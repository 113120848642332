<template>
  <v-container fluid>
    <v-row justify="center" no-gutters class="mt-3 pb-4 scheduleSelection">
      <div class="d-flex bordered qp-tab-nav">
      <div class="text-lg-right pr-0" style="width: 12rem !important">
        <v-select
            v-if="checkReadPermission($modules.facility.schedule.slug)"
            class="no-right-border"
            :items="venueServices"
            v-model="venueService"
            item-value="venue_service_id"
            item-text="name"
            outlined
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            background-color="white"
            :height="46"
            hide-details
            label="Service"
            dense
            flat
            @change="initializeScheduleForVenueService(true)"
        ></v-select>
      </div>
<!--      <div class="pl-0">-->
<!--        <v-btn-->
<!--            v-if="checkReadPermission($modules.facility.approval.slug)"-->
<!--            class="elevation-0 no-border-radius clicked-button"-->
<!--            style="height: 40px; border: 1px solid #dcdcdc !important"-->
<!--            color="white"-->
<!--        >-->
<!--          <v-icon dark>mdi-calendar-arrow-right</v-icon>-->
<!--          Schedule-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      <div class="pl-0">-->
<!--        <v-btn-->
<!--          v-if="checkReadPermission($modules.facility.approval.slug) && showApprovals"-->
<!--          @click="goToApprovals"-->
<!--          class="elevation-0 no-border-radius"-->
<!--          style="height: 40px; border: 1px solid #dcdcdc !important"-->
<!--          color="white"-->
<!--        >-->
<!--          <v-icon dark>mdi-check-decagram-outline</v-icon>-->
<!--          Approvals-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      <div class="pl-0">-->
<!--        <v-btn-->
  <!--          v-if="checkReadPermission($modules.schedule.configuration.slug)"-->
  <!--          :disabled="!venueService.venue_service_id"-->
  <!--          @click="goToConfiguration"-->
  <!--          class="elevation-0 no-left-border"-->
  <!--          style="height: 40px; border: 1px solid #dcdcdc !important"-->
  <!--          color="white"-->
<!--        >-->
<!--          <v-icon dark>mdi-cog-outline</v-icon>-->
<!--          Configuration-->
<!--        </v-btn>-->
<!--      </div>-->

      <ScheduleTabs  :schedule-class="true" :venue_service-id="venueService.venue_service_id"  />
      </div>
      <v-spacer></v-spacer>
      <div class="text-lg-right mr-2" style="width: 12rem !important">
        <v-select
            v-if="
            checkReadPermission($modules.facility.schedule.slug) &&
            this.schedule_page_configuration === 'facility'
          "
            class="w-right-border"
            :items="selectFacilities"
            v-model="currentFacility"
            item-value="id"
            item-text="name"
            outlined
            :height="46"
            label="Facility"
            :menu-props="{ bottom: true, offsetY: true }"
            background-color="white"
            hide-details
            dense
            flat
            @change="changeCurrentFacility()"
        ></v-select>
      </div>
      <div
          class="text-lg-right text-lg-right"
          style="width: 11rem !important"
          v-if="isGameFormationEnabled"
      >
        <v-select
            v-model="gameFormationFilter"
            :items="gameFormations()"
            label="Game Formation"
            item-value="id"
            item-text="name"
            :height="46"
            multiple
            background-color="white"
            :menu-props="{ bottom: true, offsetY: true }"
            @change="getFacilitySchedule()"
            outlined
            dense
            class="w-right-border"
        >
          <template
              v-if="gameFormations().length == gameFormationFilter.length"
              v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Services</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}, </span>
            <span v-if="index == 1">{{ item.name }}, </span>
            <span v-if="index === 2" class="grey--text caption pl-1"
            >and {{ gameFormationFilter.length - 1 }} others</span
            >
          </template>
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                    :color="gameFormationFilter.length > 0 ? 'teal darken-4' : ''"
                >{{ icon() }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </div>
      <div class="mx-2">
        <v-hover v-slot:default="{ hover }">
          <v-btn
              style="height: 46px; border: 1px solid #dcdcdc !important"
              :elevation="hover ? 2 : 0"
              :color="hover ? 'teal' : 'white'"
              @click="getFacilitySchedule"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-hover>
      </div>
      <div class="mr-2">
        <v-btn
            v-if="
            facilities.length > 0 &&
            checkExportPermission($modules.facility.schedule.slug)
          "
            elevation="0"
            @click="reportDownload"
            style="height: 46px; border: 1px solid #dcdcdc !important"
            color="white"
            light
            tile
        >
          <v-icon>mdi-download-box-outline</v-icon>

          Report</v-btn
        >
      </div>
    </v-row>
    <v-card class="mt-2" style="border-radius: 12px">
      <v-row>
        <v-col md="4">
          <h3
              class="pt-2 pl-5"
              v-if="this.schedule_page_configuration === 'facility'"
          >
            {{ currentFacilityName }}
          </h3>
        </v-col>
        <v-col sm="4">
          <v-row no-gutters>
            <v-col sm="1" class="text-lg-center mr-1">
              <v-btn
                  :disabled="!venueService.venue_service_id"
                  fab
                  x-small
                  color="white"
                  @click="prevDate"
              >
                <v-icon dark>mdi-menu-left</v-icon>
              </v-btn>
            </v-col>
            <v-col sm="8" class="text-lg-center">
              <date-field
                  v-model="date"
                  :buttonAndText="true"
                  :dayName="true"
                  :back-fill="
                  checkBackfillPermission($modules.facility.schedule.slug)
                "
                  :disabled="!venueService.venue_service_id"
                  @change="getFacilitySchedule"
              >
              </date-field>
            </v-col>
            <v-col sm="1" class="text-lg-center ml-1">
              <v-btn
                  fab
                  color="white"
                  x-small
                  :disabled="!venueService.venue_service_id"
                  @click="nextDate"
              >
                <v-icon dark>mdi-menu-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="4" style="text-align: right">
          <router-link :to="`/schedule`">
            <v-btn
                color="#062b48"
                dark
                tile
                text
                style="font-weight: 600; font-size: 16px; line-height: 20px"
            >Day</v-btn
            >
          </router-link>
          |
          <v-btn
              @click="gotoCalendar"
              light
              tile
              text
              style="font-weight: 400; font-size: 16px; line-height: 20px"
          >Month</v-btn
          >
        </v-col>
      </v-row>

      <div style="display: inline; display: flex" class="mt-6">
        <div
            style="width: 194px"
            class="d-flex flex-column overflow-x-hidden overflow-y-hidden"
        >
          <header-cell name="Booking Time"></header-cell>
          <div
              class="overflow-auto no-scroll"
              ref="scheduleTimer"
              @scroll="onScroll"
              :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px;margin-right:-1px;`"
          >
            <time-column :increment="increment" :height="height"></time-column>
          </div>
        </div>
        <div style="width: calc(100% - 200px)">
          <div
              class="d-flex overflow-x-hidden overflow-y-hidden"
              ref="scheduleHeader"
              v-if="schedule_page_configuration === 'facility'"
          >
            <template v-for="(weekday, index) in weekDays">
              <header-cell
                :key="`h_${index}`"
                :increment="increment"
                :height="height"
                :name="getFormattedDate(weekday.date)"
                :date="weekday.date"
                :facility-id="currentFacility"
                :is-public="currentFacilityObj.is_public"
                :is-enable-online="isEnableOnline"
                :isGameFormationEnabled="isGameFormationEnabled"
                :facility-online-disabled="getIsEnableOnline(weekday.date)"
                :formation="currentFacilityObj.game_formations"
                :perCapacity="currentFacilityObj.per_capacity"
                :totalCapacity="getFacilityCapacity(weekday.date)"
                :totalAttendees="countTotalAttendeesFacilityBased(weekday.date)"
                @enableDisableOnlineFacility="enableDisableOnlineFacility"
                @showTotalAttendees="showTotalAttendeesFacilityBased(weekday.date)"
                :perDayCapacity="currentFacilityObj.capacity"
                :isEnablePerDayCapacity="
                  currentFacilityObj.is_enable_per_day_capacity
                "
              ></header-cell>
            </template>
          </div>
          <div
              class="d-flex overflow-x-hidden overflow-y-hidden"
              ref="scheduleHeader"
              v-else
          >
            <template v-for="(facility, ind) in facilities">
              <header-cell
                  :key="`h_${facility.id}_${ind}`"
                  :increment="increment"
                  :height="height"
                  :name="facility.name"
                  :facility-id="facility.id"
                  :is-public="facility.is_public"
                  :is-enable-online="isEnableOnline"
                  :isGameFormationEnabled="isGameFormationEnabled"
                  :facility-online-disabled="facility.is_facility_online_disabled"
                  :formation="facility.game_formations"
                  :perCapacity="facility.per_capacity"
                  :totalCapacity="facility.capacity"
                  :totalAttendees="countTotalAttendees(facility)"
                  @enableDisableOnlineFacility="enableDisableOnlineFacility"
                  @showTotalAttendees="showTotalAttendees(facility.id)"
                  :perDayCapacity="facility.capacity"
                  :isEnablePerDayCapacity="facility.is_enable_per_day_capacity"
              ></header-cell>
            </template>
          </div>
          <div
              v-resize="onResize"
              ref="schedule"
              class="d-flex overflow-auto grey--text text--lighten-2 caption"
              :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px`"
              @scroll="onScroll"
          >
            <template v-for="(facility, index) in facilities">
              <facility-column
                  :key="`t_${facility.id}_${index}`"
                  :increment="increment"
                  :height="height"
                  @open-booking-form="openBooking"
                  @open-participans-model="openParticipants"
                  :name="facility.name"
                  :capacity="facility.capacity"
                  :perCapacity="facility.per_capacity"
                  :perDayCapacity="facility.capacity"
                  :isEnablePerDayCapacity="facility.is_enable_per_day_capacity"
                  :minBookingTime="facility.min_booking_time"
                  :id="facility.id"
                  :bookings="getSlotData(index)"
                  :totalAttendance="Number(facility.total_attendance)"
              >
              </facility-column>
            </template>
          </div>
        </div>
      </div>
    </v-card>

    <div class="mt-4">
      <div class="legend-container" title="schedule booking legend">
        <div class="legend-item">
          <div class="color-box" style="background-color: #e0f4f4;"></div>
          <span>Past</span>
        </div>
        <div class="legend-item">
          <div class="color-box" style="background-color: rgb(206, 168, 0);"></div>
          <span>Unpaid</span>
        </div>

        <div class="legend-item">
          <div class="color-box" style="background-color: rgb(0, 89, 118);"></div>
          <span>Paid</span>
        </div>

        <div class="legend-item">
          <div class="color-box" style="background-color: #E9806E;"></div>
          <span>Unpaid, need approval</span>
        </div>

        <div class="legend-item">
          <div class="color-box" style="background-color: #558C8C;"></div>
          <span>Paid, need approval</span>
        </div>

        <div class="legend-item">
          <div class="color-box" style="background-color: #961a04;"></div>
          <span>Maintenance</span>
        </div>
        <div class="legend-item">
          <div class="color-box" style="background-color: #8789C0;"></div>
          <span>Reserved in Cart</span>
        </div>
      </div>
    </div>
    <booking-form
        v-bind="bookingForm"
        @repeatBookingReceipt="repeatBookingReceipt"
        @close="bookingForm.showBookingForm = false; refresh = !refresh"
        @booked="completeOrder"
        @cancel="getFacilitySchedule"
        @pay="openOrderCloseBooking"
        @refresh="getFacilitySchedule"
        :perCapacity="bookingForm.per_capacity"
        :minBookingTime="bookingForm.min_booking_time"
        :increment="increment"
        @reschedule="showRescheduleMulti"
        @rescheduleMulti="showRescheduleMulti"
        @repeatRefundAndCancel="repeatRefundAndCancel"
    ></booking-form>
    <order-details
        :id="orderId"
        :ids="orderIds"
        :isSchedulePage="true"
        @close="(orderId = null), (orderIds = null)"
        @paymentDone="getFacilitySchedule"
    ></order-details>
    <!-- <participants
      :refresh="refresh"
      v-bind="participant"
      @open-booking="openBooking"
      @open-capacity-booking="openBookingCapacity"
      @close="(participant.showParticipants = false), getFacilitySchedule()"
    ></participants> -->
    <!-- <golf-participants
      :refresh="refresh"
      :venue_service_id="venueService.venue_service_id"
      v-bind="participant"
      @open-booking="openBooking"
      @open-capacity-booking="openBookingCapacity"
      @close="(participant.showParticipants = false), getFacilitySchedule()"
      @pay="openOrderCloseBooking"
      @booked="completeOrder"
    ></golf-participants> -->
    <RaceParticipants
        :refresh="refresh"
        :venue_service_id="venueService.venue_service_id"
        :isTotalParticipantsShow="isTotalParticipantsShow"
        v-bind="participant"
        @open-booking="openBooking"
        @open-capacity-booking="openBookingCapacity"
        @close="(participant.showRaceParticipants = false),(isTotalParticipantsShow = false), getFacilitySchedule()"
      />
    <participants-popup
        :refresh="refresh"
        :venue_service_id="venueService.venue_service_id"
        :isTotalParticipantsShow="isTotalParticipantsShow"
        v-bind="participant"
        @open-booking="openBooking"
        @open-capacity-booking="openBookingCapacity"
        @close="(participant.showParticipants = false),(isTotalParticipantsShow = false), getFacilitySchedule()"
        @pay="openOrderCloseBooking"
        @booked="completeOrder"
    />
    <!-- <attendees-popup-facility-booking
      :refresh="refresh"
      :venue_service_id="venueService.venue_service_id"
      v-bind="attendeesPopupParticipants"
      @open-capacity-booking="openBookingCapacity"
      @close="attendeesPopupParticipants.showParticipants = false"
    ></attendees-popup-facility-booking> -->
    <booking-details
        :venueServiceId="this.venueService.venue_service_id"
        :id="bookingOrderId"
        :is_split_payment="this.is_split_payment"
        @close="(bookingOrderId = null), getFacilitySchedule();refresh =!refresh"
        @receipt="showReceipt"
        @repeatBookingReceipt="repeatBookingReceipt"
        @reschedule="showRescheduleMulti"
        @rescheduleMulti="showRescheduleMulti"
        @refund="showRefund"
        @cancel="deleteBookings"
        @cancelRepeatBooking="cancelRepeatBooking"
        @repeatRefundAndCancel="repeatRefundAndCancel"
        @refundSession="
        (participant.showParticipants = false), getFacilitySchedule()
      "
        @openCustomerProfile="openCustomerProfile"
    ></booking-details>

    <reschedule-booking
        :venueServiceId="this.venueService.venue_service_id"
        :id="rescheduleId"
        :currentDate="date"
        @close="rescheduleId = null"
        @refund="showRefund"
        @booked="completeOrder"
        @reload="getFacilitySchedule"
    ></reschedule-booking>
    <reschedule-multi-booking
        :venueServiceId="this.venueService.venue_service_id"
        :ids="rescheduleIds"
        :currentDate="date"
        @close="rescheduleIds = null"
        @refund="showRefund"
        @booked="completeOrder"
        @reload="getFacilitySchedule"
    ></reschedule-multi-booking>
    <RefundNew
        v-if="refundModel.invoiceId && refund_dialog"
        v-bind="refundModel"
        :refundInvoiceData="refundInvoiceData"
        :show="refund_dialog"
        :repeatRefundAmount="repeatRefundAmount"
        :repeatBookingdata="repeatBookingdata"
        @close="
        refund_dialog = false;
        repeatRefundAmount = null;
      "
        @refund="
        (refund_dialog = false), (bookingOrderId = null), completeOrder()
      "
        @reload="getFacilitySchedule"
    />
    <customer-model v-bind="userModel" @close="userModel.userID = null" />
    <confirm-model
        v-bind="confirmOEDModel"
        @confirm="confirmOnlineEnableDisable"
        @close="confirmOEDModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import CustomerModel from "../../Clients/Customer/CustomerModel";
import RescheduleBooking from "./RescheduleBooking.vue";
import RescheduleMultiBooking from "./RescheduleMultiBooking.vue";
import TimeColumn from "@/components/Schedule/TimeColumn";
import FacilityColumn from "@/components/Schedule/Facility/FacilityColumn";
import HeaderCell from "@/components/Schedule/HeaderCell";
import OrderDetails from "@/components/Order/OrderDetails";
// import Participants from "./Participants";
// import GolfParticipants from "./Golf/GolfParticipants";
import ParticipantsPopup from "./ParticipantsPopup";
// import AttendeesPopupFacilityBooking from "./AttendeesPopupFacilityBooking.vue";
import BookingForm from "./BookingForm";
// import refund from "@/components/Order/Refund.vue";
import RefundNew from "@/components/Invoice/RefundNew.vue";
import BookingDetails from "./BookingDetails";
import moment from "moment";
import ScheduleTabs from "@/components/Schedule/ScheduleTabs.vue";
import RaceParticipants from "@/views/Schedule/Facility/RaceParticipants.vue";
export default {
  components: {
    RaceParticipants,
    ScheduleTabs,
    CustomerModel,
    FacilityColumn,
    TimeColumn,
    HeaderCell,
    BookingForm,
    OrderDetails,
    // GolfParticipants,
    // AttendeesPopupFacilityBooking,
    BookingDetails,
    RescheduleBooking,
    RefundNew,
    RescheduleMultiBooking,
    ParticipantsPopup,
  },
  data() {
    return {
      isMyLapEnabled:false,
      showApprovals:false,
      schedule_page_configuration: "schedule",
      week_configuration: null,
      weekDays: [],
      is_split_payment: 0,
      selectFacilities: [],
      currentFacilityObj: {},
      currentFacility: null,
      currentFacilityName: "",
      userModel: { userID: null, type: "details" },
      repeatRefundAmount: null,
      repeatBookingdata: null,
      drag: false,
      facilities: [],
      bookingForm: {},
      date: moment().format("YYYY-MM-DD"),
      currentDate: null,
      venueService: {},
      gameFormationFilter: [],
      increment: 60,
      height: 1200,
      perCapacity: 0,
      isSplitPayment: 0,
      orderId: null,
      orderIds: null,
      bookingOrderId: null,
      rescheduleId: null,
      rescheduleIds: null,
      participant: {},
      attendeesPopupParticipants: {},
      minBookingTime: 60,
      scheduleHeight: 500,
      refund_dialog: false,
      refresh: false,
      isEnableOnline: 0,
      isGameFormationEnabled: 0,
      confirmOEDModel: {
        id: null,
        title: null,
        description: null,
      },
      refundModel: { invoiceId: null, type: "full", amount: 0 },
      refundInvoiceData: {},
      isTotalParticipantsShow: false,
    };
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getSportsService.length) {
            this.getRouteParams();
            this.initializeScheduleForVenueService(true);
          }
          this.hideLoader();
        });
      });
    } else {
      if (this.$store.getters.getSportsService.length) {
        this.getRouteParams();
        this.initializeScheduleForVenueService(true);
      }
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }

  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService.filter(
          (service) => service.name != "POS"
      );
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
          this.venueService.venue_service_id
      );
    },
  },
  methods: {
    checkBookingApprovals() {
      this.$http
          .get(`venues/facilities/bookings/pending-bookings-count`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showApprovals = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getFormattedDate(date) {
      return moment(date, "YYYY-MM-DD").format("dddd, Do");
    },
    changeCurrentFacility() {
      let facility = this.selectFacilities.find((ele) => {
        return ele.id == this.currentFacility;
      });
      if (facility) {
        this.currentFacilityName = facility.name;
        this.currentFacilityObj = facility;
        this.initializeScheduleForVenueService();
      } else {
        this.currentFacilityName = null;
        this.currentFacilityObj = {};
      }
    },
    getFacilities(check = false) {
      if (this.venueService.venue_service_id == null) {
        this.showInfo("Please select service");
        return;
      }
      this.showLoader("Loading Facilities");
      this.selectFacilities = [];
      this.$http
          .get(
              `venues/facilities?venue_service_id=${this.venueService.venue_service_id}`
          )
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data.length
            ) {
              this.selectFacilities = response.data.data;
              this.selectFacilities = this.selectFacilities.filter(ele => ele.is_enabled_seat_map === 0);if (
                  this.selectFacilities.length > 0 &&
                  (!this.currentFacility || check)
              ) {
                this.currentFacility = this.selectFacilities[0].id;
                let facility = this.selectFacilities.find((ele) => {
                  return ele.id == this.currentFacility;
                });
                if (facility) {
                  this.currentFacilityName = facility.name;
                  this.currentFacilityObj = facility;
                } else {
                  this.currentFacilityName = null;
                  this.currentFacilityObj = {};
                }
                this.getFacilitySchedule();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    generateWeek(startingDate) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const result = [];

      // Convert the starting date string to a Date object
      const startDate = new Date(startingDate);

      // Generate the list of weekdays starting from the given date
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);
        const day = daysOfWeek[currentDate.getDay()];
        result.push({
          day: day,
          date: currentDate.toISOString().split("T")[0],
        });
      }
      return result;
    },
    generateWeekDays() {
      if (this.schedule_page_configuration !== "facility") {
        return;
      }
      this.weekDays = [];
      let startDate = this.date;
      if (this.week_configuration === "current_date") {
        this.weekDays = this.generateWeek(this.date);
      } else {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const result = [];
        const currentDate = new Date(startDate);

        // Calculate the Monday of the current week
        const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        const mondayOffset = currentDayOfWeek - 1; // Offset to Monday
        const mondayDate = new Date(currentDate);
        mondayDate.setDate(mondayDate.getDate() - mondayOffset); // Set to Monday of the current week

        // Generate the list of weekdays starting from Monday
        for (let i = 0; i < 7; i++) {
          result.push({
            day: daysOfWeek[i],
            date: new Date(
                mondayDate.getFullYear(),
                mondayDate.getMonth(),
                mondayDate.getDate() + i
            )
                .toISOString()
                .split("T")[0],
          });
        }
        this.weekDays = result;
      }
    },
    goToApprovals(){
      // if (this.venueService.venue_service_id) {
        this.$router.push({
          name: "BookingApprovals",
          // params: { data: btoa(this.venueService.venue_service_id) },
        });
      // } else {
      //   this.errorChecker("Please select service");
      // }
    },
    goToConfiguration() {
      if (this.venueService.venue_service_id) {
        this.$router.push({
          name: "ScheduleConfiguration",
          params: {data: btoa(this.venueService.venue_service_id)},
        });
      } else {
        this.errorChecker("Please select service");
      }
    },
    initializeScheduleForVenueService(check = false) {
      if (this.venueService.venue_service_id) {
        if (
            !this.$store.getters.getConfigurationStatus(
                this.venueService.venue_service_id
            )
        ) {
          this.$store
              .dispatch(
                  "loadConfigurationsByVenueServiceId",
                  this.venueService.venue_service_id
              )
              .then((response) => {
                if (response.status == 200) {
                  this.schedule_page_configuration =
                      this.venueServiceConfiguration.schedule_page_configuration;
                  this.week_configuration =
                      this.venueServiceConfiguration.week_configuration;
                this.isMyLapEnabled = this.venueServiceConfiguration.is_my_lap_enabled.toString();

                  if (this.venueServiceConfiguration.is_golf_enabled) {
                    this.$router.push({
                      name: "GolfSchedule",
                      params: {
                        data: btoa(
                            JSON.stringify({
                              venue_service: this.venueService,
                              date: this.date,
                            })
                        ),
                      },
                    });
                  }
                }
                this.loadFacilitiesFunc(check)
              });
        } else {
          if (this.venueServiceConfiguration.is_golf_enabled) {
            this.$router.push({
              name: "GolfSchedule",
              params: {
                data: btoa(
                    JSON.stringify({
                      venue_service: this.venueService,
                      date: this.date,
                    })
                ),
              },
            });
          } else {
            this.schedule_page_configuration =
                this.venueServiceConfiguration.schedule_page_configuration;
            this.week_configuration =
                this.venueServiceConfiguration.week_configuration;
            this.isMyLapEnabled = this.venueServiceConfiguration.is_my_lap_enabled.toString();
          }
          this.loadFacilitiesFunc(check)
        }

      }
    },
    loadFacilitiesFunc(check) {
      if (this.gameFormations().length === 0) {
        this.showLoader("Loading");
        this.$store
            .dispatch(
                "loadConfigurationsByVenueServiceId",
                this.venueService.venue_service_id
            )
            .then(() => {
              this.hideLoader();
              this.generateWeekDays();
              this.getFacilitySchedule();
            });
      } else {
        this.generateWeekDays();
        this.getFacilitySchedule();
      }
      this.getFacilities(check);
    },
    getRouteParams() {
      if (this.$route.params.data) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueService = data.venue_service;
        this.date = data.date;
        if (data.order_id) {
          this.bookingOrderId = data.order_id;
        }
        if (data.facility_id) {
          this.currentFacility = data.facility_id;
          this.changeCurrentFacility();
        }
      } else {
        this.venueService = this.$store.getters.getSportsService.filter(
            (service) => service.name != "POS"
        )[0];
      }
    },
    onResize() {
      this.scheduleHeight = window.innerHeight - 350;
    },
    gameFormations() {
      return this.$store.getters.getGameFormationsByVenueServiceId(
          this.venueService.venue_service_id
      );
    },
    openBooking(data) {
      if (
          data.per_capacity == 0 &&
          (data.status == "paid" ||
              data.status == "trainer" ||
              data.status == "unapproved")
      ) {
        this.bookingOrderId = data.order_id;
      } else {
        this.openBookingForm(data);
      }
    },
    openBookingCapacity(data) {
      if (data.status == "paid" || data.status == "trainer") {
        this.bookingOrderId = data.order_id;
      } else {
        this.openBookingForm(data);
      }
    },
    openBookingForm(data) {
      this.bookingForm = {
        showBookingForm: true,
        start_time: moment(data.start_time, "hh:mm a").format("HH:mm:ss"),
        end_time: moment(data.end_time, "hh:mm a").format("HH:mm:ss"),
        facility_name: data.facility_name,
        facility_id: data.facility_id,
        date: data.current_date
            ? data.current_date
            : data.date
                ? data.date
                : this.date,
        increment: this.increment,
        venue_service_id: this.venueService.venue_service_id,
        service: this.venueService.name,
        id: data.id != null ? data.id : 0,
        order_id: data.order_id,
        per_capacity: data.per_capacity,
        min_booking_time: data.min_booking_time,
      };
    },
    getFacilityBasedSchedule() {
      if (!this.currentFacility) {
        return;
      }
      this.showLoader("Loading");
      const dateArray = this.weekDays.map((item) => item.date);
      this.$store
          .dispatch("loadFacilityBasedSchedule", {
            venue_service_id: this.venueService.venue_service_id,
            date: this.date,
            weekDays: dateArray,
            facility_id: this.currentFacility,
            game_formation_id: this.gameFormationFilter,
            backfill: this.checkBackfillPermission(
                this.$modules.facility.schedule.slug
            ),
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.increment =
                  data.configuration.time_increment != null
                      ? data.configuration.time_increment
                      : 60;
              this.minBookingTime =
                  data.configuration.min_booking_time != null
                      ? data.configuration.min_booking_time
                      : 60;
              this.height = (60 / this.increment) * 1200;
              this.perCapacity = data.configuration.per_capacity
                  ? data.configuration.per_capacity
                  : 0;
              this.facilities = data.facilities;
              this.isEnableOnline = data.configuration.is_public;
              this.isGameFormationEnabled =
                  data.configuration.is_game_formation_enabled;
              this.hideLoader();
              if (this.date != this.currentDate) {
                this.currentDate = moment().format("YYYY-MM-DD");
                // this.scrollToAvailable();
              }
            }
          this.checkBookingApprovals();
        });
    },
    getFacilitySchedule() {
      this.generateWeekDays();
      if (this.schedule_page_configuration === "facility") {
        return this.getFacilityBasedSchedule();
      }
      if (this.participant && this.participant.showParticipants) {
        this.refresh = !this.refresh;
      }
      this.showLoader("Loading");
      this.$store
          .dispatch("loadFacilitySchedule", {
            venue_service_id: this.venueService.venue_service_id,
            date: this.date,
            game_formation_id: this.gameFormationFilter,
            backfill: this.checkBackfillPermission(
                this.$modules.facility.schedule.slug
            ),
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.increment =
                  data.configuration.time_increment != null
                      ? data.configuration.time_increment
                      : 60;
              this.minBookingTime =
                  data.configuration.min_booking_time != null
                      ? data.configuration.min_booking_time
                      : 60;
              this.height = (60 / this.increment) * 1200;
              this.perCapacity = data.configuration.per_capacity
                  ? data.configuration.per_capacity
                  : 0;
              this.facilities = data.facilities;
              this.isEnableOnline = data.configuration.is_public;
              this.isGameFormationEnabled =
                  data.configuration.is_game_formation_enabled;
              this.hideLoader();
              if (this.date != this.currentDate) {
                this.currentDate = moment().format("YYYY-MM-DD");
                // this.scrollToAvailable();
              }
            }
          });
    },
    nextDate() {
      let amount = 1;
      if (this.schedule_page_configuration === "facility") {
        amount = 7;
      }
      this.date = moment(this.date).add(amount, "days").format("YYYY-MM-DD");
      this.generateWeekDays();
      this.getFacilitySchedule();
    },
    prevDate() {
      let amount = 1;
      if (this.schedule_page_configuration === "facility") {
        amount = 7;
      }
      this.date = moment(this.date)
          .subtract(amount, "days")
          .format("YYYY-MM-DD");
      this.generateWeekDays();
      this.getFacilitySchedule();
    },
    getSlotData(index) {
      return this.$store.getters.getFacilitySchedule[index];
    },
    openOrderCloseBooking(orderId) {
      this.bookingForm.showBookingForm = false;
      this.orderId = orderId;
      // console.log("order id: " + this.orderId);
    },
    completeOrder(orderId) {
      this.getFacilitySchedule();
      if (orderId) {
        if (this.bookingForm.per_capacity) {
          this.bookingForm.showBookingForm = false;
          // console.log(this.bookingForm);
          this.openParticipants(this.bookingForm);
        } else {
          this.openOrderCloseBooking(orderId);
        }
      } else {
        this.bookingForm.showBookingForm = false;
      }
    },
    showReceipt(id) {
      this.bookingOrderId = null;
      this.orderId = id;
    },

    repeatBookingReceipt(orderIds) {
      this.bookingOrderId = null;
      this.bookingForm.showBookingForm = false;
      this.orderIds = orderIds;
    },

    showReschedule(id) {
      this.bookingOrderId = null;
      this.rescheduleId = id;
    },
    showRescheduleMulti(ids) {
      this.bookingOrderId = null;
      if (typeof ids === "number") {
        this.rescheduleIds = [ids];
        console.log("number");
      } else {
        if (ids && ids.booking_ids && Array.isArray(ids.booking_ids)) {
          this.rescheduleIds = ids.booking_ids;
        } else {
          this.rescheduleIds = [ids];
        }
      }
    },
    showRefund(id) {
      this.$store.dispatch("loadOrderDetails", id).then((response) => {
        if (response.status == 200) {
          this.refund_dialog = true;
        }
      });
      this.refund_dialog = true;
    },

    openCustomerProfile(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },

    repeatRefundAndCancel(amount, formData) {
      if (amount) {
        this.payments = [
          {
            card_type_id: null,
            payment_code: null,
            payment_method_id: null,
            amount: null,
            payment_method: null,
          },
        ];

        this.$store.commit("setOrderPayments", this.payments);
        this.repeatRefundAmount = amount;
        this.repeatBookingdata = formData;
        this.refund_dialog = true;

        this.refundModel.invoiceId = 1;
        this.refundModel.amount = amount;
        this.refundModel.type = "partial";
      }
    },

    openParticipants(data) {
      this.is_split_payment = data.is_split_payment;
      this.isTotalParticipantsShow=false;
      this.participant = {
        showParticipants: Number(this.isMyLapEnabled) === 0,  // Explicitly check if it's 0
        showRaceParticipants: Number(this.isMyLapEnabled) !== 0,  // True for any non-zero value
        start_time: moment(data.start_time, "hh:mm a").format("HH:mm:ss"),
        end_time: moment(data.end_time, "hh:mm a").format("HH:mm:ss"),
        facility_id: data.facility_id,
        date: data.current_date
            ? data.current_date
            : data.date
                ? data.date
                : this.date,
        is_split_payment: data.is_split_payment ? data.is_split_payment : 0,
      };

    },
    gotoCalendar() {
      this.$router.push({
        name: "CalendarWithParams",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service: this.venueService,
                date: this.date,
              })
          ),
        },
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.gameFormationFilter.length == this.gameFormations().length) {
          this.gameFormationFilter = [];
        } else {
          this.gameFormationFilter = this.gameFormations().map(
              (item) => item.id
          );
        }
      });
      setTimeout(() => {
        this.getFacilitySchedule();
      });
    },
    icon() {
      if (this.gameFormationFilter.length == this.gameFormations())
        return "mdi-close-box";
      if (this.gameFormationFilter.length == 0)
        return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    scrollToAvailable() {
      setTimeout(() => {
        const el = this.$refs.schedule;
        let available = this.$el.getElementsByClassName("available")[0];
        if (typeof available !== "undefined") {
          if (available.offsetTop) el.scrollTop = available.offsetTop - 20;
        }
      });
    },
    confirmCancel() {
      this.confirmModel = {
        id: this.order_id,
        title: `Do you want cancel this booking?`,
        description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel",
      };
    },
    deleteBookings(id) {
      this.showLoader("Wait");
      this.$http
          .delete(`venues/orders/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking cancelled successfully");
              this.getFacilitySchedule();
              this.bookingOrderId = null;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    cancelRepeatBooking() {
      this.getFacilitySchedule();
      this.bookingOrderId = null;
    },

    reportDownload() {
      let date = moment(this.date).format("YYYY-MM-DD");
      let url =
          "?venue_service_id=" +
          this.venueService.venue_service_id +
          "&date=" +
          date;
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
          .get(`venues/facilities/bookings/bookings-schedule${url}`, {
            responseType: "blob",
          })
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.downloadFile(response, "Schedule Report");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    onScroll() {
      const refElement = this.$refs.schedule;
      if (refElement) {
        const scrollLeft = refElement.scrollLeft;
        const scrollTop = refElement.scrollTop;
        this.$refs.scheduleTimer.scrollTop = scrollTop;
        this.$refs.scheduleHeader.scrollLeft = scrollLeft;
      }
    },
    enableDisableOnlineFacility(data) {
      let toggle = "Enabled";
      if (data.is_public) {
        toggle = "Disabled";
      }
      let date = this.date;
      if (data.date) {
        date = data.date;
      }
      this.confirmOEDModel = {
        id: Math.floor(Math.random() * 100 + 1),
        title: toggle + " online booking?",
        description:
            "By clicking <b>Yes</b> online booking for date " +
            date +
            " <b>" +
            toggle +
            "</b>.  Do you need to continue your action ?",
        type: "update",
        data: data,
      };
    },
    /** only disable for particular date */
    confirmOnlineEnableDisable(data) {
      let formData = new FormData();
      let date = moment(this.date).format("YYYY-MM-DD");
      if (data.data && data.data.date) {
        date = moment(data.data.date).format("YYYY-MM-DD");
      }
      if (
          date != "" &&
          typeof data.data.facility_id != "undefined" &&
          data.data.facility_id != "" &&
          typeof data.data.is_public != "undefined"
      ) {
        formData.append("date", date);
        formData.append("facility_id", data.data.facility_id);
        formData.append("is_public", data.data.is_public);
        this.showLoader("LOADING ... ");
        this.$http
            .post(`venues/facilities/online-status`, formData, {
              headers: {
                "Content-Type": "multipart/form-data; boundary=${form._boundary}",
              },
            })
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.hideLoader();
                this.getFacilitySchedule();
                this.showSuccess("Online facility booking status updated");
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },
    showTotalAttendees(facility_id) {
      this.isTotalParticipantsShow = true;
      // this.attendeesPopupParticipants = {
      //   showParticipants: true,
      //   facility_id: facility_id,
      //   date: this.date,
      // };
      this.participant = {
        showParticipants: true,
        facility_id: facility_id,
        date: this.date,
      }
      console.log("show total attendace");
    },
    countTotalAttendees(facility) {
      if (facility.per_capacity == 1) {
        let attendees_count = 0;
        facility.bookings.forEach((booking) => {
          attendees_count += booking.attendance;
        });
        return attendees_count;
      } else {
        return 0;
      }
    },
    countTotalAttendeesFacilityBased(date) {
      if (
          this.facilities &&
          this.facilities[date] &&
          this.facilities[date].per_capacity == 1
      ) {
        let attendees_count = 0;
        this.facilities[date].bookings.forEach((booking) => {
          attendees_count += booking.attendance;
        });
        return attendees_count;
      } else {
        return 0;
      }
    },
    getIsEnableOnline(date) {
      if (
          this.facilities &&
          this.facilities[date] &&
          this.facilities[date].is_facility_online_disabled
      ) {
        return this.facilities[date].is_facility_online_disabled;
      } else {
        return null;
      }
    },
    getFacilityCapacity(date) {
      if (
          this.facilities &&
          this.facilities[date] &&
          this.facilities[date].capacity
      ) {
        return this.facilities[date].capacity;
      } else {
        return 0;
      }
    },
    showTotalAttendeesFacilityBased(date) {
      console.log("show showTotalAttendeesFacilityBased");
      this.isTotalParticipantsShow = true;
      this.participant = {
        showParticipants: true,
        facility_id: this.currentFacility,
        date: date,
      }
    },
  },
};
</script>

<style lang="scss">
.no-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sch-gameformation-field {
  height: 44px;
}

.no-right-border {
  .v-input__control {
    border-radius: 5px 0 0 5px; /* Adjust border radius as needed */
  }

  color: #4faeaf !important;

  fieldset {
    border: 1px solid rgba(17, 42, 70, 0.1);
  }
}

.w-right-border {
  .v-input__control {
    border-radius: 5px; /* Adjust border radius as needed */
  }

  color: #4faeaf !important;

  fieldset {
    border: 1px solid rgba(17, 42, 70, 0.1);
  }
}

.no-border-radius{
  border-radius: 0 !important;
}
.no-left-border {
  border-radius: 0 5px 5px 0 !important;
  border: 1px solid rgba(17, 42, 70, 0.1);
  border-left: none;
}

.legend-container {
  display: flex;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 12px;
}

.color-box {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 2px;
  border: 1px solid black;
}

</style>
