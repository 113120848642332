<template>
  <div>
    <v-dialog
      persistent
      v-model="orderDialog"
      max-width="70%"
      @input="closeOrderDetails"
      scrollable
    >
      <v-card tile>
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="8">
              <v-card outlined>
                <v-card-title
                  ><span class="text_line">SELECT PAYMENT METHOD</span>
                  <v-spacer></v-spacer>
                  <v-btn outlined
                    >Total {{ paymentTotal | toCurrency }} /
                    {{ totalAmount }}</v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" md="12">
                        <template v-for="(method, index) in payments">
                          <payment-method
                            v-bind="method"
                            :index="index"
                            :totalAmountFromRepeat="totalAmount"
                            :key="`p_${index}`"
                            :updateCommitType="'updateRepeatPaymentMethod'"
                            @remove="removePaymentMethod"
                          ></payment-method>
                        </template>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined color="primary" @click="addNewPaymentMethod">
                    Add New Method <v-icon small right>mdi-plus</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <v-btn
                dark
                class="yellow-color"
                absolute
                bottom
                @click="$emit('close'), clearPaymentMethod()"
                >Settle later</v-btn
              >
            </v-col>
            <v-col md="4">
              <v-card outlined>
                <v-card-title
                  ><span class="text_line">ORDER SUMMARY</span></v-card-title
                >
                <v-card-text>
                  <v-list
                    three-line
                    style="min-height: 150px; max-height: 250px"
                    class="overflow-y-auto"
                  >
                    <v-list-item
                      v-for="(product, i) in products"
                      :key="i"
                      ripple
                      @click="() => {}"
                    >
                      <v-list-item-avatar title height="64" width="64">
                        <view-image :image="product.image"></view-image>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="text-body">{{
                          product.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <div
                            class="d-flex justify-space-between text-caption"
                          >
                            <div class="font-weight-bold">
                              {{ product.product_price }}
                              x {{ product.quantity }}
                            </div>
                            <div class="font-weight-bold">
                              Tax
                              {{
                                product.discount
                                  ? product.discount.actual_tax
                                  : product.tax | toCurrency
                              }}
                            </div>
                            <div class="font-weight-bold">
                              {{ currencyCode }}
                              {{
                                product.discount
                                  ? product.discount.actual_total
                                  : (product.price + product.tax).toFixed(2)
                              }}
                            </div>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <div class="d-flex justify-space-between subtitle-2">
                    <div class="">Subtotal</div>
                    <div class="">
                      {{ orderDetails.price | toCurrency }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between subtitle-2">
                    <div class="">Tax</div>
                    <div class="">
                      {{ orderDetails.tax | toCurrency }}
                    </div>
                  </div>

                  <div
                    class="d-flex justify-space-between subtitle-2 green--text"
                    v-if="orderDetails.discount"
                  >
                    <div class="">
                      Discount - {{ orderDetails.promotion_name }}
                    </div>
                    <div class="">
                      {{ orderDetails.actual_price | toCurrency }}
                      +
                      {{ orderDetails.actual_tax | toCurrency }}
                      =
                      {{ orderDetails.actual_total | toCurrency }}
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div
                    class="
                      d-flex
                      justify-space-between
                      font-weight-bold
                      subtitle-1
                      black--text
                    "
                  >
                    <div>Total</div>

                    <div>
                      <span
                        class="text-decoration-line-through pt-1"
                        v-if="orderDetails.discount"
                      >
                        {{ orderDetails.total | toCurrency }}
                      </span>
                      {{
                        orderDetails.discount
                          ? orderDetails.total - orderDetails.actual_total
                          : orderDetails.total | toCurrency
                      }}
                    </div>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-row
                    v-if="
                      !orderDetails.promotion_code &&
                        !payments.find(
                          (x) => x.payment_method == 'Complementary'
                        )
                    "
                  >
                    <v-col md="6">
                      <v-text-field
                        v-model="discountAmount"
                        label="Special discount"
                        outlined
                        :prefix="currencyCode"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                            }
                            return true;
                          },
                        ]"
                        @keyup="specialDiscountChange('amount')"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <v-text-field
                        v-model="discountPercentage"
                        suffix="%"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                              if (v > 100) {
                                return 'Percentage should be less than 100';
                              }
                            }
                            return true;
                          },
                        ]"
                        label="Percentage"
                        outlined
                        @keyup="specialDiscountChange('percentage')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-textarea
                    v-model="paymentNote"
                    rows="3"
                    label="Payment note"
                    outlined
                  ></v-textarea>
                </div>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    @click="payMultiBooking"
                    block
                    large
                    color="rgb(0, 176, 175)"
                    dark
                    >Pay now</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
        ></confirm-model>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentMethod from "./PaymentMethod.vue";
export default {
  components: {
    "payment-method": PaymentMethod,
  },
  props: {
    show: { Type: Boolean, default: null },
    refreshBool: { Type: Boolean, default: null },
    products: { Type: Array, default: null },
    multiBookingIds: { Type: Array, default: null },
    mobile: { Type: String, default: null },
    promotion_code: { Type: String, default: null },
    card_number: { Type: String, default: null },
    customer_id: { Type: Number, default: 0 },
    customer_name: { Type: String, default: null },
  },
  data() {
    return {
      balance: 0,
      confirmModel: {},
      paymentNote: null,
      discountAmount: null,
      prevdiscountAmount: null,
      discountPercentage: null,
      orderDialog: false,
      orderDetails: {},
      totalAmount: 0,
      totalAmountBeforeDiscount: 0,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val == true) {
          this.orderDialog = true;
          this.addNewPaymentMethod();
        } else {
          this.orderDialog = false;
          this.clearPaymentMethod();
        }
      },
    },
    refreshBool: {
      immediate: true,
      handler(val) {
        console.log(this.products);
        console.log("redbool", val);
      },
    },
    products: {
      immediate: true,
      handler(val) {
        if (val) {
          let total_price = 0;
          let total_tax = 0;

          let total_actual_price = 0;
          let total_actual_tax = 0;

          total_price = this.products.reduce(
            (a, b) =>
              a + parseFloat(b.discount ? b.discount.actual_price : b.price),
            0
          );
          total_tax = this.products.reduce(
            (a, b) =>
              a +
              parseFloat(
                b.discount
                  ? b.discount.actual_tax
                  : b.rental == false && !b.total_tax_amount
                  ? b.tax
                    ? b.tax
                    : 0
                  : b.total_tax_amount
                  ? b.total_tax_amount
                  : 0
              ),
            0
          );
          this.products.forEach((product) => {
            if (product.discount) {
              this.orderDetails.promotion_code = true;
              this.orderDetails.discount = true;
              total_actual_price +=
                product.discount.actual_price - product.price;
              total_actual_tax += product.discount.actual_tax - product.tax;
            }
          });

          this.totalAmount = (total_price + total_tax).toFixed(2);
          this.totalAmount = parseFloat(this.totalAmount);

          this.totalAmountBeforeDiscount = total_price + total_tax;
          this.orderDetails.price = total_price;
          this.orderDetails.tax = total_tax;
          this.orderDetails.total = total_price + total_tax;

          this.orderDetails.actual_price = total_actual_price;
          this.orderDetails.actual_tax = total_actual_tax;
          this.orderDetails.actual_total =
            total_actual_price + total_actual_tax;

          if (this.orderDetails.actual_total) {
            this.totalAmount -= this.orderDetails.actual_total;
            this.totalAmountBeforeDiscount -= this.orderDetails.actual_total;
          }
          this.$forceUpdate();
        }
      },
    },
    multiBookingIds: {
      immediate: true,
      handler(val) {
        if (val) {
          // console.log(this.multiBookingIds);
        }
      },
    },
  },
  computed: {
    payments() {
      return this.$store.getters.getRepeatPayments;
    },

    paymentTotal() {
      return this.$store.getters.getRepeatPaymentTotal;
    },
  },
  methods: {
    addNewPaymentMethod() {
      this.$store
        .dispatch("addNewRepeatPaymentMethod")
        .then(() => {})
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    clearPaymentMethod() {
      this.$store
        .dispatch("resetPaymentMethod")
        .then(() => {})
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },

    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removeRepeatPaymentMethod", data.data.index);
        });
      }
      this.confirmModel.id = null;
    },

    closeOrderDetails() {
      this.$emit("close");
    },

    specialDiscountChange(type) {
      this.orderDetails.discount = false;
      this.orderDetails.actual_price = null;
      this.orderDetails.actual_tax = null;
      this.orderDetails.actual_total = null;
      this.totalAmount = this.totalAmountBeforeDiscount;

      if (type == "percentage") {
        if (isNaN(this.discountPercentage)) {
          return;
        }

        if (this.discountPercentage < 0) {
          this.discountPercentage = 0;
        }
        if (this.discountPercentage >= 100) {
          this.discountPercentage = 100;
          this.discountAmount = 0;
        } else {
          this.discountAmount = (
            this.totalAmountBeforeDiscount -
            (this.totalAmountBeforeDiscount / 100) * this.discountPercentage
          ).toFixed(2);
        }

        let taxPercentage =
          (this.orderDetails.tax / 100) * this.discountPercentage;

        if (this.discountAmount != this.totalAmount) {
          let actual_price = this.discountAmount - taxPercentage;
          this.orderDetails.discount = true;
          this.orderDetails.actual_price =
            this.orderDetails.price - actual_price;
          this.orderDetails.actual_tax = taxPercentage;
          this.orderDetails.actual_total =
            this.orderDetails.total - this.discountAmount;
          this.totalAmount = parseFloat(this.discountAmount);
        } else {
          this.discountAmount = null;
        }
        this.$forceUpdate();
      } else if (type == "amount") {
        this.discountPercentage = null;
        let discountPercentage = null;
        if (this.discountAmount == "" || this.discountAmount == null) {
          this.discountAmount = null;
          discountPercentage = null;
          return;
        }
        if (isNaN(this.discountAmount)) {
          this.discountAmount = this.totalAmountBeforeDiscount;
          discountPercentage = 100;
        }
        if (this.discountAmount == 0) {
          discountPercentage = 100;
        } else if (this.discountAmount == this.totalAmountBeforeDiscount) {
          discountPercentage = 0;
        } else {
          discountPercentage = (
            (this.discountAmount / this.totalAmountBeforeDiscount) *
            100
          ).toFixed(2);
        }
        let taxPercentage = (this.orderDetails.tax / 100) * discountPercentage;
        if (this.discountAmount != this.totalAmount) {
          let actual_price = this.discountAmount - taxPercentage;
          this.orderDetails.discount = true;
          this.orderDetails.actual_price =
            this.orderDetails.price - actual_price;
          this.orderDetails.actual_tax = this.orderDetails.tax - taxPercentage;
          this.orderDetails.actual_total =
            this.orderDetails.total - this.discountAmount;
          this.totalAmount = parseFloat(this.discountAmount);
        }
        this.$forceUpdate();
      }
    },

    payMultiBooking() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all fields");
        return;
      }
      this.showLoader("Payment processing....");

      let totalPayment = this.payments.reduce(
        (a, b) => a + parseFloat(b.amount),
        0
      );
      if (this.totalAmount > totalPayment || this.totalAmount < totalPayment) {
        this.hideLoader();
        this.showError("Payment amount not matching with order amount!");
        return 0;
      }

      let bookingIds = [];
      let orderIds = [];
      this.multiBookingIds.forEach((element) => {
        bookingIds.push(element.id);
        orderIds.push(element.order_id);
      });
      let data = {
        payments: this.payments,
        booking_ids: bookingIds,
        order_ids: orderIds,
        products: this.products,
        customer_id: this.customer_id,
        customer_name: this.customer_name,
      };

      if (this.mobile) {
        data.mobile = this.mobile;
      }
      if (this.card_number) {
        data.card_number = this.card_number;
      }

      if (this.promotion_code) {
        data.promotion_code = this.promotion_code;
      }

      if (this.discountAmount) {
        data.discount = parseFloat(this.discountAmount);
      }
      if (this.paymentNote) {
        data.notes = this.paymentNote;
      }

      this.$http
        .post("venues/facilities/bookings/golf/payments", data)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.showSuccess("Payment success");
            this.clearPaymentMethod();
            this.$emit("payed");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}
</style>
