<template>
  <v-dialog
    v-model="showCombinations"
    scrollable
    persistent
    :overlay="false"
    max-width="50%"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title class="headline">Rental product combination</v-card-title>
      <v-card-text class="pt-2">
        <v-radio-group v-model="selectCombination">
          <v-row
            v-for="(combination, index) in productCombinations"
            :key="`combination_${index}`"
            class="mt-4"
            style="border: 1px solid #ccc; border-radius: 4px"
          >
            <v-col md="10">
              <v-chip
                label
                color="cyan"
                dark
                class="ml-2 mb-2"
                v-for="(product, index) in combination.products"
                :key="`com_pro_${index}`"
                @click:close="removeProduct(index)"
              >
                <v-avatar left>
                  <view-image :image="product.image_path"></view-image>
                </v-avatar>
                {{ product.name }} x {{ product.quantity }} -
                {{ product.price | toCurrency }}
              </v-chip>
            </v-col>
            <v-col class="d-flex align-center">
              <v-radio
                :name="`combination_${index}`"
                :label="`Choose ${currencyCode} ${combination.total_price.toFixed(
                  2
                )}`"
                :value="combination.key"
              ></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" class="ma-2 white--text blue-color" text
          >Close
        </v-btn>
        <v-btn
          @click="changeRentalProducts"
          class="ma-2 white--text teal-color"
          text
          >Change combination
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    "show-combinations": { type: Boolean, default: false },
    "product-combinations": { type: Array, default: () => [] },
    "selected-combination-price": { type: Number, default: 0 },
  },
  watch: {
    selectedCombinationPrice(val) {
      this.selectCombination = val;
    },
  },
  data() {
    return {
      selectCombination: this.selectedCombinationPrice,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeRentalProducts() {
      let combination = this.productCombinations.find((item) => {
        return item.key == this.selectCombination;
      });
      this.$emit("changeCombination", combination);
      this.close();
    },
  },
};
</script>

<style>
</style>