<template>
  <v-dialog
      persistent
      v-model="raceSetupModal"
      scrollable
      @input="close"
      width="500px"
  >
    <v-form ref="category_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                    class="text-2xl font-semibold"
                    text="Race settings"
                    style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row>
            <v-col cols="6">
              <label class="text-dark-gray font-medium text-xs" for=""
              >Total Laps</label
              >
              <v-text-field
                  v-model="laps_count"
                  :rules="[
                (v) => {
                  if (v == null) return true;
                  if (!isNaN(v)) return true;
                  return 'Default Laps should be number';
                },
              ]"
                  background-color="#fff"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
                  outlined
                  required
              ></v-text-field>

            </v-col>
            <v-col cols="12" md="6" sm="6">
              <label for="">
                Raking based on
              </label>
              <v-select
                  v-model="ranking_type_id"
                  :items="rankingTypes"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Ranking basis required']"
                  background-color="#fff"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn
              text
              class="white--text blue-color"
              @click="saveParticipantVehicle"
          >Save
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  props: {
    raceSetupModal: {type: Boolean, default: false},
    participant: {type: Object, default: null},
  },
  data() {
    return {
      laps_count: null,
      ranking_type_id: 1,
      rankingTypes:[
        {
          id:1,
          name:'Best Lap'
        },
        {
          id:2,
          name:'Best Total Time'
        },
        {
          id:3,
          name:'Best Average Lap'
        },
      ],
    };
  },
  watch: {
    raceSetupModal(val) {
      if (val) {
        this.getRaceConfiguration();
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getRaceConfiguration() {
      console.log(this.participant);
      this.showLoader('Fetching configuration..');
      this.$http
          .get(`venues/facilities/bookings/race/get-configurations?race_id=${this.participant.race_id}`)
          .then((response) => {
            if (response.status == 200) {
              let data= response.data.data;
              this.laps_count = data.total_laps;
              this.ranking_type_id = data.ranking_type_id;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    saveParticipantVehicle() {
      this.showLoader('Saving configuration..');
      let data = {
        laps_count: this.laps_count,
        ranking_type_id: this.ranking_type_id,
        race_id: this.participant.race_id,
      }
      this.$http
          .post(`venues/facilities/bookings/race/set-configuration`,data)
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    }
  }
}
</script>

<style scoped>

</style>