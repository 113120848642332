<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="modal-heading">Statistics</span>
        <div>
          <span class="kart-name-bold">Kart:</span><span class="kart-name">{{ user.vehicle_name }}</span>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="mx-0">
        <v-col md="2" class="mt-4">
          <v-list-item-avatar :height="imageDimensions" :width="imageDimensions" class="line pl-4" rounded="2">
            <view-image
                :contain="false"
                :image="user.profile_image"
                defaultImage="user"
            ></view-image>
          </v-list-item-avatar>

        </v-col>
        <v-col md="10">
          <div class="pa-4">
            <div class="d-flex justify-space-between align-center">
              <div class="player-name">{{ user.name }}</div>
              <div class="position-badge d-flex align-center">
                <div class="circle-badge"
                     :class="{'second-badge':participantPosition === 2,'first-badge':participantPosition === 1,'third-badge':participantPosition === 3}">
                  {{ participantPosition | ordinal }}
                </div>
              </div>

            </div>
            <v-divider class="my-2"></v-divider>

            <div class="d-flex justify-space-between">
              <p class="kart-text"><strong>Best Lap: </strong>{{ user.bestLap }}</p>
              <p class="kart-text"><strong>Total Laps: </strong>{{ user.totalLaps }}</p>
            </div>
            <div class="d-flex justify-space-between">
              <p class="kart-text"><strong>Start time: </strong>{{ user.firstLapStartTime | timeStamp }}</p>
              <p class="kart-text"><strong>End time: </strong>{{ user.lastLapEndTime | timeStamp }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-text>
        <div class="table-responsive">
          <table class="table border-collapse ">
            <thead>
            <tr class="opacity-70 tr-neon tr-rounded ">
              <th class="text-center">Laps</th>
              <th class="text-center">Time Taken</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(lap, index) in user.laps" :key="index">
              <td class="text-center">{{ lap.lapNumber }}</td>
              <td class="text-center">{{ lap.timeTaken }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StatsModal",
  props: {
    participantId: {type: Number, default: null},
    participantPosition: {type: Number, default: null},
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.getParticipantStats(this.participantId);
      }
    },
  },
  methods: {
    getParticipantStats(participantId) {
      this.showLoader("loading stats");
      this.$http.get(`venues/facilities/bookings/race/get-participant-stats/${participantId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              if (response.data.status == true) {
                this.user = response.data.data;
              } else {
                this.showError("Participant data not found");
                this.close();
              }
              this.hideLoader();
            }
          }).catch(() => {
        this.hideLoader();
        this.close();
        this.showError("Participant data not found");
      }).finally(() => {
        this.hideLoader();
      })
    },
    close() {
      this.$emit("close");
    }
  },
  data() {
    return {
      user: {
        name: "",
        bestLap: "",
        totalLaps: 0,
        position: 0,
        vehicle_name: '',
        totalTimeTaken: null,
        firstLapStartTime: null,
        lastLapEndTime: null,
        laps: [],
      },
      imageDimensions: 110,
    }
  }
}
</script>

<style scoped>
.position-badge {
  background-color: #4dc1b5;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.circle-badge {
  border-radius: 50%;
  background-color: #4dc1b5;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.modal-heading {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #112A46;
}

.kart-name {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.03em;
  text-align: left;
  color: #111111;
}

.kart-name-bold {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-align: left;
  color: #111111;
}

.kart-text {
  font-size: 12px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #111111;
  margin-bottom: 8px;
}

.player-name {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-align: left;
}

.first-badge {
  background-color: #278303 !important;
}

.second-badge {
  background-color: #d5c713 !important;
  color: black !important;
}

.third-badge {
  background-color: #4AB1B1 !important;
}


</style>
