<template>
  <v-dialog v-model="showParticipants" max-width="760px" persistent @input="close">
    <v-card class="participants-popup">
      <v-card-text class="pa-0">
        <div class="row header-row">
          <div class="col-md-8">
            <div class="header">Participants<br>
              <span v-if="isTotalParticipantsShow" class="checkinout">Check In : {{ totalCheckIn }}</span>
              <span v-if="isTotalParticipantsShow" class="checkinout">Check Out : {{ totalCheckOut }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-search">
              <v-text-field
                  v-model="search"
                  class="q-text-field shadow-0"
                  clearable
                  dense
                  hide-details="auto"
                  label="Search Customers"
                  outlined
                  style="width: 200px"
              ></v-text-field>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <v-row class="participants-area">
          <v-col v-if="participants.length > 0" class="p-0" cols="12" md="12" subheader>
            <v-list>
              <div
                  v-for="(participant, index) in filterParticipants"
                  :key="index"
                  :class="`parent-list-items ${
                                filterParticipants[index - 1] &&
                                filterParticipants[index - 1].order_id == filterParticipants[index].order_id
                                ? 'child-row'
                                : ''
                            }`"
              >
                <v-list-item class="d-block px-0">
                  <div
                      :class="`d-flex align-center ${participant.order_customer_id != participant.customer_id?'group-customer':'main-customer'}` ">
                    <v-list-item-action>
                      <v-checkbox
                          v-if="!isTotalParticipantsShow"
                          v-model="participant.isSettle"
                          :disabled="participant.invoice_generated === 1 || participant.o_status_id !== 5 || participant.order_status_id !== 11"
                          :indeterminate="participant.invoice_generated === 1 || participant.o_status_id !== 5 || participant.order_status_id !== 11"
                          :value="participant.isSettle"
                          @change="checkedOrder(participant)"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-avatar :height="48" :width="48" class="line" rounded="0">
                      <view-image
                          :contain="false"
                          :image="participant.profile_image"
                          defaultImage="user"
                      ></view-image>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="ml-2">
                        <span class="text-truncate" style="cursor: pointer"
                              @click="showUserModel(participant.customer_id)">{{ participant.name }}</span>
                        <span class="grey--text caption displayBlock">{{ participant.invoice_seq_no }}</span>
                        <span v-if="participant.order_customer_id == participant.customer_id"
                              class="grey--text caption">
                                                {{
                            (participant.order_total + getParentOrderTotal(participant.parent_orders)) | toCurrency
                          }}
                                            </span>
                        <span v-if="participant.actual_total != null && participant.order_discount_id !== null"
                              class="text-decoration-line-through grey--text caption price">
                                                {{
                            (participant.actual_total + getParentOrderTotal(participant.parent_orders)) | toCurrency
                          }}
                                            </span>
                        <span v-if="participant.credit_owed" class="grey--text caption displayBlock">Credit Due: {{
                            participant.credit_owed | toCurrency
                          }}</span>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="d-flex flex-row justify-start align-center mt-0">
                      <div class="d-flex gap-x-4">
                        <v-btn
                            v-show="participant.check_in_time == null && participant.check_out_time == null"
                            class="btn-checkin svg-stroke-neon"
                            text
                            @click="checkInAndOut(participant.group_customer_id)"
                        >
                          <LocationIcon style="width:12px;height:12px;"></LocationIcon>
                          <span class="ml-1">Check In</span>
                        </v-btn>
                        <v-btn
                            v-show="participant.check_in_time != null && participant.check_out_time == null"
                            class="btn-checkout svg-stroke-primary"
                            text
                            @click="checkInAndOut(participant.group_customer_id)"
                        >
                          <LocationIcon style="width:12px;height:12px;"></LocationIcon>
                          <span class="ml-1">Check Out</span>
                        </v-btn>
                        <v-btn
                            v-if="participant.check_in_and_out === 'OUT'"
                            class="btn-void-checkin svg-stroke-red"
                            text
                            @click="voidCheckIn(participant.group_customer_id)"
                        >
                          <LocationIcon style="width:12px;height:12px;"></LocationIcon>
                          <span class="ml-1">Void Check In</span>
                        </v-btn>
                        <v-btn
                            v-if="participant.status_id == 5"
                            class="btn-checkout"
                            text
                            @click="getOrderDetails(participant)"
                        >
                          <InvoiceIcon/>
                          <span class="ml-1">Invoice</span>
                        </v-btn>
                        <v-btn
                            v-else
                            class="btn-checkout"
                            text
                            @click="getOrderDetails(participant)"
                        >
                          <InvoiceIcon/>
                          <span class="ml-1">Receipt</span>
                        </v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="text_capitalize btn-dot svg-stroke-primary"
                                outlined
                                v-bind="attrs"
                                v-on="on"

                            >
                              <EditBtnIcon/>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-if="participant.alert_notes != null && participant.alert_notes != ''"
                                         @click="openBooking(participant)">
                              <v-list-item-title class="fontsize-12">Alert</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="is_split_payment == 0 || (participant.so_parent_order_id != null && participant.order_id == participant.so_parent_order_id)"
                                @click="openBooking(participant)">
                              <v-list-item-title class="fontsize-12">View Booking</v-list-item-title>
                            </v-list-item>
                            <!--                                                    <v-list-item  v-if="participant.order_customer_id == participant.customer_id" @click="getOrderDetails(participant)">-->
                            <!--                                                        <v-list-item-title  class="fontsize-12">View Transaction</v-list-item-title>-->
                            <!--                                                    </v-list-item>-->
                          </v-list>
                        </v-menu>
                      </div>
                    </v-list-item-action>
                  </div>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
          <v-col v-else class="p-0" cols="12" md="12">
            <div class="d-flex justify-center align-center" style="height: 200px;"> No Participants</div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="bg-white w-full border-top" style="position: sticky; bottom: 0;">
        <!-- <span v-if="is_split_payment == 0 && invoiceAmount" class="sum-price">Price {{ invoiceAmount | toCurrency }}</span> -->


        <v-spacer></v-spacer>
        <v-btn class="ma-2" text @click="close()">Close</v-btn>
        <v-btn v-if="participants.length > 0 && !isTotalParticipantsShow" class="ma-2 white--text blue-color btn btn-print"
               @click="downloadPdf()">
          <PrintIcon/>
          Print
        </v-btn>
        <v-btn
            v-if="selectedOrders.length && !isTotalParticipantsShow"
            class="ma-2 white--text teal-color invoice-btn"
            @click="genrateInvoice"> Generate Invoice
        </v-btn>

      </v-card-actions>
    </v-card>

    <order-details
        :id="orderId"
        :ids="orderIds"
        :invId="invoiceModel.invoiceId"
        :log="false"
        @close="(orderId = null), (orderIds = null), openParticipants()"
    ></order-details>
    <golf-order-payments
        v-if="enableMultiOrderPay"
        :date="date"
        :multiBookingIds="multiBookingIds"
        :refreshBool="refreshBool"
        :show="enableMultiOrderPay"
        v-bind="bookingForm"
        @close="closeOrderPaymentDialog"
        @payed="$emit('booked'), (enableMultiOrderPay = false)"
    ></golf-order-payments>
    <customer-alert-note
        :id="alertNote.id"
        :note="alertNote.note"
        v-bind="alertNote"
        @clearAlertNote="clearAlertNote"
        @closeAlert="closeAlert"
    >
    </customer-alert-note>
    <customer-model
        :isPermissionCheck="
            checkWritePermission($modules.clients.customers.slug)
          "
        v-bind="userModel"
        @close="userModel.userID = null"
    />
  </v-dialog>
</template>

<script>
import OrderDetails from "@/components/Order/OrderDetails.vue";
import GolfOrderPayments from "@/components/Order/GolfOrderPayments.vue";
import CustomerModel from "@/views/Clients/Customer/CustomerModel";
import CustomerAlertNote from "@/views/Clients/Customer/CustomerAlertNote.vue";
import EditBtnIcon from "@/assets/images/misc/more-horizontal-square.svg";
import PrintIcon from "@/assets/images/misc/print-icon.svg";
import LocationIcon from "@/assets/images/misc/location-icon.svg";
import InvoiceIcon from "@/assets/images/misc/invoice.svg";

export default {
  props: {
    start_time: {type: String},
    showParticipants: {type: Boolean, default: false},
    end_time: {type: String},
    date: {type: String},
    facility_id: {type: Number},
    refresh: {type: Boolean},
    venue_service_id: {type: Number},
    is_split_payment: {type: Number, default: 0},
    isTotalParticipantsShow: {type: Boolean, default: false},
  },
  watch: {
    showParticipants(val) {
      if (val == true) {
        this.openParticipants();
      }
    },
    refresh() {
      this.openParticipants();
    },
    gop() {
      this.enableMultiOrderPay = true;
    },
  },
  components: {
    OrderDetails,
    CustomerAlertNote,
    GolfOrderPayments,
    CustomerModel,
    EditBtnIcon,
    PrintIcon,
    LocationIcon,
    InvoiceIcon
  },
  computed: {
    filterParticipants() {
      const search = this.search;
      return search === "" || search == null ? this.participants : this.participants.filter((participant) => participant.name.toLowerCase().includes(search.toLowerCase()));
    },
  },
  data() {
    return {
      participants: [],
      multiplePayments: [],
      orderId: null,
      orderIds: null,
      payer: null,
      orderTotal: 0,
      payerCustomerList: [],
      bookingForm: {attendance: false, attendance_count: 1, products: []},
      enableMultiOrderPay: false,
      multiBookingIds: [],
      search: null,
      products: [],
      gop: 0,
      refreshBool: false,
      alertNote: {
        show: false,
        note: null,
        id: null,
      },
      userModel: {userID: null, type: "details"},
      selectedOrders: [],
      invoiceAmount: 0,
      invoiceModel: {
        invoiceId: null,
        invoiceIds: [],
        type: "details",
        orderIds: [],
      },
      totalCheckIn: 0,
      totalCheckOut: 0,
    };
  },
  methods: {
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    openAlert(id) {
      this.showLoader();
      this.$http
          .get("venues/customers/get-alert-note/" + id)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.alertNote.id = response.data.data.customer_id;
              this.alertNote.note = response.data.data.alert_notes;
              this.alertNote.show = true;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    clearAlertNote(id) {
      this.showLoader();
      this.$http
          .post("venues/customers/clear-alert-note/" + id)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Customer alert note cleared!");
              this.closeAlert();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    closeAlert() {
      this.alertNote.show = false;
      this.openParticipants();
    },
    closeOrderPaymentDialog() {
      this.enableMultiOrderPay = false;
      this.openParticipants();
    },
    openParticipants() {
      if (!this.facility_id) {
        return;
      }
      this.showLoader("Loading");
      this.totalCheckIn = 0;
      this.totalCheckOut = 0;
      this.payer = null;
      this.payerCustomerList = [];
      let url = "participants";
      if (this.is_split_payment == 1) {
        url = "split-participants";
      }
      if (this.isTotalParticipantsShow) {
        url = `${url}?facility_id=${this.facility_id}&date=${this.date}&venue_service_id=${this.venue_service_id}&is_split_payment=${this.is_split_payment}`;
      } else {
        url = `${url}?facility_id=${this.facility_id}&start_time=${this.start_time}&end_time=${this.end_time === "00:00:00" ? "23:59:00" : this.end_time}&date=${this.date}&venue_service_id=${this.venue_service_id}&is_split_payment=${this.is_split_payment}`;
      }
      this.$http.get(`venues/facilities/bookings/golf/${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.participants = data;
              if (this.isTotalParticipantsShow) {
                this.participants.forEach((el) => {
                  if (el.check_in_and_out == "OUT" || el.check_in_and_out == "GONE") {
                    this.totalCheckIn++;
                  }
                  if (el.check_in_and_out == "GONE") {
                    this.totalCheckOut++;
                  }
                });
              }
              this.hideLoader();
            }
          });
    },
    close() {
      this.invoiceModel.invoiceId = null;
      this.$emit("close");
    },
    openBooking(data) {
      data.status = data.status_id == 5 ? "unpaid" : "paid";
      data.is_split_payment = this.is_split_payment;
      this.$emit("open-capacity-booking", data);
    },
    downloadPdf() {
      this.showLoader("Generating..");
      this.$http.get(`venues/facilities/bookings/booked-attendance-pdf?facility_id=${this.facility_id}&start_time=${this.start_time}&end_time=${this.end_time}&date=${this.date}&venue_service_id=${this.venue_service_id}`, {responseType: "blob",})
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, `OrderCustomers`, "pdf");
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getOrderDetails(participant) {
      let parentOrderIds = [];
      if (participant.parent_orders.length) {
        parentOrderIds.push(participant.order_id);
        participant.parent_orders.map((item) => {
          parentOrderIds.push(item.id);
        });
        this.orderIds = parentOrderIds;
      } else {
        this.orderIds = null;
      }
      this.orderId = participant.order_id;
    },
    isPaidCheckBoxEnable(participant) {
      // console.log(participant);
      const dates = this.participants;
      if (participant.isPaid === false) {
        let nanoIndexes = this.participants
            .map((val, i) =>
                val.order_id === participant.order_id && val.isPaid ? i : -1
            )
            .filter((index) => index !== -1);
        if (nanoIndexes.length) {
          nanoIndexes.forEach((val) => {
            this.participants[val].isPaid = false;
          });
        }
      } else if (participant.isPaid === true) {
        let nanoIndexes = this.participants
            .map((val, i) =>
                val.order_id === participant.order_id && !val.isPaid ? i : -1
            )
            .filter((index) => index !== -1);
        if (nanoIndexes.length) {
          nanoIndexes.forEach((val) => {
            this.participants[val].isPaid = true;
          });
        }
      }
      if (dates.filter((element) => element.isPaid).length > 0) {
        var formData = new FormData();
        var isPaidIndex = 0;
        var payerList = [];
        this.products = [];
        this.bookingForm.products = [];
        var uniqueOrderIds = [];
        dates.forEach((facilityBookings) => {
          if (facilityBookings.isPaid) {
            if (uniqueOrderIds.indexOf(facilityBookings.order_id) !== -1) {
              return;
            }
            uniqueOrderIds.push(facilityBookings.order_id);

            payerList.push({
              customer_id: facilityBookings.customer_id,
              customer_name: facilityBookings.name,
            });
            formData.append(
                `orderIds[${isPaidIndex}]`,
                facilityBookings.order_id
            );
            isPaidIndex++;
            this.bookingForm.id = facilityBookings.id;
            this.bookingForm.date = facilityBookings.date;
            this.bookingForm.end_time = facilityBookings.end_time;
            this.bookingForm.facility_id = facilityBookings.facility_id;
            this.bookingForm.facility_name = facilityBookings.facility_name;
            this.bookingForm.isPaid = facilityBookings.isPaid;
            this.bookingForm.min_booking_time =
                facilityBookings.min_booking_time;
            this.bookingForm.order_id = facilityBookings.order_id;
            this.bookingForm.per_capacity = facilityBookings.per_capacity;
            this.bookingForm.start_time = facilityBookings.start_time;
            this.bookingForm.status_id = facilityBookings.status_id;
          }
        });
        this.showLoader("Loading");
        this.$http
            .post(
                `venues/facilities/bookings/multiple-participant-products`,
                formData
            )
            .then((response) => {
              this.hideLoader();
              if (response.status == 200 && response.data.status == true) {
                if (response.data.data) {
                  let products = response.data.data.products;
                  let orderTotal = 0;
                  this.products = products;

                  this.payerCustomerList = payerList;
                  this.payer = this.payerCustomerList[0];
                  this.multiplePayments = this.participants;
                  this.products.forEach((item) => {
                    orderTotal += item.price + item.total_tax_amount;
                    let pata = {
                      order_item_id: item.id,
                      product_id: item.product_id,
                      product_type_id: item.product_type_id,
                      category_id: item.category_id,
                      quantity: item.quantity,
                      price: item.price,
                      rental: item.rental != null ? true : false,
                      discount: item.discount ? item.discount : false,
                      name: item.name ? item.name : "Product Name",
                      product_price: item.product_price,
                      tax: item.total_tax_amount,
                      total_tax_amount: item.total_tax_amount,
                      total_price: item.price + item.total_tax_amount,
                      venue_service_id: this.venue_service_id,
                    };
                    this.bookingForm.products.push(pata);
                  });
                  this.orderTotal = orderTotal;
                  // console.log(this.bookingForm.products);
                }
              }
            })
            .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
            });
      } else if (dates.filter((element) => element.isPaid).length == 0) {
        this.payerCustomerList = [];
        this.payer = null;
        this.bookingForm = [];
        this.orderTotal = 0;
      }
    },

    showMultiPaymentsConfirmation() {
      if (
          this.multiplePayments.filter(
              (element) => element.isPaid && element.status_id == 5
          ).length == 1
      ) {
        this.bookingForm.showBookingForm = false;
        this.payerCustomerList = [];
        // console.log(this.bookingForm);
        this.orderId = this.bookingForm.order_id;
      } else if (
          this.multiplePayments.filter(
              (element) => element.isPaid && element.status_id == 1
          ).length > 0
      ) {
        this.showError("Please unselect paid date");
      } else if (
          this.multiplePayments.filter(
              (element) => element.isPaid && element.status_id == 5
          ).length > 1
      ) {
        this.gop++;
        // console.log("pay");
        this.multiplePayment();
      } else {
        this.showError("Please select participant for pay");
      }
    },
    multiplePayment() {
      if (this.bookingForm.products.length == 0) {
        this.showError("Please add at least one product");
        return;
      }
      if (
          this.multiplePayments.filter(
              (element) => element.isPaid && element.status_id == 5
          ).length > 0
      ) {
        this.multiBookingIds = [];

        this.multiplePayments.forEach((el) => {
          if (el.isPaid && el.status_id == 5) {
            this.multiBookingIds.push(el);
          }
        });
        this.bookingForm.customer_id = this.payer.customer_id;
        this.bookingForm.customer_name = this.payer.customer_name;
        this.enableMultiOrderPay = true;
      }
      this.$forceUpdate();
    },
    changePayer() {
      this.bookingForm.customer_id = this.payer.customer_id;
      this.bookingForm.customer_name = this.payer.customer_name;
    },
    getPayerName(order_id) {
      let name = this.participants.find(
          (x) => x.order_id === order_id && x.order_customer_id === x.customer_id
      ).name;
      return name;
    },
    getColourDisabled(status_id) {
      if (status_id === 1) {
        return "success";
      } else {
        return "warning";
      }
    },
    getParentOrderTotal(parentOrders) {
      let total = 0;
      parentOrders.map((item) => {
        total += item.total;
      });
      return total;
    },
    voidCheckIn(id) {
      this.showLoader("Loading");
      this.$http
          .get(`venues/facilities/bookings/void-check-in/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.openParticipants();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    checkInAndOut(id) {
      this.showLoader("Loading");
      this.$http
          .get(`venues/facilities/bookings/check-in-and-out/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.openParticipants();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    checkedOrder(participant) {
      let invAmount = 0;
      let selectedOrd = [];
      this.selectedOrders = [];
      if (!participant.isSettle) {
        this.participants.map((p) => {
          if (p.order_id === participant.order_id) {
            p.isSettle = false;
          }
        });
      } else if (participant.isSettle === true) {
        this.participants.map((item) => {
          if (item.order_id === participant.order_id) {
            item.isSettle = true;
          }
        });
      }
      /** calculate selected order */
      this.participants.map((item) => {
        if (item.isSettle === true) {
          const oIndex = selectedOrd.findIndex(
              (or) => or.order_id === item.order_id
          );
          if (oIndex === -1) {
            /** to fixed  */
            let arr = {
              order_id: item.order_id,
              total: parseFloat(item.order_total),
            };
            invAmount += item.order_total;
            selectedOrd.push(arr);
          }
        }
      });

      this.selectedOrders = selectedOrd;
      this.invoiceAmount = invAmount;
    },
    /** invoice functions */
    genrateInvoice() {
      if (this.selectedOrders.length === 0) {
        this.showError("Select Order");
        return false;
      }
      this.showLoader("Wait ...");
      let formData = new FormData();
      this.selectedOrders.forEach((item, i) => {
        formData.append(`order_ids[${i}]`, item.order_id);
      });
      this.$http
          .post("venues/invoices", formData)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
              this.selectedOrders = [];
              let data = response.data.data;
              this.invoiceData = data;
              this.invoiceModel.invoiceId = data.id;
              this.invoiceModel.orderIds = this.selectedOrders;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.parent-list-items:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

.parent-list-items.child-row {
  border-top: 0px;
  padding-left: 50px;
  position: relative;
}

.parent-list-items.child-row:before {
  // content: "";
  // counter-increment: item;
  // position: absolute;
  // top: -10px;
  // left: -30px;
  // border-left: 1px solid #ddd;
  // border-bottom: 1px solid #ddd;
  // width: 32px;
  // height: 15px;
}

.parent-list-items.child-row:after {
  // position: absolute;
  // content: "";
  // top: 5px;
  // left: -30px;
  // border-left: 1px solid #ddd;
  // border-top: 1px solid #ddd;
  // width: 30px;
  // height: 100%;
}

.fontsize-12 {
  font-size: 12px;
}

.participants-popup .header-row {
  padding-top: 10px;
  margin: 5px;
}

.header, span.sum-price {
  line-height: 2;
  letter-spacing: 0.48px;
  color: var(--dark-blue, #112A46);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

span.sum-price {
  font-size: 14px;
}

.text-search {
  width: 100%;
  display: flex;
}

.participants-area {
  padding: 10px 0px;
  margin: 0px 20px;
}

.parent-list-items {
  color: #112A46;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-dot.v-btn {
  min-width: 24px !important;
  height: 30px !important;
  flex-shrink: 0;
  padding: 0px !important;
}

button.btn-checkin {
  color: #4FAEAF !important;
  font-size: 10px !important;
  border: 0.75px solid #4FAEAF;
  background: rgba(79, 174, 175, 0.10);
  height: 30px !important;
  flex-shrink: 0;
}

button.btn-checkout {
  height: 30px !important;
  flex-shrink: 0;
  color: #011627 !important;
  font-size: 10px !important;
  border: 0.75px solid #011627;
  background: rgba(1, 22, 39, 0.10);
}

button.btn-void-checkin {
  color: #E50000 !important;
  font-size: 10px !important;
  height: 30px !important;
  flex-shrink: 0;
  border: 0.75px solid #E50000;
  background: rgba(229, 0, 0, 0.10);
}

button.btn-print, button.btn-void-checkin, button.btn-checkout, button.btn-checkin, button.btn-invoice {
  width: 100px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3px;
}

button.btn-void-checkin {
  width: 120px;
}


.modal-buttons {
  border-top: 1px inset;
}

span.cdisplayBlock {
  display: block;
  padding-top: 5px;
}

span.displayBlock {
  display: block;
}

span.checkinout {
  font-size: 12px;
  padding: 10px 10px 0px 0px;
}
</style>
