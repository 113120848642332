<template>
  <v-sheet
    color="#E9F1F6"
    dark
    width="194"
    height="80px"
    class="title flex-grow-0 flex-shrink-0 text-center pb-1"
    style="position: relative; color:black; font-weight: 600"
  >
    <span
      class="facility-online-badge caption"
      v-if="name != 'Time' && isEnableOnline && checkWritePermission($modules.schedule.availability.slug)"
    >
      <v-btn
        icon
        class="facility-online-btn"
        v-if="facilityOnlineDisabled"
        @click="onlineEnableToggle(facilityId, 0)"
      >
        <v-icon :color="'#df5716'">mdi-lightning-bolt-circle</v-icon></v-btn
      >
      <v-btn
        icon
        class="facility-online-btn"
        v-else
        @click="onlineEnableToggle(facilityId, isPublic)"
      >
        <v-icon :color="isPublic == 1 ? 'success' : '#df5716'"
          >mdi-lightning-bolt-circle</v-icon
        ></v-btn
      >
    </span>
    <v-tooltip bottom v-if="tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          {{ name }}
        </div>
      </template>
      {{ tooltip }}
    </v-tooltip>
    <div v-else class="facility-name_header" :title="name">
      {{ name }}
    </div>
    <span class="c-booking-btn" v-if="perCapacity && name != 'Booking Time'">
      <v-btn
        color="black"
        x-small
        dark
        outlined
        block
        @click="showTotalAttendees"
        >Total {{ totalAttendees }}
        <span v-if="isEnablePerDayCapacity">/ {{ perDayCapacity }} </span>
      </v-btn>
    </span>
    <div class="caption mb-2" v-if="isGameFormationEnabled && formation.length">
      ({{ gameFormations() }})
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    perCapacity: { type: Number, default: 0 },
    name: { type: String, default: "Pitch Name" },
    date:{type :String, default: null},
    isEnableOnline: { type: Number, default: 0 },
    isGameFormationEnabled:{ type:Number, default:1},
    isPublic: { type: Number, default: 1 },
    facilityId: { type: Number, default: 1 },
    formation: { type: Array, default: () => [] },
    tooltip: { type: String },
    facilityOnlineDisabled: { type: Object, default: null },
    totalAttendees: { type: Number, default: 0 },
    totalCapacity: { type: Number, default: 0 },
    perDayCapacity: { type: Number, default: 0 },
    isEnablePerDayCapacity: { type: Number, default: 0 },
  },
  methods: {
    gameFormations() {
      return this.formation.map((item) => item.name).join(",");
    },
    onlineEnableToggle(facility_id, is_public) {
      this.$emit("enableDisableOnlineFacility", {
        facility_id: facility_id,
        is_public: is_public,
        date:this.date
      });
    },
    showTotalAttendees() {
      return this.$emit("showTotalAttendees");
    },
  },
};
</script>

<style>
span.c-booking-btn {
  width: 150px;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.facility-online-badge {
  position: absolute;
  top: 7px;
  right: -6px;
  z-index: +1;
}
.facility-name_header {
  padding-top: 12px;
  font-size: 18px;
}
.facility-name_header_inner {
  padding-top: 12px;
  font-size: 12px;
}
</style>