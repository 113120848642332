<template>
  <v-card
    color="grey"
    :height="height"
    width="194"
    tile
    class="flex-grow-0 flex-shrink-0"
  >
    <!-- <draggable v-model="slots" @start="drag = true" @end="drag = false"> -->

    <template v-for="slot in bookings">
      <booking-slot
        v-if="perCapacity == 0 && slot.is_split_payment !== 1"
        :height="slot.height"
        :class="`pa-2 text-center ${slot.index}`"
        :key="slot.index"
        :status="slot.status"
        :fillHeight="slot.fill_height"
        :name="slot.name"
        :index="slot.index"
        :creditOwed="slot.credit_owed"
        :repeat="slot.repeat_id ? true : false"
        :isOverNight="slot.is_over_night"
        :id="slot.id"
        @click="onClickSlot(slot)"
      >
      </booking-slot>
      <capacity-slot
        v-else
        :key="slot.index"
        :height="slot.height"
        :class="`pa-2 text-center ${slot.index}`"
        :status="slot.status"
        :bookings="slot.bookings"
        :reserved-quantity="slot.reserved_quantity"
        :isPast="slot.isPast"
        :name="slot.name"
        :creditOwed="slot.credit_owed"
        :index="slot.index"
        :capacity="slot.split_capacity > 0 ? slot.split_capacity : capacity"
        :perDayCapacity="perDayCapacity"
        :isEnablePerDayCapacity="isEnablePerDayCapacity"
        :splitCapacity="slot.split_capacity > 0 ? true : false"
        @click="onClickSlot(slot)"
        @participants="getParticipants(slot)"
        :totalAttendance="totalAttendance"
      ></capacity-slot>
      <v-divider :key="`d_${slot.index}`"></v-divider>
    </template>
    <!-- </draggable> -->
  </v-card>
</template>

<script>
// import draggable from "vuedraggable";
import BookingSlot from "./BookingSlot";
import CapacitySlot from "./CapacitySlot";
export default {
  props: {
    bookings: { type: Array, default: () => [] },
    increment: { type: Number, default: 60 },
    height: { type: Number, default: 1000 },
    name: { type: String },
    id: { type: Number },
    date: { type: String },
    perCapacity: { type: Number, default: 1 },
    minBookingTime: { type: Number, default: 60 },
    capacity: { type: Number, default: 0 },
    perDayCapacity: { type: Number, default: 0 },
    isEnablePerDayCapacity: { type: Number, default: 0 },
    totalAttendance: { type: Number, default: 0 },
  },
  components: {
    // draggable,
    BookingSlot,
    CapacitySlot,
  },
  data() {
    return {};
  },
  methods: {
    onClickSlot(data) {
      let slotData = data;
      if (this.perCapacity == 1 || this.isSplitPayment == 1) {
        delete slotData.order_id;
        delete slotData.id;
      }
      slotData.capacity = this.capacity;
      slotData.facility_name = this.name;
      slotData.facility_id = this.id;
      slotData.per_capacity = this.perCapacity;
      slotData.min_booking_time = this.minBookingTime;

      this.$emit("open-booking-form", slotData);
    },
    getParticipants(data) {
      let slotData = data;
      slotData.capacity = this.capacity;
      slotData.facility_name = this.name;
      slotData.facility_id = this.id;
      slotData.per_capacity = this.perCapacity;
      slotData.min_booking_time = this.minBookingTime;
      this.$emit("open-participans-model", slotData);
    },
  },
};
</script>

<style>
</style>