var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white border-top rounded-lg pb-8 mt-4 relative"},[_c('v-btn',{attrs:{"color":"error","x-small":"","fab":"","absolute":"","top":"","right":""},on:{"click":_vm.clearRepeat}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-row',{staticClass:"pa-4"},[_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('label',{attrs:{"for":""}},[_vm._v("Booking date")]),_c('date-field',{attrs:{"outlined":"","background-color":"#fff","value":_vm.repeatForm.date,"dayName":true,"backFill":_vm.checkBackfillPermission(_vm.$modules.facility.schedule.slug),"class-name":"q-text-field shadow-0","dense":true,"hide-details":true,"label":""},on:{"change":function($event){return _vm.changeFieldValue($event, 'date')}}})],1),_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('label',{attrs:{"for":""}},[_vm._v("Start Time")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"label":"","outlined":"","menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","item-text":"formatted","item-value":"time","items":_vm.repeatForm.start_times,"value":_vm.repeatForm.start_time,"dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'start_time')}}})],1),_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('label',{staticClass:"text-dark-gray font-medium text-xs",attrs:{"for":""}},[_vm._v("End Time")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"outlined":"","menu-props":{ bottom: true, offsetY: true },"label":"","background-color":"#fff","item-text":"formatted","item-value":"time","items":_vm.repeatForm.end_times,"value":_vm.repeatForm.end_time,"rules":[function (v) { return !!v || 'End time is required'; }],"dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'end_time')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.formatted)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.formatted)}})]}}])})],1),_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('label',[_vm._v("Facility*")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"item-value":"id","item-text":"name","items":_vm.facilities,"label":"","outlined":"","background-color":"#fff","dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'facility_id')}},model:{value:(_vm.repeatForm.facility_id),callback:function ($$v) {_vm.$set(_vm.repeatForm, "facility_id", $$v)},expression:"repeatForm.facility_id"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Type")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":['Daily', 'Weekly', 'Monthly', 'Advanced'],"label":"","value":_vm.repeatForm.repeatType,"outlined":"","background-color":"#fff","disabled":_vm.repeatForm.repeatId > 0,"dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'repeatType')}}})],1),(_vm.repeatForm.repeatType == 'Weekly')?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Weekdays")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.weekdays,"multiple":"","item-value":"value","item-text":"name","label":"","value":_vm.repeatForm.repeatWeekdays,"outlined":"","background-color":"#fff","disabled":_vm.repeatForm.repeatId > 0,"dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'repeatWeekdays')}},scopedSlots:_vm._u([(_vm.weekdays.length == _vm.repeatForm.repeatWeekdays.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v("All Days")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", "+_vm._s(item.name))]):_vm._e(),(index == 2)?_c('span',[_vm._v(", ...")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.repeatForm.repeatType != 'Advanced')?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Repeat Every")]),_c('v-text-field',{staticClass:"q-text-field shadow-0",attrs:{"outlined":"","background-color":"#fff","disabled":_vm.repeatForm.repeatType == null || _vm.repeatForm.repeatType == 'Never',"value":_vm.repeatForm.repeatNumber,"label":"","type":"number","min":"1","suffix":(_vm.repeatForm.repeatType == 'Monthly'
            ? 'Month'
            : _vm.repeatForm.repeatType == 'Weekly'
            ? 'Week'
            : 'Day') + '(s)',"dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'repeatNumber')}}})],1):_vm._e(),(_vm.repeatForm.repeatType != 'Advanced')?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Repeat End")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"disabled":_vm.repeatForm.repeatType == null,"value":_vm.repeatForm.repeatEndType,"label":"","items":['Count', 'Date'],"outlined":"","background-color":"#fff","dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'repeatEndType')}}})],1):_vm._e(),(
        _vm.repeatForm.repeatType != 'Advanced' &&
        (_vm.repeatForm.repeatEndType == null ||
          _vm.repeatForm.repeatEndType == 'Count')
      )?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("End After")]),_c('v-text-field',{staticClass:"q-text-field shadow-0",attrs:{"outlined":"","background-color":"#fff","disabled":_vm.repeatForm.repeatType == null,"value":_vm.repeatForm.repeatOccurrence,"label":"","type":"number","suffix":"Occurrence","min":"0","dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.changeFieldValue($event, 'repeatOccurrence')}}})],1):(_vm.repeatForm.repeatType != 'Advanced')?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Date")]),_c('date-field',{attrs:{"value":_vm.repeatForm.repeatEndDate,"dense":true,"label":"","class-name":"q-text-field shadow-0","hide-details":true,"backFill":_vm.checkBackfillPermission(_vm.$modules.facility.management.slug)},on:{"change":function($event){return _vm.changeFieldValue($event, 'repeatEndDate')}}})],1):_vm._e(),(_vm.repeatForm.repeatType == 'Advanced')?_c('v-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Dates")]),_c('multi-date-field',{attrs:{"value":_vm.repeatForm.repeated_dates,"dense":true,"label":"","class-name":"q-text-field shadow-0","hide-details":'auto'},on:{"input":function($event){return _vm.changeFieldValue($event, 'repeated_dates')}}})],1):_vm._e()],1),(_vm.repeatForm.repeated_dates.length > 0)?_c('div',{staticClass:"d-flex flex-wrap",attrs:{"no-gutters":""}},[_c('v-row',_vm._l((_vm.repeatForm.repeated_dates),function(date){return _c('v-col',{key:date,attrs:{"md":"2"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":_vm.checkExcluded(date)
              ? 'error'
              : _vm.checkIncluded(date)
              ? 'success'
              : ''},on:{"click":function($event){return _vm.changeDateSelection(date)}}},[_c('v-icon',{attrs:{"color":_vm.checkExcluded(date)
                ? 'error'
                : _vm.checkIncluded(date)
                ? 'success'
                : ''}},[_vm._v(_vm._s(_vm.checkExcluded(date) ? "mdi-minus-box" : _vm.checkIncluded(date) ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))]),_vm._v(" "+_vm._s(_vm._f("calendarYearFormat")(date))+" ")],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }