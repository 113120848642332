<template>
  <div class="d-flex p-2">
    <template>
      <div class="nv_item d-flex p-4 pointer" @click="gotoSchedule">
        <SvgIcon :class="[scheduleClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']"
                 text="Schedule">
          <template v-slot:icon>
            <SchedulesIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>
    <template v-if="checkReadPermission($modules.facility.approval.slug)">
      <div class="nv_item d-flex p-4 pointer" @click="goToApprovals">
        <SvgIcon :class="[approvalsClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']"
                 text="Approvals">
          <template v-slot:icon>
            <SchedulesIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>

    <template v-if="checkReadPermission($modules.schedule.configuration.slug)">
      <div class="nv_item d-flex p-4 pointer" @click="goToConfiguration">
        <SvgIcon :class="[configClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']"
                 text="Configuration">
          <template v-slot:icon>
            <ConfigIcon/>
          </template>
        </SvgIcon>
      </div>
    </template>

  </div>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import SchedulesIcon from "@/assets/images/memberships/calendar-icon.svg";
import ConfigIcon from "@/assets/images/memberships/cog_icon.svg";

export default {
  name: "ScheduleTabs",
  components: {ConfigIcon, SchedulesIcon, SvgIcon},
  props: {
    scheduleClass: {type: Boolean, default: false},
    configClass: {type: Boolean, default: false},
    approvalsClass: {type: Boolean, default: false},
    venue_serviceId: null,
  },
  methods:{
    goToConfiguration() {
      if (this.venue_serviceId) {
        this.$router.push({
          name: "ScheduleConfiguration",
          params: { data: btoa(this.venue_serviceId) },
        });
      } else {
        this.errorChecker("Please select service");
      }
    },
    gotoSchedule(){
      this.$router.push({name: "Schedule"}, () => {
      });
    },
    goToApprovals(){
      this.$router.push({
        name: "BookingApprovals",
      });
    },
  }
}
</script>

<style scoped>

</style>