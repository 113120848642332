<template>
  <div>
    <v-form>
      <v-dialog v-model="showBookingForm" width="80%" scrollable persistent>
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title>
              {{ "Add Booking" }}
              <v-spacer></v-spacer>
              <div
                v-if="promotions.length > 0 && perCapacity == 0"
              >
                <label for="">Promotions</label>
                <v-autocomplete
                  v-if="this.bookingForm.card_number == null"
                  :items="[
                    { name: 'None', promotion_code: null },
                    ...promotions,
                  ]"
                  item-text="name"
                  label=""
                  item-value="promotion_code"
                  v-model="bookingForm.promotion_code"
                  outlined
                  @change="verifyBenefit('promotion')"
                  :readonly="disablePromotion"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                >
                </v-autocomplete>
              </div>
              <div
                  v-if="
                      checkReadPermission($modules.salesTeam.management.slug)
                    "
              >
                <label for="">Sales Team</label>
                <v-select
                    v-model="bookingForm.sales_team_id"
                    label=""
                    :items="salesTeams"
                    item-text="name"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0 ml-2"
                ></v-select>
              </div>

            </v-card-title>
            <div class="d-flex justify-space-around headline pa-4">
              <div class="pitch">Facility: {{ facility_name }}</div>
              <div class="pitch">Service: {{ service }}</div>
              <div class="pitch" v-if="perCapacity == 0">
                Price
                {{ bookingForm.total_price | toCurrency }}
                <span
                  v-if="bookingForm.discount != null && bookingForm.price != bookingForm.discount.actual_price"
                  class="text-decoration-line-through"
                >{{ bookingForm.discount.actual_total | toCurrency }}</span>
              </div>
              <div class="pitch" v-else>
                Total {{ grandTotal() | toCurrency }}
              </div>
            </div>

            <v-card-text>
              <div
                class="bg-white bordered rounded-lg pb-8"
              >
                <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-4">
                    <div class="title-text">Booking Details</div>
                  <div class="d-flex justify-end align-start" v-if="!id && perCapacity == 0">
                    <v-switch
                      class="mr-0 pa-0 mt-0"
                      v-model="bookingForm.repeat"
                      label=""
                      dense
                      id="repeat_booking"
                      hide-details
                      @change="changeRepeatBookingSwitch"
                    ></v-switch
                  >
                    <label for="repeat_booking" class="toggle-switch-label">Repeat bookings</label>
                  </div>
                </div>
                <div class="px-4 pt-2">
                  <v-row class="mt-0" v-if="!bookingForm.repeat || id > 0">
                    <v-col sm="4" md="4">
                      <label for="">Booking date</label>
                      <date-field
                          outlined
                          background-color="#fff"
                          :readonly="id==0|| perCapacity ===0"
                          v-model="bookingForm.date"
                          :dayName="true"
                          :disabled="id==0|| perCapacity ===0"
                          class-name="q-text-field shadow-0"
                          :dense="true"
                          :hide-details="true"
                          label=""
                      >
                      </date-field>
                    </v-col>
                    <v-col sm="4" md="4">
                      <label for="" class="text-dark-gray font-medium text-xs">Start Time</label>
                      <v-select
                          hint="Start Time"
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          item-text="formatted"
                          item-value="time"
                          :items="startTimes"
                          @change="changeStartTime"
                          :readonly="bookingForm.repeat || fullDay || id == 0 || perCapacity ===0"
                          v-model="bookingForm.start_time"
                          :rules="[(v) => !!v || 'Start time is required']"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                          outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col sm="4" md="4">
                      <label for="" class="text-dark-gray font-medium text-xs">End Time</label>
                      <v-select
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          item-text="formatted"
                          item-value="time"
                          :items="endTimes"
                          @change="getRentalProducts"
                          :readonly="bookingForm.repeat || fullDay || perCapacity == 1 || (id != 0 && perCapacity ===0)"
                          v-model="bookingForm.end_time"
                          :rules="[(v) => !!v || 'End time is required']"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                      >
                        <template v-slot:item="{ item }">
                          <span v-html="item.formatted"></span>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span v-html="item.formatted"></span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
                <v-row v-if="bookingForm.repeat && !id">
                  <v-col
                    md="12"
                    v-for="(repeat, index) in $store.getters.getRepeats"
                    :key="`repeat_${index}`"
                  >
                    <repeat-booking
                      @repeat="setRepeatData"
                      @remove="removeRepeatRow"
                      :date="repeat.date"
                      :facilityId="facility_id"
                      :venueServiceId="venue_service_id"
                      :index="index"
                      :minBookingTime="minBookingTime"
                      :enableOvernightBooking="enableOvernightBooking"
                      :facilities="facilities"
                    ></repeat-booking>
                  </v-col>
                </v-row>
                <div class="ml-4" v-if="bookingForm.repeat && !id">
                  <v-btn
                      class="mt-4"
                      color="teal-color"
                      dark
                      elevation="0"
                      @click="addRepeatRow"
                  >
                    + Add Booking
                  </v-btn>

                </div>
<!--                <v-row class="mt-0">-->
<!--                  <v-col-->
<!--                    sm="4"-->
<!--                    md="4"-->
<!--                    v-if="-->
<!--                      checkReadPermission($modules.salesTeam.management.slug)-->
<!--                    "-->
<!--                  >-->
<!--                    <v-select-->
<!--                      v-model="bookingForm.sales_team_id"-->
<!--                      label="Sales Team"-->
<!--                      :items="salesTeams"-->
<!--                      item-text="name"-->
<!--                      item-value="id"-->
<!--                      outlined-->
<!--                      :menu-props="{ bottom: true, offsetY: true }"-->
<!--                      background-color="#fff"-->
<!--                    ></v-select>-->
<!--                  </v-col>-->
<!--                </v-row>-->
              </div>

              <ProductSelection
                  :key="refreshComponent"
                  :products="bookingForm.products"
                  :categories="categories"
                  :categoriesList="categoriesList"
                  :taxTypes="taxTypes"
                  :bookingForm="bookingForm"
                  :productCatId="productCategoryId"
                  :venueServiceId="venue_service_id"
                  :productCombinations="productCombinations"
                  :deletedProducts="deletedProducts"
                  :currentOrderProducts="currentOrderProducts"
                  :repeatId="repeatId"
                  @chooseRentalCombination="chooseRentalCombination"
                  @deleteProduct="deleteProduct"
                  @setCurrentOrderProducts="setCurrentOrderProducts"
                  @removeProduct="
                      (productIndex) => removeProduct(productIndex)
                    "
                  @setCustomerProduct="
                      (data) => setCustomerProduct(null, data)
                    "
              />

              <div>
                <div class="bg-white bordered rounded-lg pb-8 mt-8">

                  <customer-booking-form
                    :key="customerFormRefresh"
                    :perCapacity="perCapacity"
                    :promotions="promotions"
                    :venue_service_id="venue_service_id"
                    :singleBookingForm="bookingForm"
                    :id="id"
                    :order_id="order_id"
                    :countries="countries"
                    :companies="companies"
                    :idProofTypes="idProofTypes"
                    :disablePromotion="disablePromotion"
                    :tags="tags"
                    @setCustomerData="(data) => setCustomerData(data)"
                    @setMemberData="(data) => setMemberData(data)"
                    @customerTypeChange="customerTypeChange($event, null)"
                    @verifyBenefit="(type) => verifyBenefit(type)"
                    @clearBenefit="(data) => clearBenefit()"
                  />
                  <div
                    v-for="(addOns, index) in bookingCustomersAddons"
                    :key="index"
                    class="bg-white bordered rounded-lg pb-8 mt-8 relative"
                  >
                    <v-spacer></v-spacer>
                    <customer-booking-form
                      :addOn="1"
                      :perCapacity="perCapacity"
                      :promotions="promotions"
                      :venue_service_id="venue_service_id"
                      :singleBookingForm="addOns"
                      :id="id"
                      :order_id="order_id"
                      :countries="countries"
                      :companies="companies"
                      :idProofTypes="idProofTypes"
                      :tags="tags"
                      :disablePromotion="disablePromotion"
                      @removeCustomerAdd="
                        (data) => removeAddonCustomer(index, null)
                      "
                      @setCustomerData="
                        (data) => setCustomerDataAddon(data, index)
                      "
                      @customerTypeChange="customerTypeChange($event, null)"
                    />
                  </div>
                  <div
                    v-if="
                      perCapacity == 1 && bookingFormAdded < bookingFormTotal
                    "
                    class="add_btn pb-4"
                    style="margin-top: -30px"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="teal"
                          fab
                          x-small
                          dark
                          @click="addBookingFormCustomer"
                        >
                          <v-icon small>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      Add
                    </v-tooltip>
                  </div>
                  <v-card
                    outlined
                    color="#edf9ff"
                    class="mt-2"
                    style="border: 1px #ccc solid"
                    v-if="uploadedAttachments.length > 0"
                  >
                    <v-card-text>
                      <div class="titles">Uploaded Attachments</div>
                      <template v-for="doc in uploadedAttachments">
                        <v-chip
                          :key="doc.id"
                          v-if="doc.document_path"
                          color="grey accent-4 mr-2 mb-2"
                          dark
                          label
                          @click="openFile(doc.document_path)"
                          small
                        >
                          {{ doc.document_name }}
                        </v-chip>
                      </template>
                    </v-card-text>
                  </v-card>

                </div>
                <div
                    class="bg-white bordered rounded-lg pb-4 mt-8"
                    v-if="venueServiceDocuments.length > 0"
                >
                    <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-4">
                      <div class="title-text">Documents</div>
                    </div>
                  <div class="pa-4">
                    <template  v-for="doc in venueServiceDocuments">
                      <v-chip
                          :key="doc.id"
                          v-if="doc.file_path"
                          color="grey accent-4 mr-2"
                          dark
                          label
                          @click="openFile(doc.file_path)"
                          small
                      >
                        {{ doc.name }}
                      </v-chip>
                    </template>
                  </div>
                </div>
                <v-row v-if="!order_id" style="margin-top: 5px">
                  <v-col md="4">
                    <v-switch
                      v-model="bookingForm.attendance"
                      label="Add Guest"
                      v-if="
                        attendanceSwitch &&
                          perCapacity == 1 &&
                          attendies < capacity
                      "
                      @change="changeAttendanceSwitch"
                    ></v-switch>
                  </v-col>
                  <v-col md="6"></v-col>
                  <v-col md="2" v-if="perCapacity == 1">
                    <!-- <v-text-field
                      :disabled="bookingForm.attendance"
                      type="number"
                      label="Participants Count"
                      outlined
                      rows="2"
                      background-color="#fff"
                      required
                      v-model="bookingForm.attendance_count"
                      @keyup="checkSlotCapacity()"
                      @change="
                        checkSlotCapacity();
                        if (!bookingForm.attendance_count)
                          bookingForm.attendance_count = 1;
                      "
                    ></v-text-field> -->
                  </v-col>
                </v-row>
                <div  v-if="bookingForm.attendance && !order_id">
                  <div
                    v-for="(customer, index) in attendanceCustomers"
                    :key="index"
                    style="margin-top: 5px"
                    class="relative"
                  >
                    <div
                      class="bg-white bordered rounded-lg pb-8 mt-4"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            x-small
                            absolute
                            top
                            right
                            @click="removeCustomer(index)"
                          >
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        Delete
                      </v-tooltip>
                      <customer-booking-form
                        :key="index"
                        :perCapacity="perCapacity"
                        :promotions="promotions"
                        :companies="companies"
                        :singleBookingForm="customer"
                        :venue_service_id="venue_service_id"
                        :id="id"
                        :order_id="order_id"
                        :tags="tags"
                        :countries="countries"
                        :idProofTypes="idProofTypes"
                        @setCustomerData="
                          (data) => setCustomerData(data, index)
                        "
                        @setMemberData="(data) => setMemberData(data, index)"
                        @customerTypeChange="customerTypeChange($event, index)"
                        @verifyBenefit="(type) => verifyBenefit(type, index)"
                        @clearBenefit="(data) => clearBenefit(index)"
                      />

                      <v-card
                        v-for="(addOns, index1) in attendanceCustomersAddons[
                          index
                        ]"
                        :key="index1 + 'hi'"
                        color="#edf9ff"
                        style="border: 1px #ccc solid"
                        class="pa-5 mt-5 mb-6"
                        outlined
                      >
                        <v-spacer></v-spacer>
                        <customer-booking-form
                          :addOn="order_id ? 2 : 1"
                          :perCapacity="perCapacity"
                          :promotions="promotions"
                          :venue_service_id="venue_service_id"
                          :singleBookingForm="addOns"
                          :id="id"
                          :order_id="order_id"
                          :countries="countries"
                          :tags="tags"
                          :companies="companies"
                          :idProofTypes="idProofTypes"
                          :disablePromotion="disablePromotion"
                          @removeCustomerAdd="
                            (data) => removeAddonCustomer(index1, index)
                          "
                          @setCustomerData="
                            (data) => setCustomerDataAddon(data, index, index1)
                          "
                          @customerTypeChange="customerTypeChange($event, null)"
                        />
                      </v-card>
                      <div
                        v-if="
                          perCapacity == 1 &&
                            attendanceCustomerAdded[index] <
                              attendanceCustomersTotal[index]
                        "
                        class="add_btn pb-4"
                        style="margin-top: -30px"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="teal"
                              fab
                              x-small
                              dark
                              @click="addAttendanceCustomer(index)"
                            >
                              <v-icon small>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          Add
                        </v-tooltip>
                      </div>

                      <v-row>
                        <v-col md="12">
                          <ProductSelection
                            :key="index"
                            :products="customer.products"
                            :categories="categories"
                            :categoriesList="categoriesList"
                            :taxTypes="taxTypes"
                            :productCatId="customer.productCategoryId"
                            :venueServiceId="venue_service_id"
                            :productCombinations="productCombinations"
                            :deletedProducts="deletedProducts"
                            :bookingForm="customer"
                            :currentOrderProducts="currentOrderProducts"
                            @chooseRentalCombination="chooseRentalCombination"
                            @deleteProduct="deleteProduct"
                            @setCurrentOrderProducts="setCurrentOrderProducts"
                            @removeProduct="
                              (productIndex) =>
                                removeProduct(productIndex, index)
                            "
                            @setCustomerProduct="
                              (data) => setCustomerProduct(index, data)
                            "/></v-col
                      ></v-row>
                    </div>
                  </div>
                  <v-row>
                    <v-col
                      sm="5"
                      md="12"
                      v-if="addAttandanceBtn && bookedCapacity + 1 < capacity"
                    >
                      <div class="add_btn">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="blue-color"
                              fab
                              x-small
                              dark
                              @click="addAttandance"
                            >
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          Add
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <repeat-bookingPayment
                v-if="repeatId"
                v-bind="bookingForm"
                :repeatId="repeatId"
                :openProduct="bookingWithOpenProduct"
                @repeatDatesForPayment="repeatDatesForPaymentChange"
              ></repeat-bookingPayment>

              <div class="bg-white bordered rounded-lg pb-8 mt-8">
                <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-4">
                  <div class="title-text">Order Note</div>
                </div>
                <v-row>
                  <v-col md="12">
                    <v-textarea
                      v-model="bookingForm.order_notes"
                      rows="3"
                      outlined
                      background-color="#fff"
                      dense
                      clearable
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="perCapacity != 1 && bookingForm.status_id == 5 && !fullDay && checkWritePermission($modules.schedule.management.slug)"
                @click="reschedule()"
                class="ma-2 yellow-color"
                text
                :disabled="repeatId && !isEnableRepeateBookingReschedule"
                >Reschedule
              </v-btn>
              <v-btn
                v-if="id == 0"
                @click="addMaintenance()"
                class="ma-2 text_capitalize"
                color="red"
                outlined
                >
                <MaintenanceIcon class="mr-1" />
                Maintenance
              </v-btn>
              <v-btn
                v-if="!repeatId && bookingForm.status_id == 5"
                @click="confirmCancel"
                class="ma-2 white--text red-color"
                text
                >Cancel Booking
              </v-btn>

              <v-btn
                v-if="repeatId && bookingForm.status_id == 5"
                @click="cancelRepeatBooking"
                class="ma-2 white--text red-color"
                text
                >Cancel Booking
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                v-show="
                  !repeatId &&
                    bookingForm.status_id == 4 &&
                    (current_group_customer.check_in_time == null ||
                      current_group_customer.check_out_time == null)
                "
                :color="
                  current_group_customer.check_in_and_out == 'OUT'
                    ? 'error'
                    : 'success'
                "
                @click="checkInAndOut(current_group_customer.group_customer_id)"
                class="ma-1 white--text teal-color"
              >
                {{
                  current_group_customer.check_in_and_out
                    ? "Check " +
                      (current_group_customer.check_in_and_out == "OUT"
                        ? "Out"
                        : "In")
                    : "Check In/Out"
                }}</v-btn
              >
              <v-btn @click="close()" class="ma-2" text
                >Close
              </v-btn>

              <v-btn
                v-if="
                  bookingForm.is_booking_approved == 0 &&
                    (bookingForm.status_id == 5 || bookingForm.status_id == 7) && checkWritePermission($modules.facility.approval.slug)
                "
                @click="approveBooking"
                class="ma-2 white--text green-color"
                text
              >
                Approve
              </v-btn>

              <v-btn
                v-if="
                  bookingForm.is_booking_approved == 1 &&
                    (bookingForm.status_id == 5 || bookingForm.status_id == 7)
                "
                @click="showInvoice"
                class="ma-2 white--text teal-color"
                text
              >
                Invoice
              </v-btn>

              <v-btn
                v-if="repeatId && !bookingWithOpenProduct"
                @click="showRepeatPaymentsConfirmation"
                class="ma-2 white--text teal-color"
                text
                :disabled="repeatId && !isEnableRepeatBookingPayment"
              >
                Pay
              </v-btn>

              <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="confirmAddOrEditBooking"
                v-if="checkWritePermission($modules.schedule.management.slug)"
              >
                {{ order_id ? "Update Reservation" : "Confirm Reservation" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-form>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    >
      <template #content v-if="this.id && this.confirmModel.type === 'update' && typeof confirmModel.data.notify_customers !== 'undefined'">
        <v-row>
          <v-col cols="6">
            <v-switch
                class="mx-0 my-0"
                v-model="notify_customers"
                label="Notify Customers"
                dense
                hide-details="auto"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </confirm-model>
    <product-combination
      :productCombinations="productCombinations"
      :showCombinations="showCombinationDialog"
      @close="showCombinationDialog = false"
      @changeCombination="changeRentalProducts"
      :selectedCombinationPrice="selectedCombinationPrice"
    >
    </product-combination>

    <repeat-order-payments
      v-if="enableRepeatOrderPay"
      v-bind="bookingForm"
      :show="enableRepeatOrderPay"
      :repeatBookingIds="repeatBookingIds"
      :wallet="wallet"
      :orderId="order_id"
      :promotion_code="bookingForm.promotion_code"
      @close="enableRepeatOrderPay = false"
      :date="date"
      :payerCustomerList="payerCustomerList"
      @payed="$emit('booked'), (enableRepeatOrderPay = false)"
    ></repeat-order-payments>
    <facility-maintenance
      :facilityId="maintenanceId"
      :venueServiceId="venue_service_id"
      @save="saveMaintenance"
      @close="closeMaintenance"
      :timeIncrement="increment"
      :startTime="start_time"
      :endTime="end_time"
      :startDate="date"
      :endDate="date"
    ></facility-maintenance>
  </div>
</template>
<script>
import RepeatBooking from "@/components/Schedule/Facility/RepeatBooking";
import RepeatBookingPayment from "@/components/Schedule/Facility/RepeatBookingPayment";
import ProductCombination from "@/components/Schedule/Facility/ProductCombination";
import moment, { now } from "moment";
import bookingFields from "@/mixins/bookingFieldValidation";
import RepeatOrderPayments from "@/components/Order/RepeatOrderPayments.vue";
import FacilityMaintenance from "@/components/Facility/FacilityMaintenance";
import CustomerBookingForm from "./CustomerBookingForm.vue";
import ProductSelection from "@/components/Schedule/Facility/ProductSelection";
import MaintenanceIcon from "@/assets/images/schedule/maintenance.svg";
export default {
  props: {
    showBookingForm: { type: Boolean },
    start_time: { type: String },
    end_time: { type: String },
    date: { type: String },
    facility_id: { type: Number },
    order_id: { type: Number },
    id: { type: Number, default: 0 },
    facility_name: { type: String },
    venue_service_id: { type: Number },
    service: { type: String },
    perCapacity: { type: Number },
    increment: { type: Number },
    minBookingTime: { type: Number },
  },
  components: {
    RepeatBooking,
    ProductCombination,
    RepeatBookingPayment,
    RepeatOrderPayments,
    FacilityMaintenance,
    CustomerBookingForm,
    ProductSelection,
    MaintenanceIcon,
  },
  mixins: [bookingFields],
  data() {
    return {
      enableOvernightBooking:false,
      customerFormRefresh: now(),
      refreshComponent: 0,
      currentRepeatDates: null,
      bookingForm: {
        attendance: false,
        attendance_count: 1,
        opt_marketing: false,
        total_price: 0,
        price: 0,
        discount: null,
        promotion_code: null,
      },
      bookingFormTotal: 0,
      bookingFormAdded: 1,
      bookingCustomersAddons: [],
      selectedProduct: {},
      productCategoryId: null,
      wallet: {
        products: null,
        cash: null,
      },
      // webcamDialog: false,
      endTimes: [],
      categories: [],
      companies: [],
      valid: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      repeatId: null,
      productCombinations: [],
      showCombinationDialog: false,
      selectedCombinationPrice: 0,
      isEnableRepeatBookingPayment: false,
      isEnableRepeateBookingReschedule: false,
      repeatDatesForPayments: null,
      enableRepeatOrderPay: false,
      payerCustomerList: [],
      repeatBookingIds: [],
      currentOrderProducts: [],
      attendanceData: {},
      capacity: 0,
      attendies: 0,
      bookedCapacity: 1,
      totalParticipants: 0,
      attendanceSwitch: false,
      uploadedAttachments: [],
      attendanceCustomers: [
        {
          opt_marketing: false,
          productCategoryId: null,
          quantity: 1,
          price: 0,
          total_price: 0,
          selectedProduct: { quantity: 1, price: 0 },
          products: new Array(),
          discount: null,
          promotion_code: null,
        },
      ],
      attendanceCustomersTotal: [0],
      attendanceCustomerAdded: [1],
      attendanceCustomersAddons: [[]],
      editFlag: false,
      pastTime: false,
      maintenanceId: null,
      categoriesList: [
        { name: "All", id: null },
        { name: "Open Product", id: -1 },
      ],
      disablePromotion: false,
      bookingWithOpenProduct: false,
      isEmiratesIdCheck: false,
      deletedProducts: [],
      addAttandanceBtn: true,
      current_group_customer: {},
      fullDay: false,
      facilities:[],
      facilityStartTime:null,
      facilityEndTime:null,
      notify_customers:false
    };
  },
  watch: {
    showBookingForm(val) {
      this.customerFormRefresh = now() + 11;
      this.disablePromotion = false;
      this.repeatId = null;
      this.isEnableRepeateBookingReschedule = null;
      this.refreshComponent = now();
      this.attendanceCustomers = [
        {
          opt_marketing: false,
          productCategoryId: null,
          quantity: 1,
          price: 0,
          total_price: 0,
          selectedProduct: { quantity: 1, price: 0 },
          products: new Array(),
          discount: null,
          promotion_code: null,
        },
      ];

      this.attendanceData = [];
      this.uploadedAttachments = [];
      this.currentOrderProducts = [];
      this.currentRepeatDates = null;
      this.attendanceSwitch = false;
      this.bookedCapacity = 1;
      this.addAttandanceBtn = true;
      this.productCategoryId = null;
      this.selectedProduct = { rental: false };
      this.attendies = 0;
      this.deletedProducts = [];
      if (val === true) {
        this.checkEnableOvernightBooking();
        this.bookingForm = {
          start_time: this.start_time,
          end_time: this.end_time,
          date: this.date,
          facility_id: this.facility_id,
          venue_service_id: this.venue_service_id,
          attendance_count: 1,
          price: 0,
          total_price: 0,
          discount: null,
          promotion_code: null,
        };
        if (this.id > 0) {
          this.bookingForm.id = this.id;
          this.bookingForm.order_id = this.order_id;
        }
        this.$store.dispatch("loadPromotions", {
          date: this.date,
          venue_service_id: this.venue_service_id,
          product_type: "Facility",
        });
        if(this.venue_service_id){
          this.getFacilities();
        }
        // this.getCustomerWallets();
        this.setFieldConfigurations();
        this.getFacilityUtils();
      }
    },
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store.dispatch("loadPaymentMethods", "normal");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then( () => {
        if(this.venue_service_id){
         this.getFacilities();
        }
      })
    }
    this.checkPermission = this.checkExportPermission(
      this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Facility");
      this.$forceUpdate();
    }
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
        this.venue_service_id
      );
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
    startTimes(){
      let times = [];
      let currentTime = null;
      const startTime = this.facilityStartTime?this.facilityStartTime:this.start_time;
      const endTime = this.facilityEndTime?this.facilityEndTime:this.end_time;
      currentTime = moment(this.date+ " " +startTime,"YYYY-MM-DD HH:mm:ss").startOf('hour');

      let closingTime = moment(this.date+ " " +endTime,"YYYY-MM-DD HH:mm:ss");
      while (currentTime.isBefore(closingTime)) {
        times.push({
          formatted: currentTime.format('hh:mm a'),
          time: currentTime.format('HH:mm:ss'),
        });
        currentTime.add(this.increment, 'minutes');
      }
      // times.pop();
      return times;
    }
  },
  methods: {
    async getFacilityTimings() {
      let timings = { start_time:null, end_time:null};
      this.showLoader("Loading");
      await this.$http
          .get(`venues/facilities/get-min-time?facility_id=${this.facility_id}&date=${this.date}&same_day=1`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              timings.start_time = response.data.start_time;
              timings.end_time = response.data.end_time;
              this.hideLoader();

            }
          })
          .catch((error) => {
            this.hideLoader();
            console.error(error);
            // this.errorChecker(error);
          });
      return timings;
    },
    async addOverNightBookingTimes(data){
      let times = await this.getFacilityTimings();
      let minStartTime = null;

      if(times && times.end_time) {
        minStartTime = times.start_time.start_time
      }else{
        minStartTime = data.facility_rentals.reduce((min, obj) => {
          const start_time = moment(obj.start_time, 'HH:mm:ss');
          return start_time.isAfter(moment(min, 'HH:mm:ss')) ? start_time.format('HH:mm:ss') : min;
        }, moment('00:00:00', 'HH:mm:ss'));
      }

      let newTimesots = this.generateTimeSlots(minStartTime,this.increment,this.minBookingTime,this.bookingForm.start_time);
      this.endTimes.push(...newTimesots);
    },
    generateTimeSlots(minStartTime, increment, minBookingTime, bookingStartTime) {
      const timeSlots = [];
      let currentTime = moment(minStartTime, 'HH:mm:ss');
      const bookingEndTime = moment(bookingStartTime, 'HH:mm:ss').subtract(minBookingTime - increment, 'minutes');
      let key = 0;
      while (currentTime.isBefore(bookingEndTime)) {
        if (key !== 0) {
          timeSlots.push({
            formatted: currentTime.format('hh:mm a') + '<span style="color: red">+1</span>',
            time: currentTime.format('HH:mm:ss'),
          });
        } else {
          key++;
        }
          currentTime.add(increment, 'minutes');
      }
      return timeSlots;
    },
    checkEnableOvernightBooking(){
      if(this.venueServiceConfiguration.enable_over_night_booking){
        this.enableOvernightBooking = true;
      }
    },
    checkInAndOut(id) {
      this.showLoader("Loading");
      this.$http
        .get(`venues/facilities/bookings/check-in-and-out/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.getBookingDetails();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getCustomerWallets() {
      if (!this.order_id) {
        return;
      }
      this.$http
        .get(
          `venues/customers/products-wallet/get-customer-all/${this.order_id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data;
            this.wallet.cash = data.cash;
            this.wallet.products = data.products;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    refreshCustomersMain() {
      if (this.bookingFormTotal) {
        this.bookingCustomersAddons.splice(this.bookingFormTotal - 1);
      } else {
        this.bookingCustomersAddons = [];
      }
    },
    refreshCustomersAttendance(index) {
      this.attendanceCustomersAddons[index].splice(
        this.attendanceCustomersTotal[index] - 1
      );
    },
    removeAddonCustomer(index, key) {
      if (key == null) {
        this.bookingCustomersAddons.splice(index, 1);
        this.bookingFormAdded--;
      } else {
        this.attendanceCustomersAddons[key].splice(index, 1);
        this.attendanceCustomerAdded[key]--;
      }
    },

    updateBookingFormQuantity() {
      let old = this.bookingFormTotal;
      let max = this.bookingForm.products.reduce(
        (acc, num) =>
          acc +
          (num.rental == true
            ? parseInt(num.quantity) * parseInt(num.participant_count)
            : 0),
        0
      );
      // console.log("max");
      // console.log(max);
      if (old > max && max < this.bookingFormAdded) {
        this.bookingFormAdded = max + 1;
      }

      this.bookingFormTotal = max;
    },

    updateAttendanceFormQuantity(index) {
      let old = this.attendanceCustomersTotal[index] ?? 0;
      let max = 0;
      if (this.attendanceCustomers[index].products.length) {
        max = this.attendanceCustomers[index].products.reduce(
          (acc, num) =>
            acc +
            (num.rental == true
              ? parseInt(num.quantity) * parseInt(num.participant_count)
              : 0),
          0
        );
      }
      if (old > max && max < this.attendanceCustomerAdded[index]) {
        this.attendanceCustomerAdded[index] = max + 1;
      }
      this.attendanceCustomersTotal[index] = max;
    },

    addBookingFormCustomer() {
      this.bookingCustomersAddons.push({
        customer_id: null,
        attendance: false,
        attendance_count: 1,
        opt_marketing: false,
        total_price: 0,
        price: 0,
        discount: null,
        promotion_code: null,
      });
      this.bookingFormAdded++;
      if (this.venueServiceConfiguration.auto_fill_customers) {
        this.autoFillCustomer();
      }
    },
    autoFillCustomer() {
      if (!this.bookingForm.name) {
        return;
      }
      let index =
        this.bookingCustomersAddons.length > 0
          ? this.bookingCustomersAddons.length - 1
          : null;
      if (index >= 0) {
        let data = {
          customer_id: null,
          name: this.bookingForm.name + " Guest #" + (index + 1),
          mobile: this.bookingForm.mobile,
          email: this.bookingForm.email,
        };
        this.setCustomerDataAddon(data, index);
      }
    },
    addAttendanceCustomer(index) {
      this.attendanceCustomersAddons[index].push({
        opt_marketing: false,
        productCategoryId: null,
        quantity: 1,
        price: 0,
        total_price: 0,
        selectedProduct: { quantity: 1, price: 0 },
        products: new Array(),
        discount: null,
        promotion_code: null,
      });
      this.attendanceCustomerAdded[index]++;
      if (typeof this.attendanceCustomersTotal[index] == "undefined") {
        this.attendanceCustomersTotal[index] = 0;
      }
    },
    grandTotal() {
      var totalPrice = 0;
      if (this.bookingForm.products) {
        totalPrice = this.bookingForm.products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
      }
      if (this.attendanceCustomers.length) {
        this.attendanceCustomers.map((item) => {
          if (item.products) {
            if (item.products.length) {
              totalPrice += item.products.reduce(
                (a, b) => a + parseFloat(b.total_price),
                0
              );
            }
          }
        });
      }
      return totalPrice;
    },
    reschedule() {
      if (this.isEnableRepeateBookingReschedule) {
        // let booking = this.repeatDatesForPayments.find((item) => item.isPaid);
        // this.$emit("reschedule", booking.booking_id);
        const bookings = this.repeatDatesForPayments.filter(item => item.isPaid);
         console.log("bookings");
        console.log(bookings);
        if(bookings && bookings.length > 1){
          const bookingIds = bookings.map(item => item.booking_id);
          this.$emit("rescheduleMulti", {booking_ids: bookingIds});
        }else{
          this.$emit("reschedule", bookings[0].booking_id);
        }
      } else {
        this.$emit("reschedule", this.bookingForm.id);
      }
    },
    addMaintenance() {
      this.maintenanceId = this.facility_id;
    },
    closeMaintenance() {
      this.maintenanceId = null;
    },
    saveMaintenance() {
      this.$emit("refresh");
      this.close();
    },
    rescheduleFromRepeatBooking(id) {
      this.$emit("reschedule", id);
    },
    close() {
      this.bookingFormTotal = 0;
      this.bookingFormAdded = 1;
      this.bookingCustomersAddons = [];
      this.attendanceCustomersTotal = [0];
      this.attendanceCustomerAdded = [1];
      this.attendanceCustomersAddons = [[]];
      this.$emit("close");
    },
    setCustomerData(data, index = null) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (index === null) {
        if (data.mobile && data.first_name && data.customer_id) {
          this.isEmiratesIdCheck = false;
          if (!this.bookingForm.id) {
            this.bookingForm.promotion_code = null;
          }

          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name
          );
        } else {
          this.clearCardAndBenefits();
        }
        if (!data.customer_id && this.id > 0) {
          this.$set(this.bookingForm, "customer_id", null);
        }

        if (!data.name && data.first_name) {
          this.$set(this.bookingForm, "name", data.first_name);
        }
        if (
          this.bookingForm.customer_id &&
          !data.customer_id &&
          this.bookingForm.name != data.name &&
          this.bookingForm.mobile != data.mobile
        ) {
          this.$set(this.bookingForm, "mobile", null);
          this.bookingForm.search = null;
          this.bookingForm.nameSearch = null;
          this.$set(this.bookingForm, "email", null);
          this.$set(this.bookingForm, "gender", null);
          this.$set(this.bookingForm, "name", null);
          this.$set(this.bookingForm, "customer_id", null);
          this.$set(this.bookingForm, "first_name", null);
          this.$set(this.bookingForm, "image_path", null);
          this.$set(this.bookingForm, "dob", null);
          this.$set(this.bookingForm, "age_group", null);
          this.$set(this.bookingForm, "country_id", null);
          this.$set(this.bookingForm, "last_name", null);
          this.$set(this.bookingForm, "opt_marketing", false);
          this.$set(this.bookingForm, "id_proof_type_id", null);
          this.$set(this.bookingForm, "id_proof_number", null);
          this.$set(this.bookingForm, "id_proof_path", null);
          this.$set(this.bookingForm, "customer_tag", null);
          this.$forceUpdate();
        }
        if (data.mobile) this.$set(this.bookingForm, "mobile", data.mobile);
        if (data.email) this.$set(this.bookingForm, "email", data.email);
        if (data.country_id) {
          this.$set(this.bookingForm, "country_id", data.country_id);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "country_id", null);
          }
        }
        if (data.gender) {
          this.$set(this.bookingForm, "gender", data.gender);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "gender", null);
          }
        }
        if (data.dob) {
          this.$set(this.bookingForm, "dob", data.dob);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "dob", null);
          }
        }
        if (data.age_group) {
          this.$set(this.bookingForm, "age_group", data.age_group);
        }
        else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "age_group", null);
          }
        }
        if (data.name) {
          data.name = data.name.replace(/\s\s+/g, " ");
          data.name = data.name.trim();
          this.$set(this.bookingForm, "name", data.name);
        }
        if (data.last_name) {
          data.last_name = data.last_name.replace(/\s\s+/g, " ");
          data.last_name = data.last_name.trim();
          this.$set(this.bookingForm, "last_name", data.last_name);
        } else {
          this.$set(this.bookingForm, "last_name", null);
        }
        if (data.first_name) {
          data.first_name = data.first_name.replace(/\s\s+/g, " ");
          data.first_name = data.first_name.trim();
          this.$set(this.bookingForm, "first_name", data.first_name);
        }
        if (data.customer_id)
          this.$set(this.bookingForm, "customer_id", data.customer_id);
        if (data.image_path) {
          this.$set(this.bookingForm, "image_path", data.image_path);
        } else {
          this.$set(this.bookingForm, "image_path", null);
        }
        if (data.id_proof_type_id) {
          this.$set(
            this.bookingForm,
            "id_proof_type_id",
            data.id_proof_type_id
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof_type_id", null);
          }
        }
        if (data.id_proof_number) {
          this.$set(this.bookingForm, "id_proof_number", data.id_proof_number);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof_number", null);
          }
        }
        if (data.id_proof_path) {
          this.$set(this.bookingForm, "id_proof_path", data.id_proof_path);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof_path", null);
          }
        }
        if (data.customer_tag) {
          this.$set(this.bookingForm, "customer_tag", data.customer_tag);
        } else {
          this.$set(this.bookingForm, "customer_tag", null);
        }

        if (data.id_proof) {
          this.$set(this.bookingForm, "id_proof", data.id_proof);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof", null);
          }
        }
        if (data.opt_marketing) {
          if (data.opt_marketing == 1) {
            this.$set(this.bookingForm, "opt_marketing", true);
          } else {
            this.$set(this.bookingForm, "opt_marketing", false);
          }
        }
        if (data.customer_documents) {
          this.bookingForm.customer_documents = data.customer_documents;
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
          ) {
            this.$set(
              this.bookingForm,
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
          ) {
            this.$set(
              this.bookingForm,
              "id_proof_number",
              data.customer_documents[0].id_proof_number
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
          ) {
            this.$set(
              this.bookingForm,
              "id_proof_path",
              data.customer_documents[0].id_proof_path
            );
          }
        } else {
          if (data.customer_id) {
            this.bookingForm.customer_documents = [];
          }
        }
      } else {
        if (data.mobile && data.first_name && data.customer_id) {
          this.isEmiratesIdCheck = false;
          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name,
            index
          );
        } else {
          this.clearCardAndBenefits(index);
        }

        if (!data.customer_id) {
          this.$set(this.attendanceCustomers[index], "customer_id", null);
        }

        if (!data.name && data.first_name) {
          this.$set(this.attendanceCustomers[index], "name", data.first_name);
        }
        if (
          this.attendanceCustomers[index].customer_id &&
          !data.customer_id &&
          this.attendanceCustomers[index].name != data.name &&
          this.attendanceCustomers[index].mobile != data.mobile
        ) {
          this.$set(this.attendanceCustomers[index], "mobile", null);
          this.attendanceCustomers[index].search = null;
          this.attendanceCustomers[index].nameSearch = null;
          this.$set(this.attendanceCustomers[index], "email", null);
          this.$set(this.attendanceCustomers[index], "gender", null);
          this.$set(this.attendanceCustomers[index], "name", null);
          this.$set(this.attendanceCustomers[index], "customer_id", null);
          this.$set(this.attendanceCustomers[index], "first_name", null);
          this.$set(this.attendanceCustomers[index], "image_path", null);
          this.$set(this.attendanceCustomers[index], "dob", null);
          this.$set(this.attendanceCustomers[index], "age_group", null);
          this.$set(this.attendanceCustomers[index], "country_id", null);
          this.$set(this.attendanceCustomers[index], "last_name", null);
          this.$set(this.attendanceCustomers[index], "opt_marketing", false);
          this.$set(this.attendanceCustomers[index], "id_proof_type_id", null);
          this.$set(this.attendanceCustomers[index], "id_proof_number", null);
          this.$set(this.attendanceCustomers[index], "id_proof_path", null);
          this.$forceUpdate();
        }
        if (data.mobile)
          this.$set(this.attendanceCustomers[index], "mobile", data.mobile);
        if (data.email)
          this.$set(this.attendanceCustomers[index], "email", data.email);
        if (data.country_id) {
          this.$set(
            this.attendanceCustomers[index],
            "country_id",
            data.country_id
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "country_id", null);
          }
        }
        if (data.gender) {
          this.$set(this.attendanceCustomers[index], "gender", data.gender);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "gender", null);
          }
        }
        if (data.dob) {
          this.$set(this.attendanceCustomers[index], "dob", data.dob);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "dob", null);
          }
        }
        if (data.age_group) {
          this.$set(this.attendanceCustomers[index], "age_group", data.age_group);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "age_group", null);
          }
        }
        if (data.name) {
          data.name = data.name.replace(/\s\s+/g, " ");
          data.name = data.name.trim();
          this.$set(this.attendanceCustomers[index], "name", data.name);
        }
        if (data.last_name) {
          data.last_name = data.last_name.replace(/\s\s+/g, " ");
          data.last_name = data.last_name.trim();
          this.$set(
            this.attendanceCustomers[index],
            "last_name",
            data.last_name
          );
        } else {
          this.$set(this.attendanceCustomers[index], "last_name", null);
        }
        if (data.first_name) {
          data.first_name = data.first_name.replace(/\s\s+/g, " ");
          data.first_name = data.first_name.trim();
          this.$set(
            this.attendanceCustomers[index],
            "first_name",
            data.first_name
          );
        }
        if (data.customer_id)
          this.$set(
            this.attendanceCustomers[index],
            "customer_id",
            data.customer_id
          );
        if (data.image_path) {
          this.$set(
            this.attendanceCustomers[index],
            "image_path",
            data.image_path
          );
        } else {
          this.$set(this.attendanceCustomers[index], "image_path", null);
        }
        if (data.id_proof_type_id) {
          this.$set(
            this.attendanceCustomers[index],
            "id_proof_type_id",
            data.id_proof_type_id
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_type_id",
              null
            );
          }
        }
        if (data.id_proof_number) {
          this.$set(
            this.attendanceCustomers[index],
            "id_proof_number",
            data.id_proof_number
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "id_proof_number", null);
          }
        }
        if (data.id_proof_path) {
          this.$set(
            this.attendanceCustomers[index],
            "id_proof_path",
            data.id_proof_path
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "id_proof_path", null);
          }
        }

        if (data.id_proof) {
          this.$set(this.attendanceCustomers[index], "id_proof", data.id_proof);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "id_proof", null);
          }
        }
        if (data.opt_marketing) {
          if (data.opt_marketing == 1) {
            this.$set(this.attendanceCustomers[index], "opt_marketing", true);
          } else {
            this.$set(this.attendanceCustomers[index], "opt_marketing", false);
          }
        }
        if (data.customer_documents) {
          this.attendanceCustomers[index].customer_documents =
            data.customer_documents;
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
          ) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
          ) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_number",
              data.customer_documents[0].id_proof_number
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
          ) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_path",
              data.customer_documents[0].id_proof_path
            );
          }
        } else {
          this.attendanceCustomers[index].customer_documents = [];
        }
        if (data.customer_tag) {
          this.$set(
            this.attendanceCustomers[index],
            "customer_tag",
            data.customer_tag
          );
        } else {
          this.$set(this.attendanceCustomers[index], "customer_tag", null);
        }
      }
      this.$refs.form.resetValidation();
      this.$forceUpdate();
    },
    setMemberData(data, index = null) {
      this.setCustomerData(data, index);
      if (index === null) {
        this.$set(this.bookingForm, "card_number", data.card_number);
        this.$set(this.bookingForm, "membership_id", data.membership_id);
      } else {
        this.$set(
          this.attendanceCustomers[index],
          "card_number",
          data.card_number
        );
        this.$set(
          this.attendanceCustomers[index],
          "membership_id",
          data.membership_id
        );
      }

      //this.verifyBenefit("membership", index);
    },
    confirmAddOrEditBooking(){
      if(this.id && this.perCapacity == 1){
        this.confirmModel = {
          id: this.order_id,
          title: `Do you want update this booking?`,
          description: `This will update the reservation. By clicking <b>Yes</b> you can confirm update operation`,
          type: "update",
          data:{
            id:this.id,
            notify_customers:false
          }
        }
      }else{
        this.addOrEditBooking()
      }
    },
    addOrEditBooking() {
      this.confirmModel.id = null;
      if (this.perCapacity == 1) {
        let count =
          this.bookingFormTotal +
          this.attendanceCustomersTotal.reduce(
            (partialSum, a) => partialSum + a,
            0
          );
        if (this.capacity < count) {
          this.showError("Trying to book more than available capacity");
          return;
        }
        // console.log("this.bookingFormTotal");
        // console.log(this.bookingFormTotal);
        // console.log("this.attendanceCustomersTotal");
        // console.log(this.attendanceCustomersTotal);
        // console.log("count");
        // console.log(count);
        // console.log("this.capacity");
        // console.log(this.capacity);
        // return;
      }
      this.attendanceData = this.attendanceCustomers;
      if (this.attendanceData.length > 0) {
        let mobiles = this.attendanceData.map(function(item) {
          return item.mobile;
        });
        if (this.bookingForm.mobile) {
          mobiles.push(this.bookingForm.mobile);
        }
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields to continue");
        return;
      }

      if (this.repeatId && this.bookingForm.id > 0) {
        this.bookingForm.products = this.currentOrderProducts;
      } else {
        var repeatBookingAvailableDates = [];
        if (this.bookingForm.repeat) {
          this.bookingForm.repeats.forEach((repeats) => {
            repeatBookingAvailableDates = repeatBookingAvailableDates.concat(repeats.available_dates);
          });
          repeatBookingAvailableDates = repeatBookingAvailableDates.sort(
            (a, b) => new Date(a) - new Date(b)
          );
        }
      }

      if (!this.bookingForm.repeats && !this.id) {
        this.bookingForm.repeat = false;
        delete this.bookingForm.repeats;
      }
      if (this.bookingForm.products.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.bookingForm) {
        if (
          this.bookingForm[key] != null &&
          !Array.isArray(this.bookingForm[key]) &&
          typeof this.bookingForm[key] != "object"
        ) {
          formData.append(`${key}`, this.bookingForm[key]);
        } else if (Array.isArray(this.bookingForm[key])) {
          this.bookingForm[key].forEach((data, index) => {
            if (!Array.isArray(data)) {
              for (let innerKey in data) {
                if (Array.isArray(data[innerKey])) {
                  if (
                    innerKey != "start_times" &&
                    innerKey != "end_times" &&
                    innerKey != "times"
                  ) {
                    data[innerKey].forEach((deepData, deepIndex) => {
                      formData.append(
                        `${key}[${index}][${innerKey}][${deepIndex}]`,
                        typeof deepData == "object"
                          ? JSON.stringify(deepData)
                          : deepData
                      );
                    });
                  }
                } else {
                  formData.append(
                    `${key}[${index}][${innerKey}]`,
                    data[innerKey]
                  );
                }
              }
            } else if (Array.isArray(data)) {
              data.forEach((innerData, innerIndex) => {
                formData.append(`${key}[${index}][${innerIndex}]`, innerData);
              });
            } else {
              formData.append(
                `${key}[${index}]`,
                typeof data == "object" ? JSON.stringify(data) : data
              );
            }
          });
        }
      }
      if (this.perCapacity === 1 && this.bookingCustomersAddons.length > 0) {
        for (let main in this.bookingCustomersAddons) {
          // console.log("bookingCustomerAddons");
          // console.log(this.bookingCustomersAddons);
          for (let keyInner in this.bookingCustomersAddons[main]) {
            if (
              this.bookingCustomersAddons[main][keyInner] != null &&
              !Array.isArray(this.bookingCustomersAddons[main][keyInner]) &&
              typeof this.bookingCustomersAddons[main][keyInner] != "object"
            ) {
              formData.append(
                `addOnCustomers[${main}][${keyInner}]`,
                this.bookingCustomersAddons[main][keyInner]
              );
            } else if (
              Array.isArray(this.bookingCustomersAddons[main][keyInner])
            ) {
              this.bookingCustomersAddons[main][keyInner].forEach(
                (data, index) => {
                  // console.log(data);
                  if (!Array.isArray(data)) {
                    for (let innerKey in data) {
                      if (Array.isArray(data[innerKey])) {
                        if (
                          innerKey != "start_times" &&
                          innerKey != "end_times" &&
                          innerKey != "times"
                        ) {
                          data[innerKey].forEach((deepData, deepIndex) => {
                            formData.append(
                              `addOnCustomers[${main}][${keyInner}][${index}][${innerKey}][${deepIndex}]`,
                              typeof deepData == "object"
                                ? JSON.stringify(deepData)
                                : deepData
                            );
                          });
                        }
                      } else {
                        formData.append(
                          `addOnCustomers[${main}][${keyInner}][${index}][${innerKey}]`,
                          data[innerKey]
                        );
                      }
                    }
                  } else if (Array.isArray(data)) {
                    data.forEach((innerData, innerIndex) => {
                      formData.append(
                        `addOnCustomers[${main}][${keyInner}][${index}][${innerIndex}]`,
                        innerData
                      );
                    });
                  } else {
                    formData.append(
                      `addOnCustomers[${main}][${keyInner}][${index}]`,
                      typeof data == "object" ? JSON.stringify(data) : data
                    );
                  }
                }
              );
            }
          }
        }
      }

      if (
        this.attendanceData.length > 0 &&
        !this.bookingForm.id &&
        this.perCapacity === 1
      ) {
        for (let key in this.attendanceData) {
          if (
            typeof this.attendanceData[key] === "object" &&
            this.attendanceData[key].mobile
          ) {
            if (this.attendanceData[key].products) {
              if (this.attendanceData[key].products.length == 0) {
                this.hideLoader();
                this.showError("Please add atleast one product");
                return;
              }
            } else {
              this.showError("Please add atleast one product");
              return;
            }
            formData.append(
              `attendance_customer[${key}][venue_service_id]`,
              this.venue_service_id
            );
            formData.append(
              `attendance_customer[${key}][first_name]`,
              this.attendanceData[key].first_name
            );
            formData.append(
              `attendance_customer[${key}][last_name]`,
              this.attendanceData[key].last_name
            );
            formData.append(
              `attendance_customer[${key}][mobile]`,
              this.attendanceData[key].mobile
            );
            formData.append(
              `attendance_customer[${key}][email]`,
              this.attendanceData[key].email
            );
            if (
              this.attendanceData[key].customer_id &&
              this.attendanceData[key].customer_id != null
            ) {
              formData.append(
                `attendance_customer[${key}][customer_id]`,
                this.attendanceData[key].customer_id
              );
            }
            if (this.attendanceData[key].name) {
              formData.append(
                `attendance_customer[${key}][name]`,
                this.attendanceData[key].name
              );
            } else {
              formData.append(
                `attendance_customer[${key}][name]`,
                this.attendanceData[key].namesearch.first_name
              );
            }
            if (this.attendanceData[key].promotion_code) {
              formData.append(
                `attendance_customer[${key}][promotion_code]`,
                this.attendanceData[key].promotion_code
              );
            }
            if (this.attendanceData[key].card_number) {
              formData.append(
                `attendance_customer[${key}][card_number]`,
                this.attendanceData[key].card_number
              );
            }
            if (this.attendanceData[key].customer_type) {
              formData.append(
                `attendance_customer[${key}][customer_type]`,
                this.attendanceData[key].customer_type
              );
            }
            if (this.attendanceData[key].opt_marketing) {
              formData.append(
                `attendance_customer[${key}][opt_marketing]`,
                this.attendanceData[key].opt_marketing
              );
            }
            if (this.attendanceData[key].discount) {
              for (let dKey in this.attendanceData[key].discount) {
                formData.append(
                  `attendance_customer[${key}][discount][${dKey}]`,
                  this.attendanceData[key].discount[dKey]
                );
              }
            }
            /** optional field data */
            if (this.attendanceData[key].gender) {
              formData.append(
                `attendance_customer[${key}][gender]`,
                this.attendanceData[key].gender
              );
            }
            if (this.attendanceData[key].dob) {
              formData.append(
                `attendance_customer[${key}][dob]`,
                this.attendanceData[key].dob
              );
            }
            if (this.attendanceData[key].age_group) {
              formData.append(
                `attendance_customer[${key}][age_group]`,
                this.attendanceData[key].age_group
              );
            }
            if (this.attendanceData[key].country_id) {
              formData.append(
                `attendance_customer[${key}][country_id]`,
                this.attendanceData[key].country_id
              );
            }
            if (this.attendanceData[key].id_proof_type_id) {
              formData.append(
                `attendance_customer[${key}][id_proof_type_id]`,
                this.attendanceData[key].id_proof_type_id
              );
            }
            if (this.attendanceData[key].id_proof_number) {
              formData.append(
                `attendance_customer[${key}][id_proof_number]`,
                this.attendanceData[key].id_proof_number
              );
            }
            if (this.attendanceData[key].id_proof) {
              formData.append(
                `attendance_customer[${key}][id_proof]`,
                this.attendanceData[key].id_proof
              );
            }
            if (this.attendanceData[key].profile_image) {
              formData.append(
                `attendance_customer[${key}][profile_image]`,
                this.attendanceData[key].profile_image
              );
            }
            if (this.attendanceData[key].image_path) {
              formData.append(
                `attendance_customer[${key}][image_path]`,
                this.attendanceData[key].image_path
              );
            }
            formData.append(
              `attendance_customer[${key}][price]`,
              this.attendanceData[key].price
            );
            formData.append(
              `attendance_customer[${key}][total_price]`,
              this.attendanceData[key].total_price
            );
            // console.log(this.attendanceCustomersAddons[key]);

            if (
              this.attendanceCustomersAddons[key].length > 0 &&
              this.perCapacity === 1
            ) {
              for (let main in this.attendanceCustomersAddons[key]) {
                for (let keyInner in this.attendanceCustomersAddons[key][
                  main
                ]) {
                  if (
                    this.attendanceCustomersAddons[key][main][keyInner] !=
                      null &&
                    !Array.isArray(
                      this.attendanceCustomersAddons[key][main][keyInner]
                    ) &&
                    typeof this.attendanceCustomersAddons[key][main][
                      keyInner
                    ] != "object"
                  ) {
                    formData.append(
                      `attendance_customer[${key}][addOnCustomers][${main}][${keyInner}]`,
                      this.attendanceCustomersAddons[key][main][keyInner]
                    );
                  } else if (
                    Array.isArray(
                      this.attendanceCustomersAddons[key][main][keyInner]
                    )
                  ) {
                    this.attendanceCustomersAddons[key][main][keyInner].forEach(
                      (data, index) => {
                        // console.log(data);
                        if (!Array.isArray(data)) {
                          for (let innerKey in data) {
                            if (Array.isArray(data[innerKey])) {
                              if (
                                innerKey != "start_times" &&
                                innerKey != "end_times" &&
                                innerKey != "times"
                              ) {
                                data[innerKey].forEach(
                                  (deepData, deepIndex) => {
                                    formData.append(
                                      `attendance_customer[${key}][addOnCustomers][${main}][${keyInner}][${index}][${innerKey}][${deepIndex}]`,
                                      typeof deepData == "object"
                                        ? JSON.stringify(deepData)
                                        : deepData
                                    );
                                  }
                                );
                              }
                            } else {
                              formData.append(
                                `attendance_customer[${key}][addOnCustomers][${main}][${keyInner}][${index}][${innerKey}]`,
                                data[innerKey]
                              );
                            }
                          }
                        } else if (Array.isArray(data)) {
                          data.forEach((innerData, innerIndex) => {
                            formData.append(
                              `attendance_customer[${key}][addOnCustomers][${main}][${keyInner}][${index}][${innerIndex}]`,
                              innerData
                            );
                          });
                        } else {
                          formData.append(
                            `attendance_customer[${key}][addOnCustomers][${main}][${keyInner}][${index}]`,
                            typeof data == "object"
                              ? JSON.stringify(data)
                              : data
                          );
                        }
                      }
                    );
                  }
                }
              }
            }

            this.attendanceData[key].products.map((item, index) => {
              for (var iKey in item) {
                if (iKey != "discount") {
                  formData.append(
                    `attendance_customer[${key}][products][${index}][${iKey}]`,
                    item[iKey]
                  );
                } else {
                  for (let dKey in item[iKey]) {
                    formData.append(
                      `attendance_customer[${key}][products][${index}][${iKey}][${dKey}]`,
                      item[iKey][dKey]
                    );
                  }
                }
              }
            });
          }
        }
      }

      if (this.bookingForm.id_proof) {
        formData.append("id_proof", this.bookingForm.id_proof);
      }

      if (this.bookingForm.profile_image) {
        formData.append("profile_image", this.bookingForm.profile_image);
      }
      if(this.id && this.perCapacity === 1){
        formData.append('notify_customers',this.notify_customers);
      }
      if (
        this.bookingForm.repeat &&
        repeatBookingAvailableDates &&
        repeatBookingAvailableDates.length > 0
      ) {
        formData.delete("date");
        formData.delete("start_time");
        formData.delete("end_time");
        formData.append("start_time",this.bookingForm.repeats[this.bookingForm.repeats.length - 1].start_time);
        formData.append("end_time",this.bookingForm.repeats[this.bookingForm.repeats.length - 1].end_time);
        formData.append("date",repeatBookingAvailableDates[repeatBookingAvailableDates.length - 1]);
      }
      if (this.bookingForm.id) {
        formData.set("attendance_count", 1);
      }

      if (this.bookingForm.sales_team_id){
        formData.append("sales_team_id", this.bookingForm.sales_team_id);
      }
      formData.append("deleted_products", JSON.stringify(this.deletedProducts));
      if(this.enableOvernightBooking){
        formData.append("enable_overnight_booking", 1);
      }else{
        formData.append("enable_overnight_booking", 0);
      }
      this.$http
        .post(
          `venues/facilities/bookings${
            this.bookingForm.id > 0 ? "/" + this.bookingForm.id : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.hideLoader();
            if (this.repeatId) {
              this.$emit("booked");
            } else {
              this.$emit("booked", data.order_id);
            }
            this.attendanceCustomersTotal = [0];
            this.attendanceCustomerAdded = [1];
            this.attendanceCustomersAddons = [[]];
            this.bookingFormTotal = 0;
            this.bookingFormAdded = 1;
            this.bookingCustomersAddons = [];
            this.notify_customers = false;
            this.showSuccess("Booking updated successfully");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFacilityUtils() {
      this.showLoader("Loading");
      this.endTimes = [];
      this.$http
        .get(
          `venues/facilities/bookings/utils?facility_id=${this.facility_id}&date=${this.date}&start_time=${this.start_time}&increment=${this.increment}&min_booking_time=${this.minBookingTime}&booking_id=${this.id}&per_capacity=${this.perCapacity}&venue_service_id=${this.venue_service_id}&enable_overnight_booking=${this.enableOvernightBooking}`
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.bookingWithOpenProduct = false;
            this.capacity = 0;
            if (data.is_enable_per_day_capacity && data.capacity && data.total_attendance) {
              const remainingCapacity = data.capacity - data.total_attendance;
              this.capacity = remainingCapacity >= 1 ? remainingCapacity : 0;
            } else {
              this.capacity = data.capacity;
            }

            this.productCombinations = [];
            this.endTimes = data.end_times;
            if(this.enableOvernightBooking){
              this.addOverNightBookingTimes(data);
            }
            this.facilityStartTime = data.opening_time;
            this.facilityEndTime = data.closing_time;
            this.bookingForm.start_time = data.start_time;
            this.bookingForm.end_time = data.end_time;
            let start_time = moment(this.start_time, "HH:mm:ss").format("HH:mm:ss");
            let currentTime = moment(new Date()).format("HH:mm:ss");

            if (moment(start_time, "HH:mm:ss").isBefore(moment(currentTime, "HH:mm:ss"))) {
              this.pastTime = true;
            } else {
              this.pastTime = false;
            }
            this.categories = data.categories;
            this.categoriesList = [
              { name: "All", id: null },
              { name: "Open Product", id: -1 },
              ...this.categories,
            ];
            if (data.facility_rentals.length > 0 && this.id == 0 && this.perCapacity == 0) {
              let rentalProducts = data.default_products;
              this.bookingForm.price = 0;
              this.bookingForm.total_price = 0;
              this.bookingForm.products = [];
              rentalProducts.forEach((rentalProduct) => {
                this.bookingForm.price += rentalProduct.price;
                this.bookingForm.total_price += rentalProduct.price +
                  (rentalProduct.quantity >= 1
                    ? rentalProduct.total_tax_amount ? rentalProduct.total_tax_amount : rentalProduct.tax_amount *
                      parseFloat(rentalProduct.quantity)
                    : rentalProduct.tax_amount);
                this.bookingForm.products.push({
                  product_id: rentalProduct.id,
                  product_type_id: rentalProduct.product_type_id,
                  price: rentalProduct.price,
                  name: rentalProduct.name,
                  tax: rentalProduct.tax_amount,
                  total_tax_amount:rentalProduct.total_tax_amount,
                  seasonal_pricing_id:rentalProduct.seasonal_pricing_id,
                  category_id: rentalProduct.category_id,
                  rental: true,
                  product_price: rentalProduct.product_price_when_overlapping
                    ? rentalProduct.product_price_when_overlapping
                    : rentalProduct.product_price,
                  quantity: rentalProduct.quantity,
                  total_price:
                    rentalProduct.price +
                    (rentalProduct.quantity >= 1
                      ?
                        rentalProduct.total_tax_amount ? rentalProduct.total_tax_amount :
                        rentalProduct.tax_amount *
                        parseFloat(rentalProduct.quantity)
                      : rentalProduct.tax_amount),
                });
              });
            } else {
              if(this.perCapacity == 1){
                this.$http
                  .get(
                    `venues/facilities/bookings/participants?facility_id=${this.facility_id}&start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&date=${this.date}`
                  )
                  .then((participans_response) => {
                    this.hideLoader();
                    if (
                      participans_response.status == 200 &&
                      participans_response.data.status == true
                    ) {
                      const data_participans_response = participans_response.data.data;
                      this.totalParticipants = data_participans_response.reduce( (a, b) => a + parseFloat(b.attendance),0);
                      this.bookedCapacity += this.totalParticipants;
                      if (this.bookedCapacity >= this.capacity) {
                        this.attendanceSwitch = false;
                      } else {
                        this.attendanceSwitch = true;
                      }
                      this.editFlag = false;
                    }
                  });
                this.bookingForm.products = [];
                this.bookingForm.price = 0;
                this.bookingForm.total_price = 0;
              }
            }
            if (this.perCapacity == 1) {
              if (data.facility_rentals[0]) {
                this.categoriesList.push({
                  name: "Tickets",
                  id: data.facility_rentals[0].category_id,
                });
              }
            }
            if (data.facility_rentals.length) {
              if (data.facility_rentals[0]) {
                let rentalProducts = {
                  id: data.facility_rentals[0].category_id,
                  name: this.perCapacity == 1 ? "Tickets" : "Rentals",
                  products: [
                    ...data.facility_rentals.map((item) => {
                      item.id = item.product_id;
                      item.rental = true;
                      return item;
                    }),
                  ],
                };
                this.categories.push(rentalProducts);
              }
            }
            this.bookingForm.opt_marketing = false;
            this.$refs.form.resetValidation();
            if (this.id > 0) {
              this.getBookingDetails();
            }
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.close();
          this.$emit("refresh");
          this.errorChecker(error);
        });
    },
    getBookingDetails() {
      this.showLoader("Loading Facilities");
      this.facilities = [];
      this.$http
        .get(`venues/facilities/bookings/${this.bookingForm.order_id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.isEmiratesIdCheck = false;
            this.disablePromotion = true;
            const data = response.data.data;
            this.uploadedAttachments = data.facility_booking.length > 0 && data.facility_booking[0] ? data.facility_booking[0].documents : [];
            this.bookingForm.order_notes = data.order_notes;
            if (data.facility_booking.length > 0) {
              let bookingData = data.facility_booking.find(
                (x) =>
                  x.date === this.date &&
                  x.start_time == this.start_time &&
                  x.facility_booking_duplicate_id == null
              );
              if (!bookingData) {
                bookingData = data.facility_booking.find(
                  (x) =>
                    x.date === this.date &&
                    x.facility_booking_duplicate_id == null
                );
              }
              // check is overnight
              if(! bookingData){
                bookingData = data.facility_booking.find( (x) =>
                  x.booking_end_date === this.date &&
                  x.id == this.id &&
                  x.facility_booking_duplicate_id == null
                );
                if(bookingData){
                  this.date = bookingData.date;
                  this.start_time = bookingData.start_time;
                  this.end_time = bookingData.end_time;
                  this.bookingForm.date = bookingData.date;
                  this.bookingForm.start_time = bookingData.start_time;
                  this.bookingForm.end_time = bookingData.end_time;
                }
              }

              this.bookingForm.id = bookingData.id;
              this.bookingForm.customer_type = bookingData.customer_type;
              this.bookingForm.id_proof_path = bookingData.id_proof;
              this.bookingForm.id_proof_number = bookingData.id_proof_number;
              this.bookingForm.id_proof_type_id = bookingData.id_proof_type_id;
              this.bookingForm.image_path = bookingData.image_path;
              this.bookingForm.start_time = bookingData.start_time;
              this.bookingForm.end_time = bookingData.end_time;
              this.bookingForm.attendance_count = bookingData.attendance;
              this.bookingForm.is_booking_approved = bookingData.is_booking_approved;
              this.attendies = bookingData.attendance;
            }

            if (data.discount != null) {
              if (data.discount.promotion != null) {
                this.bookingForm.promotion_code =
                  data.discount.promotion.promotion_code;
              }
              if (data.discount.member != null) {
                this.bookingForm.customer_type = "member";
                this.bookingForm.card_number =
                  data.discount.member.display_number;
              }
              this.bookingForm.discount = {
                actual_price: data.discount.actual_price,
                actual_tax: data.discount.actual_tax,
                actual_total: data.discount.actual_total,
              };
            }

            if (data.parent_order_id && data.parent_orders_id.length > 0) {
              this.bookingForm.parent_order_id = data.parent_order_id;
              this.bookingForm.parent_orders_id = data.parent_orders_id;
            } else {
              this.bookingForm.parent_orders_id = null;
            }

            if (data.customer) {
              this.bookingForm.name = `${data.customer.first_name}${
                data.customer.last_name ? " " + data.customer.last_name : ""
              }`;
              this.bookingForm.first_name = data.customer.first_name;
              this.bookingForm.last_name = data.customer.last_name;
              this.bookingForm.mobile = data.customer.mobile;
              this.bookingForm.email = data.customer.email;
              this.bookingForm.gender = data.customer.gender;
              this.bookingForm.dob = data.customer.dob;
              this.bookingForm.age_group = data.customer.age_group;
              this.bookingForm.country_id = data.customer.country_id;
              if (data.customer.venue_customer_tags) {
                const tags = [];
                data.customer.venue_customer_tags.map((cvt) => {
                  tags.push({ id: cvt.tag.id, name: cvt.tag.name });
                });
                this.bookingForm.customer_tag = tags;
              }
              this.bookingForm.opt_marketing = data.customer.opt_marketing == 1 ? true : false;

              if (data.customer.customer_documents) {
                this.bookingForm.customer_documents = data.customer.customer_documents;
              }

              if (this.bookingForm.customer_type == "member" &&  !this.bookingForm.card_number) {
                this.searchMember(
                  data.customer.mobile,
                  data.customer.customer_id,
                  data.customer.first_name,
                  data.customer.last_name
                );
              }
            }
            this.bookingFormAdded = 1;
            this.bookingFormTotal = 1;
            let fbId = data.facility_booking && data.facility_booking.length > 0? data.facility_booking[0].id: null;
            let group_customers = data.group_customers;
            if (data.group_customers.length > 0) {
              this.current_group_customer = data.group_customers[0];
            }
            group_customers.shift();

            let test = [];
            Object.values(group_customers).forEach((data1) => {
              if (!fbId || !data1.gc_fb_id || fbId == data1.gc_fb_id) {
                const tags = [];
                if (
                  data1.venue_customer_tags &&
                  data1.venue_customer_tags.length
                ) {
                  data1.venue_customer_tags.map((cvt) => {
                    tags.push({ id: cvt.tag.id, name: cvt.tag.name });
                  });
                }
                test.push({
                  attendance: false,
                  attendance_count: 1,
                  total_price: 0,
                  price: 0,
                  discount: null,
                  promotion_code: null,
                  customer_id: data1.customer_id,
                  name: `${data1.first_name}${
                    data1.last_name ? " " + data1.last_name : ""
                  }`,
                  first_name: data1.first_name,
                  last_name: data1.last_name,
                  mobile: data1.mobile,
                  email: data1.email,
                  gender: data1.gender,
                  dob: data1.dob,
                  age_group: data1.age_group,
                  country_id: data1.country_id,
                  opt_marketing: data1.opt_marketing == 1 ? true : false,
                  customer_tag: tags,
                });
              }
              // this.bookingFormAdded++;
              this.bookingFormTotal++;
            });

            this.bookingCustomersAddons = test;
            this.bookingFormAdded =
              this.bookingFormAdded +
              (this.bookingCustomersAddons.length
                ? this.bookingCustomersAddons.length
                : 0);

            if (data.group_customers.length) {
              this.$http
                .get(
                  `venues/facilities/bookings/participants?facility_id=${this.facility_id}&start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&date=${this.date}`
                )
                .then((participans_response) => {
                  if (
                    participans_response.status == 200 &&
                    participans_response.data.status == true
                  ) {
                    const data_participans_response = Array.from(
                      new Set(participans_response.data.data.map((a) => a.id))
                    ).map((id) => {
                      return participans_response.data.data.find(
                        (a) => a.id === id
                      );
                    });

                    this.bookedCapacity = data_participans_response.reduce(
                      (a, b) => a + parseFloat(b.attendance),
                      0
                    );
                  }
                });
              this.attendanceCustomers = data.group_customers;

              // this.attendies = this.attendanceCustomers.length;

              this.attendanceCustomers.shift();

              if (this.attendanceCustomers.length == 0) {
                this.bookingForm.attendance = false;
                if (this.bookedCapacity < this.capacity) {
                  this.attendanceSwitch = true;
                } else {
                  this.attendanceSwitch = false;
                }
              } else {
                this.bookingForm.attendance = true;
                this.attendanceSwitch = true;
              }
              this.editFlag = true;
            }
            this.bookingForm.price = data.price;
            this.bookingForm.status_id = data.status_id;
            this.bookingForm.order_id = data.id;
            this.bookingForm.customer_id = data.customer_id;

            if (data.company_sale != null) {
              this.bookingForm.company_id = data.company_sale.company_id;
              this.bookingForm.company_sale_id = data.company_sale.id;
              this.getActiveCompanySales();
            }

            this.bookingForm.products = [];
            this.currentOrderProducts = [];
            data.items.forEach((product) => {
              let pdata = {
                order_item_id: product.order_item_id,
                product_id: product.product_id,
                inventory_enable: product.inventory_enable,
                product_type_id: product.product_type_id,
                category_id: product.category_id,
                quantity: product.quantity,
                seasonal_pricing_id: product.seasonal_pricing_id,
                price: product.price,
                rental: product.rental != null ? true : false,
                is_full_day:
                  product.rental != null ? product.rental.is_full_day : false,
                venue_service_id: this.venue_service_id,
                discount: product.discount ? product.discount : false,
                name: product.name ? product.name : "Product Name",
                product_price: product.product_price,
                tax: product.tax,
                participant_count: product.participant_count,
                total_price: product.total,
              };
              if (product.category_name == "open_product" && product.rental) {
                this.bookingWithOpenProduct = true;
              }
              this.currentOrderProducts.push(pdata);
              this.bookingForm.products.push(pdata);
            });
            this.bookingForm.total_price = this.bookingForm.products.reduce(
              (a, b) => a + parseFloat(b.total_price),
              0
            );
            if (
              data.facility_booking.length > 0 &&
              data.facility_booking[0].repeat
            ) {
              let repeatData = data.facility_booking[0].repeat;
              this.bookingForm.repeat_dates_for_payment = [];
              this.bookingForm.repeat = true;
              this.bookingForm.repeats = repeatData.meta;
              this.bookingForm.repeat_id = repeatData.id;
              data.facility_booking.forEach((data) => {
                this.bookingForm.repeat_dates_for_payment.push({
                  date_id: data.id,
                  date: data.date,
                });
              });
              setTimeout(() => {
                this.repeatId = repeatData.id;
              });

              this.disablePromotion = true;
              this.$forceUpdate();
            }
            this.customerFormRefresh = now();
            this.updateBookingFormQuantity();
            this.checkFullDayProduct();
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    removeProduct(pIndex, index = null) {
      if (index === null) {
        // console.log("hi");
        let data = this.bookingForm.products[pIndex];
        if (this.bookingForm.products[pIndex].inventory_enable) {
          if (data.order_item_id) {
            this.deletedProducts.push(data.order_item_id);
          }
          let products = this.categories.find(
            (item) => item.id == data.category_id
          ).products;
          if (products) {
            products.forEach((el) => {
              if (el.id == data.product_id) {
                el.sales -= data.quantity;
              }
            });
          }
        }
        this.bookingForm.products.splice(pIndex, 1);
        if (this.bookingForm.repeats && this.bookingForm.repeats.length > 0) {
          this.bookingForm.repeats.forEach((repeat) => {
            let findIndex = repeat.products.findIndex(
              (x) => x.id == data.product_id
            );
            if (findIndex != -1) {
              repeat.products.splice(pIndex, 1);
            }
          });
        }

        if (data.rental == false && this.repeatId) {
          let findIndex = this.currentOrderProducts.findIndex(
            (x) => x.product_id == data.product_id
          );
          if (findIndex != null) {
            this.currentOrderProducts.splice(findIndex, 1);
          }
        }
        this.$forceUpdate();
        this.bookingForm.total_price = this.bookingForm.products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
        if (this.bookingForm.promotion_code != null) {
          this.verifyBenefit("promotion");
        }
        if (this.bookingForm.card_number != null) {
          this.verifyBenefit("membership");
        }
        this.refreshComponent++;
        this.updateBookingFormQuantity();
        this.refreshCustomersMain();
        this.$forceUpdate();
      } else {
        let data = this.attendanceCustomers[index].products[pIndex];
        if (this.attendanceCustomers[index].products[pIndex].inventory_enable) {
          if (data.order_item_id) {
            this.deletedProducts.push(data.order_item_id);
          }
          let products = this.categories.find(
            (item) => item.id == data.category_id
          ).products;
          if (products) {
            products.forEach((el) => {
              if (el.id == data.product_id) {
                el.sales -= data.quantity;
              }
            });
          }
        }
        this.attendanceCustomers[index].products.splice(pIndex, 1);
        if (this.bookingForm.repeats && this.bookingForm.repeats.length > 0) {
          this.bookingForm.repeats.forEach((repeat) => {
            let findIndex = repeat.products.findIndex(
              (x) => x.id == data.product_id
            );
            if (findIndex != -1) {
              repeat.products.splice(pIndex, 1);
            }
          });
        }

        if (data.rental == false && this.repeatId) {
          let findIndex = this.currentOrderProducts.findIndex(
            (x) => x.product_id == data.product_id
          );
          if (findIndex != null) {
            this.currentOrderProducts.splice(findIndex, 1);
          }
        }

        this.attendanceCustomers[index].total_price = this.attendanceCustomers[
          index
        ].products.reduce((a, b) => a + parseFloat(b.total_price), 0);
        if (this.attendanceCustomers[index].promotion_code != null) {
          this.verifyBenefit("promotion", index);
        }
        if (this.attendanceCustomers[index].card_number != null) {
          this.verifyBenefit("membership", index);
        }
        this.updateAttendanceFormQuantity(index);
        this.refreshCustomersAttendance(index);
      }
      this.checkFullDayProduct();
      this.$forceUpdate();
    },
    deleteProduct(data) {
      this.deletedProducts = data;
    },
    setCurrentOrderProducts(data) {
      this.currentOrderProducts = data;
      this.$forceUpdate();
    },
    checkFullDayProduct() {
      const hasFullDay = this.bookingForm.products.some(
        (obj) => obj.is_full_day === 1
      );

      if (hasFullDay) {
        this.fullDay = true;
      } else {
        this.fullDay = false;
      }
    },
    verifyBenefit(type, index = null) {
      this.clearBenefit(index);
      if (index === null && this.bookingForm.products.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      if (
        index !== null &&
        this.attendanceCustomers[index].products &&
        this.attendanceCustomers[index].products.length == 0
      ) {
        this.showError("Please add atleast one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        if (index === null) {
          data.promotion_code = this.bookingForm.promotion_code;
        } else {
          data.promotion_code = this.attendanceCustomers[index].promotion_code;
        }
        if (data.promotion_code === null) {
          this.clearBenefit(index);
          return;
        }
      } else {
        if (index === null) {
          data.card_number = this.bookingForm.card_number;
        } else {
          data.card_number = this.attendanceCustomers[index].card_number;
        }
      }
      if (this.bookingForm.mobile && index === null) {
        data.mobile = this.bookingForm.mobile;
      } else if (
        index !== null &&
        this.attendanceCustomers[index] &&
        this.attendanceCustomers[index].mobile
      ) {
        data.mobile = this.attendanceCustomers[index].mobile;
      }
      if (index === null) {
        if (this.bookingForm.discount) {
          data.products = [];
          this.bookingForm.products.forEach((product) => {
            let pdata = product;
            if (product.discount) {
              pdata.price = product.discount.actual_price;
              delete pdata.discount;
            } else {
              if (product.product_price) {
                pdata.price = product.product_price;
              }
            }
            data.products.push(pdata);
          });
        } else {
          data.products = this.bookingForm.products;
          data.products.forEach((element) => {
            if (element.product_price) {
              element.price = element.product_price;
            } else {
              element.price = element.price / element.quantity;
            }
          });
        }
      } else {
        if (this.attendanceCustomers[index].discount) {
          data.products = [];
          this.attendanceCustomers[index].products.forEach((product) => {
            let pdata = product;
            if (product.discount) {
              pdata.price = product.discount.actual_price;
              delete pdata.discount;
            } else {
              if (product.product_price) {
                pdata.price = product.product_price;
              }
            }
            data.products.push(pdata);
          });
        } else {
          data.products = this.attendanceCustomers[index].products;
          data.products.forEach((element) => {
            if (element.product_price) {
              element.price = element.product_price;
            } else {
              element.price = element.price / element.quantity;
            }
          });
        }
      }

      let url = "venues/benefits/verify";
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (index === null) {
              this.bookingForm.discount = data.discount;
              this.bookingForm.price = data.price;
              this.bookingForm.total_price = data.total;
              data.products.map( (product) => {
                if (product.rental === "1") {
                  product.rental = true;
                }
              })
              this.bookingForm.products = data.products;
            } else {
              this.attendanceCustomers[index].discount = data.discount;
              this.attendanceCustomers[index].price = data.price;
              this.attendanceCustomers[index].products = data.products;
              this.attendanceCustomers[index].total_price = data.total;
            }
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    clearBenefit(index = null) {
      if (this.bookingForm.discount && index === null) {
        if (this.bookingForm.customer_type != "member") {
          this.bookingForm.card_number = null;
        }
        this.bookingForm.price = this.bookingForm.discount.actual_price;
        this.bookingForm.total_price = this.bookingForm.discount.actual_total;
        this.bookingForm.products.forEach((product, index) => {
          if (product.discount) {
            this.bookingForm.products[index].price =
              product.discount.actual_price;
            this.bookingForm.products[index].total_price =
              product.discount.actual_total;
          }
          this.bookingForm.products[index].discount = null;
        });
        setTimeout(() => {
          this.bookingForm.discount = null;
          this.refreshComponent++;
          this.$forceUpdate();
        });
      } else {
        if (
          this.attendanceCustomers[index] &&
          this.attendanceCustomers[index].discount
        ) {
          if (this.attendanceCustomers[index].customer_type != "member") {
            this.attendanceCustomers[index].card_number = null;
          }
          this.attendanceCustomers[index].price = this.attendanceCustomers[
            index
          ].discount.actual_price;
          this.attendanceCustomers[
            index
          ].total_price = this.attendanceCustomers[index].discount.actual_total;
          if (
            this.attendanceCustomers[index].products &&
            this.attendanceCustomers[index].products.length > 0
          ) {
            this.attendanceCustomers[index].products.forEach(
              (product, pIndex) => {
                if (product.discount) {
                  this.attendanceCustomers[index].products[pIndex].price =
                    product.discount.actual_price;
                  this.attendanceCustomers[index].products[pIndex].total_price =
                    product.discount.actual_total;
                }
                if (
                  this.attendanceCustomers[index].products &&
                  this.attendanceCustomers[index].products[pIndex]
                ) {
                  if (
                    this.attendanceCustomers[index].products[pIndex].discount
                  ) {
                    this.$set(
                      this.attendanceCustomers[index],
                      "discount",
                      null
                    );
                  }
                }
              }
            );
          }
          setTimeout(() => {
            this.attendanceCustomers[index].discount = null;
            this.$forceUpdate();
          });
        }
      }
    },
    customerTypeChange(e, index = null) {
      if (index === null) {
        if (this.bookingForm.customer_type == "corporate") {
          this.getActiveCompanySales();
        }
        if (
          typeof this.bookingForm.promotion_code === "undefined" ||
          this.bookingForm.promotion_code === null
        ) {
          this.clearBenefit();
        }
        if (
          this.bookingForm.customer_type == "normal" &&
          this.bookingForm.card_number != null
        ) {
          this.$set(this.bookingForm, "card_number", null);
          this.clearBenefit();
        }
      } else {
        if (this.attendanceCustomers[index].customer_type == "corporate") {
          this.getActiveCompanySales();
        }
        if (
          typeof this.attendanceCustomers[index].promotion_code ===
            "undefined" ||
          this.attendanceCustomers[index].promotion_code === null
        ) {
          this.clearBenefit(index);
        }
        if (
          this.attendanceCustomers[index].customer_type == "normal" &&
          this.attendanceCustomers[index].card_number != null
        ) {
          this.$set(this.attendanceCustomers[index], "card_number", null);
          this.clearBenefit(index);
        }
      }
    },
    removeCustomer(index) {
      this.attendanceCustomers.splice(index, 1);
      this.bookedCapacity = this.bookedCapacity - 1;
      this.attendanceCustomersTotal.splice(index, 1);
      this.attendanceCustomerAdded.splice(index, 1);
      this.attendanceCustomersAddons.splice(index, 1);
      this.capacityStatus();
    },
    changeStartTime(){
      console.log('this.bookingForm.start_tim',this.bookingForm.start_time)
      const startTime = moment(this.bookingForm.start_time, "HH:mm:ss");
      const endTime = startTime.add(this.minBookingTime, "minutes");
      this.bookingForm.end_time = endTime.format("HH:mm:ss");
      this.getFacilityEndTimes();
    },
    getFacilityEndTimes() {
      this.showLoader("Loading");
      this.endTimes = [];
      this.$http
          .get(
              `venues/facilities/bookings/utils?facility_id=${this.facility_id}&date=${this.bookingForm.date}&start_time=${this.bookingForm.start_time}&increment=${this.increment}&min_booking_time=${this.minBookingTime}&booking_id=${this.id}&per_capacity=${this.perCapacity}&venue_service_id=${this.venue_service_id}&enable_overnight_booking=${this.enableOvernightBooking}`
          )
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              this.endTimes = response.data.data.end_times;
            }
          });
    },
    getRentalProducts() {
      if (this.currentRepeatDates) {
        this.validateRepeatBookings(this.currentRepeatDates);
      } else {
        this.$http
          .get(
            `venues/facilities/bookings/rentals?start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&facility_id=${this.facility_id}&date=${this.date}&venue_service_id=${this.venue_service_id}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.productCombinations = data;
              if (data.length) {
                this.changeRentalProducts(data[0]);
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    changeRentalProducts(rentalCombination) {
      let otherProducts = [];
      if (this.repeatId) {
        otherProducts = this.currentOrderProducts.filter(
          (item) => !item.rental
        );
      }
      if (!this.repeatId) {
        otherProducts = this.bookingForm.products.filter(
          (item) => !item.rental
        );
      }
      this.selectedCombinationPrice = rentalCombination.key;
      let rentals = [];
      rentalCombination.products.forEach((product) => {
        let tax_amount = product.total_tax_amount? product.total_tax_amount: product.tax_amount;

        if(product.is_repeatable){
            if(!product.product_price_when_overlapping && !product.total_tax_amount){
              tax_amount = tax_amount * parseFloat(product.quantity < 1?1:product.quantity);
            }
            if(product.product_price_when_overlapping && product.price > product.product_price_when_overlapping && !product.total_tax_amount){
              tax_amount = tax_amount * parseFloat(product.quantity < 1?1:product.quantity);
              // tax_amount =  tax_amount * parseFloat(product.quantity);
            }
        }



        rentals.push({
          product_id: product.id,
          product_type_id: product.product_type_id,
          price: product.price,
          name: product.name,
          tax: product.tax_amount,
          category_id: product.category_id,
          rental: true,
          product_price: product.product_price_when_overlapping? product.product_price_when_overlapping: product.product_price,
          quantity: product.quantity,
          total_price: product.price + tax_amount,
          total_tax_amount: product.total_tax_amount? product.total_tax_amount: tax_amount,
        });
      });
      if (otherProducts) {
        this.bookingForm.products = [...rentals, ...otherProducts];
      } else {
        this.bookingForm.products = rentals;
      }

      this.bookingForm.total_price = this.bookingForm.products.reduce(
        (a, b) => a + parseFloat(b.total_price),
        0
      );

      this.showCombinationDialog = false;
      if (this.bookingForm.promotion_code != null) {
        this.verifyBenefit("promotion");
      }
      if (this.bookingForm.card_number != null) {
        this.verifyBenefit("membership");
      }
      this.$forceUpdate();
    },
    chooseRentalCombination() {
      this.showCombinationDialog = true;
    },
    getActiveCompanySales() {
      this.$http
        .get(`venues/companies/active/sales?date=${this.date}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.companies = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    async removeRepeatRow(index) {
      await this.$store
        .dispatch("removeRepeatRow", index)
        .then(() => {
          this.setRepeatData();
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setRepeatData() {
      this.productCombinations = [];
      this.bookingForm.repeats = this.$store.getters.getRepeats;
      this.combineRepeatProducts();
    },
    combineRepeatProducts() {
      let otherProducts = this.bookingForm.products.filter(
        (item) => !item.rental
      );
      let rentals = [];
      this.bookingForm.repeats.forEach((repeat) => {
        repeat.products.forEach((product) => {
          let index = rentals.findIndex(
            (item) => item.product_id == product.id
          );
          if (index == -1) {

            // let tax_amount =  product.product_price_when_overlapping?product.tax_amount:product.total_tax_amount ? product.total_tax_amount : (product.tax_amount * (product.quantity < 1 ? 1 : product.quantity));

            let tax_amount =  product.product_price_when_overlapping? (product.total_tax_amount ?  product.total_tax_amount : (product.tax_amount * (product.quantity < 1 ? 1 : product.quantity))) : (product.total_tax_amount ? product.total_tax_amount : product.tax_amount);


            //let total_price = product.price + tax_amount;

            if(product.product_price_when_overlapping){
              if(product.price >= product.product_price_when_overlapping){
                tax_amount = (product.product_price_when_overlapping * 5 /100) * parseFloat(product.quantity < 1 ? 1 : product.quantity);
              }
              //tax_amount = (product.product_price_when_overlapping * 5 /100) * parseFloat(product.quantity < 1 ? 1 : product.quantity);
            }

            rentals.push({
              product_id: product.id,
              product_type_id: product.product_type_id,
              price: product.price,
              name: product.name,
              tax: product.tax_amount,
              category_id: product.category_id,
              rental: true,
              product_price: product.product_price_when_overlapping? product.product_price_when_overlapping: product.product_price,
              quantity: product.quantity,
              total_price: product.price + tax_amount,
              total_tax_amount: product.price + product.total_tax_amount,

            });
          } else {

            rentals[index].price += product.price;
            rentals[index].quantity += product.quantity;
            // rentals[index].total_price += product.price + product.tax_amount * product.quantity;
            rentals[index].total_price += product.price + (product.total_tax_amount ? product.total_tax_amount : product.tax_amount);
            rentals[index].total_tax_amount += product.price + product.total_tax_amount;

          }
        });
      });

      if (otherProducts) {
        this.bookingForm.products = [...rentals, ...otherProducts];
      } else {
        this.bookingForm.products = rentals;
      }
      this.bookingForm.price = this.bookingForm.products.reduce(
        (a, b) => a + parseFloat(b.price),
        0
      );

      // let tax = this.bookingForm.products.reduce(
      //   (a, b) => a + parseFloat(b.tax * b.quantity),
      //   0
      // );

      // this.bookingForm.total_price = this.bookingForm.price + tax;
      this.bookingForm.total_price= this.bookingForm.products.reduce(
        (a, b) => a + parseFloat(b.total_price),
        0
      );
      this.$forceUpdate();
      if (this.bookingForm.promotion_code != null) {
        this.verifyBenefit("promotion");
      }
      if (this.bookingForm.card_number != null) {
        this.verifyBenefit("membership");
      }
    },
    confirmActions(data) {
      if (data.type == "cancel") {
        this.deleteBookings(data.id);
      } else if (data.type == "repeatBookingCancel") {
        this.multipleCancelForRepeatBooking();
      }else if (data.type == "customer") {
        this.bookingCustomersAddons.splice(data.id, 1);
        this.bookingFormAdded--;
      }else if(data.type === 'update'){
        this.addOrEditBooking()
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },

    timeFormat(time) {
      return moment(time, "HH:mm:ss").format("hh:mm a");
    },

    confirmCancel() {
      this.confirmModel = {
        id: this.order_id,
        title: `Do you want cancel this booking?`,
        description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel",
      };
    },

    cancelRepeatBooking() {
      if (this.bookingWithOpenProduct) {
        this.confirmCancel();
      } else if (
        this.repeatDatesForPayments.filter((element) => element.isPaid).length >
        0
      ) {
        this.confirmModel = {
          id: this.order_id,
          title: `Do you want cancel this booking?`,
          description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
          type: "repeatBookingCancel",
        };
      } else {
        this.showError("Please select date for cancel");
      }
    },

    deleteBookings(id) {
      this.showLoader("Wait");
      this.$http
        .delete(`venues/orders/${id}`)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Booking cancelled successfully");
            this.close();
            this.$emit("cancel");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    changeRepeatBookingSwitch() {
      if (!this.bookingForm.repeat) {
        this.currentRepeatDates = null;
        this.getRentalProducts();
        this.bookingForm.repeats = [];
      } else {
        this.$store.commit("resetRepeatState");
        this.$store
          .dispatch("addRepeatRow", {
            start_time: this.bookingForm.start_time,
            end_time: this.bookingForm.end_time,
            date: this.date,
            facility_id: this.facility_id,
            init: true,
          })
          .catch((error) => {
            this.errorChecker(error);
          });
        this.bookingForm.repeats = [];
      }
      this.$forceUpdate();
    },

    addRepeatRow() {
      this.$store
        .dispatch("addRepeatRow", {
          start_time: null,
          end_time: null,
          date: this.date,
          facility_id: this.facility_id,
          init: true,
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    showInvoice() {
      if (
        this.bookingForm.parent_order_id &&
        this.bookingForm.parent_orders_id.length > 0
      ) {
        this.$emit(
          "repeatBookingReceipt",
          this.bookingForm.parent_orders_id.sort((a, b) => b - a)
        );
      } else {
        this.$emit("pay", this.bookingForm.order_id);
      }
    },
    addAttandance() {
      this.attendanceCustomers.push({
        opt_marketing: false,
        productCategoryId: null,
        quantity: 1,
        price: 0,
        total_price: 0,
        selectedProduct: { quantity: 1, price: 0 },
        products: new Array(),
        discount: null,
        promotion_code: null,
      });
      this.bookedCapacity = this.bookedCapacity + 1;
      this.attendanceCustomersTotal.push(0);
      this.attendanceCustomerAdded.push(1);
      this.attendanceCustomersAddons.push([]);
      this.$forceUpdate();
      this.capacityStatus();
    },
    capacityStatus() {
      if (this.bookedCapacity >= this.capacity) {
        this.addAttandanceBtn = false;
      } else {
        this.addAttandanceBtn = true;
      }
      this.$forceUpdate();
    },
    changeAttendanceSwitch() {
      this.attendanceData = new Array();
      if (this.bookingForm.attendance == false) {
        this.bookingForm.attendance_count = 1;
        this.attendanceCustomers = [
          {
            opt_marketing: false,
            productCategoryId: null,
            quantity: 1,
            price: 0,
            total_price: 0,
            selectedProduct: { quantity: 1, price: 0 },
            products: new Array(),
            discount: null,
            promotion_code: null,
          },
        ];
        this.attendanceData = new Array();
      } else {
        this.bookedCapacity = 1 + this.totalParticipants;
      }

      this.$forceUpdate();
    },
    showRepeatPaymentsConfirmation() {
      let unpaidOrderLength = this.repeatDatesForPayments.filter(
        (element) => element.isPaid && element.status_id == 5
      ).length;
      let totalActiveOrderLength = this.repeatDatesForPayments.filter(
        (element) => element.status_id != 2
      ).length;
      let paidOrderLength = this.repeatDatesForPayments.filter(
        (element) => element.isPaid && element.status_id == 1
      ).length;
      if (unpaidOrderLength == totalActiveOrderLength) {
        // console.log("1");
        this.$emit("pay", this.bookingForm.order_id);
      } else if (paidOrderLength > 0) {
        // console.log("2");
        this.showError("Please unselect paid date");
      } else if (unpaidOrderLength > 0) {
        // console.log("3: " + unpaidOrderLength);
        var findUnpaidOrders = this.repeatDatesForPayments.filter(
          (x) => x.status_id == 5
        );
        if (
          findUnpaidOrders.length ==
          this.repeatDatesForPayments.filter(
            (element) => element.isPaid && element.status_id == 5
          ).length
        ) {
          // console.log("4 ");
          this.$emit("pay", findUnpaidOrders[0].order_id);
        } else {
          // console.log("5");
          this.setPayerCustomerList();
          this.multiplePayment();
        }
      } else {
        this.showError("Please select date for pay");
      }
    },

    multiplePayment() {
      if (this.bookingForm.products.length == 0) {
        this.showError("Please add at least one product");
        return;
      }
      if (
        this.repeatDatesForPayments.filter(
          (element) => element.isPaid && element.status_id == 5
        ).length > 0
      ) {
        this.enableRepeatOrderPay = true;
        this.repeatBookingIds = [];
        this.repeatDatesForPayments.forEach((el) => {
          if (el.isPaid && el.status_id == 5) {
            this.repeatBookingIds.push(el);
          }
        });
      }
      this.$forceUpdate();
    },

    multipleCancelForRepeatBooking() {
      let bookingIds = [];

      this.repeatDatesForPayments.forEach((el) => {
        if (el.isPaid) {
          bookingIds.push({
            booking_id: el.booking_id,
            date: el.date,
            order_id: el.order_id,
            status_id: el.status_id,
            facility_id: el.facility_id,
            end_time: el.end_time,
            start_time: el.start_time,
          });
        }
      });
      this.showLoader();
      this.$http
        .post(`venues/facilities/bookings/repeat/multiple/cancel`, {
          booking_ids: bookingIds,
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Booking cancelled successfully");
            this.close();
            this.$emit("cancel");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    repeatDatesForPaymentChange(dates, rentalCombination) {
      this.repeatDatesForPayments = dates;
      if (dates.filter((element) => element.isPaid).length == 1) {
        this.isEnableRepeatBookingPayment = true;
        this.isEnableRepeateBookingReschedule = true;
      } else if (dates.filter((element) => element.isPaid).length == 0) {
        this.isEnableRepeatBookingPayment = false;
        this.isEnableRepeateBookingReschedule = false;
      } else {
        this.isEnableRepeatBookingPayment = true;
        this.isEnableRepeateBookingReschedule = true;
      }

      if (rentalCombination) {
        this.changeRentalProducts(rentalCombination);
        if (this.bookingForm.promotion_code != null) {
          this.verifyBenefit("promotion");
        }
        if (this.bookingForm.card_number != null) {
          this.verifyBenefit("membership");
        }
      } else {
        this.changeRentalProducts({ products: [] });
      }
    },
    searchMember(mobile, id, first_name, last_name, index = null) {
      this.isSearchLoading = true;
      let query = "";
      query = `field=id&search=${id}`;
      this.$http
        .get(`venues/memberships/members/filters?${query}`)
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.data;
            if (data.length > 0) {
              if (data[0].card_number) {
                if (index === null) {
                  this.bookingForm.customer_type = "member";
                  this.$set(
                    this.bookingForm,
                    "card_number",
                    data[0].card_number
                  );
                  this.$set(
                    this.bookingForm,
                    "membership_id",
                    data[0].membership_id
                  );
                  this.$forceUpdate();
                  if (
                    this.bookingForm &&
                    this.bookingForm.products.length > 0
                  ) {
                    this.verifyBenefit("membership");
                  }
                } else {
                  this.$set(
                    this.attendanceCustomers[index],
                    "customer_type",
                    "member"
                  );
                  this.$set(
                    this.attendanceCustomers[index],
                    "card_number",
                    data[0].card_number
                  );
                  this.$set(
                    this.attendanceCustomers[index],
                    "membership_id",
                    data[0].membership_id
                  );
                  this.$forceUpdate();
                  if (
                    this.attendanceCustomers[index] &&
                    this.attendanceCustomers[index].products &&
                    this.attendanceCustomers[index].products.length > 0
                  ) {
                    this.verifyBenefit("membership", index);
                  }
                }
              } else {
                if (index === null) {
                  if (this.bookingForm.customer_type == "member") {
                    this.bookingForm.customer_type = "normal";
                    this.$set(this.bookingForm, "customer_type", "normal");
                    this.$set(this.bookingForm, "membership_id", null);
                  }
                } else {
                  if (
                    this.attendanceCustomers[index].customer_type == "member"
                  ) {
                    this.attendanceCustomers[index].customer_type = "normal";
                    this.attendanceCustomers[index].membership_id = null;
                  }
                }
                this.clearCardAndBenefits(index);
              }
            } else {
              if (index === null) {
                if (this.bookingForm.customer_type == "member") {
                  this.bookingForm.customer_type = "normal";
                  this.$set(this.bookingForm, "customer_type", "normal");
                  this.$set(this.bookingForm, "membership_id", null);
                }
              } else {
                if (this.attendanceCustomers[index].customer_type == "member") {
                  this.attendanceCustomers[index].customer_type = "normal";
                  this.attendanceCustomers[index].membership_id = null;
                }
              }
              this.clearCardAndBenefits(index);
            }
            this.$forceUpdate();
          } else {
            this.clearCardAndBenefits(index);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.clearCardAndBenefits(index);
        });
    },
    clearCardAndBenefits(attCustomerIndex = null) {
      if (attCustomerIndex === null) {
        this.bookingForm.member = null;
        this.bookingForm.customer_type = this.bookingForm.customer_type
          ? this.bookingForm.customer_type
          : null;
        this.$set(this.bookingForm, "card_number", null);
        if (this.bookingForm.promotion_code == null) {
          this.clearBenefit();
        }
      } else {
        if (this.attendanceCustomers[attCustomerIndex]) {
          this.attendanceCustomers[attCustomerIndex].customer_type = this
            .attendanceCustomers[attCustomerIndex].customer_type
            ? this.attendanceCustomers[attCustomerIndex].customer_type
            : null;
          this.$set(
            this.attendanceCustomers[attCustomerIndex],
            "card_number",
            null
          );
          if (
            this.attendanceCustomers[attCustomerIndex].promotion_code == null
          ) {
            this.clearBenefit(attCustomerIndex);
          }
          // this.clearBenefit(attCustomerIndex);
        }
      }
      this.$forceUpdate();
    },
    checkSlotCapacity() {
      if (parseInt(this.bookingForm.attendance_count) <= 0) {
        this.bookingForm.attendance_count = 1;
        return;
      }

      let totalAttendance;
      if (this.id > 0) {
        totalAttendance =
          parseInt(this.bookingForm.attendance_count) +
          (this.bookedCapacity ? this.bookedCapacity - this.attendies : 0);
      } else {
        totalAttendance =
          parseInt(this.bookingForm.attendance_count) +
          (this.bookedCapacity ? this.bookedCapacity : 0);
      }

      if (totalAttendance > this.capacity) {
        this.bookingForm.attendance_count = 1;
        this.showError(
          `Only ${this.capacity -
            (this.attendies
              ? this.bookedCapacity - this.attendies
              : this.bookedCapacity)} slot available `
        );
      }
    },
    setCustomerProduct(index = null, products) {
      if (index === null) {
        this.bookingForm.products = products;
        this.checkFullDayProduct();

        if (this.bookingForm.promotion_code != null) {
          this.verifyBenefit("promotion");
        }
        if (this.bookingForm.card_number != null) {
          this.verifyBenefit("membership");
        }

        this.bookingForm.total_price = products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
        this.refreshComponent++;
        this.updateBookingFormQuantity();
      } else {
        this.attendanceCustomers[index].products = products;
        if (
          this.attendanceCustomers[index].promotion_code &&
          this.attendanceCustomers[index].promotion_code != null
        ) {
          this.verifyBenefit("promotion", index);
        }
        if (
          this.attendanceCustomers[index].card_number &&
          this.attendanceCustomers[index].card_number != null
        ) {
          this.verifyBenefit("membership", index);
        }

        this.attendanceCustomers[index].total_price = products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
        this.updateAttendanceFormQuantity(index);
      }

      this.$forceUpdate();
    },
    setCustomerDataAddon(data, key, index = null) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (index === null) {
        if (data.mobile && data.first_name && data.customer_id) {
          this.isEmiratesIdCheck = false;
          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name
          );
        } else {
          this.clearCardAndBenefits();
        }
        if (!data.customer_id && this.id > 0) {
          this.$set(this.bookingCustomersAddons[key], "customer_id", null);
        }

        if (!data.name && data.first_name) {
          this.$set(this.bookingCustomersAddons[key], "name", data.first_name);
        }
        if (
          this.bookingCustomersAddons[key].customer_id &&
          !data.customer_id &&
          this.bookingCustomersAddons[key].name != data.name &&
          this.bookingCustomersAddons[key].mobile != data.mobile
        ) {
          this.$set(this.bookingCustomersAddons[key], "mobile", null);
          this.bookingCustomersAddons[key].search = null;
          this.bookingCustomersAddons[key].nameSearch = null;
          this.$set(this.bookingCustomersAddons[key], "email", null);
          this.$set(this.bookingCustomersAddons[key], "gender", null);
          this.$set(this.bookingCustomersAddons[key], "name", null);
          this.$set(this.bookingCustomersAddons[key], "customer_id", null);
          this.$set(this.bookingCustomersAddons[key], "first_name", null);
          this.$set(this.bookingCustomersAddons[key], "image_path", null);
          this.$set(this.bookingCustomersAddons[key], "dob", null);
          this.$set(this.bookingCustomersAddons[key], "age_group", null);
          this.$set(this.bookingCustomersAddons[key], "country_id", null);
          this.$set(this.bookingCustomersAddons[key], "last_name", null);
          this.$set(this.bookingCustomersAddons[key], "opt_marketing", false);
          this.$set(this.bookingCustomersAddons[key], "id_proof_type_id", null);
          this.$set(this.bookingCustomersAddons[key], "id_proof_number", null);
          this.$set(this.bookingCustomersAddons[key], "id_proof_path", null);
          this.$forceUpdate();
        }
        if (data.mobile)
          this.$set(this.bookingCustomersAddons[key], "mobile", data.mobile);
        if (data.email)
          this.$set(this.bookingCustomersAddons[key], "email", data.email);
        if (data.country_id) {
          this.$set(
            this.bookingCustomersAddons[key],
            "country_id",
            data.country_id
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingCustomersAddons[key], "country_id", null);
          }
        }
        if (data.gender) {
          this.$set(this.bookingCustomersAddons[key], "gender", data.gender);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingCustomersAddons[key], "gender", null);
          }
        }
        if (data.dob) {
          this.$set(this.bookingCustomersAddons[key], "dob", data.dob);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingCustomersAddons[key], "dob", null);
          }
        }
        if (data.age_group) {
          this.$set(this.bookingCustomersAddons[key], "age_group", data.age_group);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingCustomersAddons[key], "age_group", null);
          }
        }
        if (data.name) {
          data.name = data.name.replace(/\s\s+/g, " ");
          data.name = data.name.trim();
          this.$set(this.bookingCustomersAddons[key], "name", data.name);
        }
        if (data.last_name) {
          data.last_name = data.last_name.replace(/\s\s+/g, " ");
          data.last_name = data.last_name.trim();
          this.$set(
            this.bookingCustomersAddons[key],
            "last_name",
            data.last_name
          );
        } else {
          this.$set(this.bookingCustomersAddons[key], "last_name", null);
        }
        if (data.first_name) {
          data.first_name = data.first_name.replace(/\s\s+/g, " ");
          data.first_name = data.first_name.trim();
          this.$set(
            this.bookingCustomersAddons[key],
            "first_name",
            data.first_name
          );
        }
        if (data.customer_id)
          this.$set(
            this.bookingCustomersAddons[key],
            "customer_id",
            data.customer_id
          );
        if (data.image_path) {
          this.$set(
            this.bookingCustomersAddons[key],
            "image_path",
            data.image_path
          );
        } else {
          this.$set(this.bookingCustomersAddons[key], "image_path", null);
        }
        if (data.id_proof_type_id) {
          this.$set(
            this.bookingCustomersAddons[key],
            "id_proof_type_id",
            data.id_proof_type_id
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(
              this.bookingCustomersAddons[key],
              "id_proof_type_id",
              null
            );
          }
        }
        if (data.id_proof_number) {
          this.$set(
            this.bookingCustomersAddons[key],
            "id_proof_number",
            data.id_proof_number
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(
              this.bookingCustomersAddons[key],
              "id_proof_number",
              null
            );
          }
        }
        if (data.id_proof_path) {
          this.$set(
            this.bookingCustomersAddons[key],
            "id_proof_path",
            data.id_proof_path
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingCustomersAddons[key], "id_proof_path", null);
          }
        }

        if (data.id_proof) {
          this.$set(
            this.bookingCustomersAddons[key],
            "id_proof",
            data.id_proof
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingCustomersAddons[key], "id_proof", null);
          }
        }
        if (data.opt_marketing) {
          if (data.opt_marketing == 1) {
            this.$set(this.bookingCustomersAddons[key], "opt_marketing", true);
          } else {
            this.$set(this.bookingCustomersAddons[key], "opt_marketing", false);
          }
        }
        if (data.customer_documents) {
          this.bookingCustomersAddons[key].customer_documents =
            data.customer_documents;
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
          ) {
            this.$set(
              this.bookingCustomersAddons[key],
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
          ) {
            this.$set(
              this.bookingCustomersAddons[key],
              "id_proof_number",
              data.customer_documents[0].id_proof_number
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
          ) {
            this.$set(
              this.bookingCustomersAddons[key],
              "id_proof_path",
              data.customer_documents[0].id_proof_path
            );
          }
        } else {
          if (data.customer_id) {
            this.bookingCustomersAddons[key].customer_documents = [];
          }
        }
        if (data.customer_tag) {
          this.$set(
            this.bookingCustomersAddons[key],
            "customer_tag",
            data.customer_tag
          );
        } else {
          this.$set(this.bookingCustomersAddons[key], "customer_tag", null);
        }
      } else {
        if (data.mobile && data.first_name && data.customer_id) {
          this.isEmiratesIdCheck = false;
          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name,
            key
          );
        } else {
          this.clearCardAndBenefits(index);
        }

        if (!data.customer_id) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "customer_id",
            null
          );
        }

        if (!data.name && data.first_name) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "name",
            data.first_name
          );
        }
        if (
          this.attendanceCustomersAddons[key][index].customer_id &&
          !data.customer_id &&
          this.attendanceCustomersAddons[key][index].name != data.name &&
          this.attendanceCustomersAddons[key][index].mobile != data.mobile
        ) {
          this.$set(this.attendanceCustomersAddons[key][index], "mobile", null);
          this.attendanceCustomersAddons[key][index].search = null;
          this.attendanceCustomersAddons[key][index].nameSearch = null;
          this.$set(this.attendanceCustomersAddons[key][index], "email", null);
          this.$set(this.attendanceCustomersAddons[key][index], "gender", null);
          this.$set(this.attendanceCustomersAddons[key][index], "name", null);
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "customer_id",
            null
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "first_name",
            null
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "image_path",
            null
          );
          this.$set(this.attendanceCustomersAddons[key][index], "dob", null);
          this.$set(this.attendanceCustomersAddons[key][index], "age_group", null);
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "country_id",
            null
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "last_name",
            null
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "opt_marketing",
            false
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof_type_id",
            null
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof_number",
            null
          );
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof_path",
            null
          );
          this.$forceUpdate();
        }
        if (data.mobile)
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "mobile",
            data.mobile
          );
        if (data.email)
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "email",
            data.email
          );
        if (data.country_id) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "country_id",
            data.country_id
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "country_id",
              null
            );
          }
        }
        if (data.gender) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "gender",
            data.gender
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "gender",
              null
            );
          }
        }
        if (data.dob) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "dob",
            data.dob
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomersAddons[key][index], "dob", null);
          }
        }
        if (data.age_group) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "age_group",
            data.age_group
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomersAddons[key][index], "age_group", null);
          }
        }
        if (data.name) {
          data.name = data.name.replace(/\s\s+/g, " ");
          data.name = data.name.trim();
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "name",
            data.name
          );
        }
        if (data.last_name) {
          data.last_name = data.last_name.replace(/\s\s+/g, " ");
          data.last_name = data.last_name.trim();
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "last_name",
            data.last_name
          );
        } else {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "last_name",
            null
          );
        }
        if (data.first_name) {
          data.first_name = data.first_name.replace(/\s\s+/g, " ");
          data.first_name = data.first_name.trim();
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "first_name",
            data.first_name
          );
        }
        if (data.customer_id)
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "customer_id",
            data.customer_id
          );
        if (data.image_path) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "image_path",
            data.image_path
          );
        } else {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "image_path",
            null
          );
        }
        if (data.id_proof_type_id) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof_type_id",
            data.id_proof_type_id
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof_type_id",
              null
            );
          }
        }
        if (data.id_proof_number) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof_number",
            data.id_proof_number
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof_number",
              null
            );
          }
        }
        if (data.id_proof_path) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof_path",
            data.id_proof_path
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof_path",
              null
            );
          }
        }

        if (data.id_proof) {
          this.$set(
            this.attendanceCustomersAddons[key][index],
            "id_proof",
            data.id_proof
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof",
              null
            );
          }
        }
        if (data.opt_marketing) {
          if (data.opt_marketing == 1) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "opt_marketing",
              true
            );
          } else {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "opt_marketing",
              false
            );
          }
        }
        if (data.customer_documents) {
          this.attendanceCustomersAddons[key][index].customer_documents =
            data.customer_documents;
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
          ) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
          ) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof_number",
              data.customer_documents[0].id_proof_number
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
          ) {
            this.$set(
              this.attendanceCustomersAddons[key][index],
              "id_proof_path",
              data.customer_documents[0].id_proof_path
            );
          }
        } else {
          this.attendanceCustomersAddons[key][index].customer_documents = [];
        }
        if (data.customer_tag) {
          this.$set(
            this.bookingCustomersAddons[key][index],
            "customer_tag",
            data.customer_tag
          );
        } else {
          this.$set(
            this.bookingCustomersAddons[key][index],
            "customer_tag",
            null
          );
        }
      }
      this.$refs.form.resetValidation();
      this.$forceUpdate();
    },
    setPayerCustomerList() {
      this.payerCustomerList = [];
      if (this.bookingForm.customer_id) {
        this.payerCustomerList.push({
          customerId: this.bookingForm.customer_id,
          customerName: this.bookingForm.name,
          mobile: this.bookingForm.mobile,
          email: this.bookingForm.email,
          cashWallet: this.wallet.cash,
        });
      }
    },

    approveBooking() {
      this.showLoader("Loading");
      this.$http
        .get(
          `venues/facilities/bookings/booking-status/${this.bookingForm.id}/approve`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.$emit("refresh");
            this.showInvoice();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getFacilities() {
      this.$http.get(`venues/facilities/short?venue_service_id=${this.venue_service_id}&per_capacity=0&type=ground
      `)
        .then((response) => {
        if (response.status == 200 && response.data.status == true) {
          this.facilities = response.data.data;
          this.$forceUpdate();
        }
      })
      .catch((error) => {
        this.errorChecker(error);
      });
    },
  },
};
</script>
<style lang="scss" scoped>



.toggle-switch-label{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color:#112A46
}

$card-outlined-border-width: 3px;
.open-product {
  background-color: teal;
  cursor: pointer;
  height: 30px;
  width: 60px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  transition: "border-radius 200ms ease-in-out";
  border-radius: 4px;
  text-align: center;
}
</style>
