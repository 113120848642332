<template>
  <div>
  <v-dialog v-model="resheduleMultiDialog" scrollable max-width="700px" >
    <v-form ref="form" v-model="valid">
      <v-card >
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" :text="`Reschedule Booking${ids && ids.length > 1 ? 's' : '' }`" style="color: black"></SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="row row--dense mt-5 reschule-rec" v-for="(rescheduleForm,index) in rescheduleForms" :key="index">
            <v-col sm="12" lg="12" md="12" v-if="bookingDetails[index]">
              <p class="titles mb-0"
                v-if="bookingDetails[index].price != bookingDetails[index].old_price && !bookingDetails[index].is_trainer"
              >
                New Price : {{ bookingDetails[index].price | toCurrency }} |
                Original Price : {{ bookingDetails[index].old_price | toCurrency }}
                <span v-if="bookingDetails[index].balance">
                  | Excess Price : {{ bookingDetails[index].symbol }} {{ bookingDetails[index].balance | toCurrency }}
                </span>
              </p>
            </v-col>
            <v-col sm="12" md="12" lg="12">
              <div class="titles d-flex justify-space-between" v-if="bookingDetails[index] && !bookingDetails[index].is_trainer">
                <v-btn
                  x-small
                  outlined
                  v-if="productCombinations[index] && productCombinations[index].length > 1"
                  @click="chooseRentalCombination(index)"
                ><v-icon x-small>mdi-cart-arrow-right</v-icon> Rental combination</v-btn>
              </div>
              <div v-if="bookingDetails[index] && !bookingDetails[index].is_trainer">
                <v-chip
                  label
                  color="cyan"
                  dark
                  class="ml-2 mb-2"
                  v-for="(product, pIndex) in bookingDetails[index].products"
                  :key="pIndex"
                >
                  <v-avatar left>
                    <view-image :image="product.image_path"></view-image>
                  </v-avatar>
                  {{ product.name }} x
                  {{ product.quantity && typeof product.quantity == "number"? product.quantity.toFixed(2): product.quantity }}
                  -
                  {{ product.price.toFixed(2) | toCurrency }}
                  <span v-if="product.discount != null" class="text-decoration-line-through pl-1">
                    {{ product.discount.actual_price.toFixed(2) | toCurrency }}
                  </span>
                </v-chip>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col sm="12" md="12" lg="12">
              <v-row>
                <v-col  sm="6" md="6" lg="6">
                  <label for="">Booking Date</label>
                  <date-field
                    v-model="rescheduleForm.date"
                    @change="getRescheduleDetails(index)"
                    :rules="[(v) => !!v || 'Booking Date is required']"
                    :backFill="checkBackfillPermission($modules.memberships.management.slug)"
                    class-name="q-text-field shadow-0"
                    :hide-details="true"
                    :dense="true"
                    bg-color=""
                    label="">
                  </date-field>
                </v-col>
                <v-col  sm="6" md="6" lg="6">
                  <label for="">Facility</label>
                  <v-select
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    label=""
                    background-color="#fff"
                    item-text="name"
                    item-value="id"
                    :items="facilities[index]"
                    class="q-autocomplete shadow-0"
                    hide-details
                    dense
                    v-model="rescheduleForm.facility_id"
                    @change="getRescheduleDetails(index)"
                  >
                  </v-select>
                </v-col>
                <v-col sm="6" md="6" lg="6">
                  <label for="">Start Time*</label>
                  <v-select
                    class="q-autocomplete shadow-0"
                    outlined
                    hide-details
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                    label=""
                    background-color="#fff"
                    item-text="formatted"
                    item-value="time"
                    :items="times[index]"
                    v-model="rescheduleForm.start_time"
                    @change="changeStartTime(index)"

                  >
                  </v-select>
                </v-col>
                <v-col md="6" lg="6">
                  <label for="">End Time*</label>
                  <v-select
                    class="q-autocomplete shadow-0"
                    outlined
                    hide-details
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                    label=""
                    background-color="#fff"
                    item-text="formatted"
                    item-value="time"
                    :items="endTimes[index]"
                    v-model="rescheduleForm.end_time"
                    @change="changeEndTime(index)"
                  >
                  <template v-slot:item="{ item }">
                    <span v-html="item.formatted"></span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-html="item.formatted"></span>
                  </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 " text @click="close">Close</v-btn>
          <v-btn
            @click="callConfirmReschedule()"
            class="ma-2 white--text teal-color"
            text
          >Confirm Reschedule</v-btn>
        </v-card-actions>
      </v-card>
      <product-combination
        :productCombinations="productCombinations[currentRentalCombinationIndex]"
        :showCombinations="showCombinationDialog"
        @close="showCombinationDialog = false"
        @changeCombination="(emittedValue) => changeRentalProducts(currentRentalCombinationIndex, emittedValue)"
        :selectedCombinationPrice="selectedCombinationPrice[currentRentalCombinationIndex]"
      >
      </product-combination>
    </v-form>

  </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmReschedule"
        @close="confirmModel.id = null"
    >
      <template #content v-if="this.confirmModel.type === 'update' && typeof confirmModel.data.notify_customers !== 'undefined'">
        <v-row>
          <v-col cols="6">
            <v-switch
                class="mx-0 my-0"
                v-model="notify_customers"
                label="Notify Customers"
                dense
                hide-details="auto"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </confirm-model>
  </div>

</template>
<script>
import ProductCombination from "@/components/Schedule/Facility/ProductCombination";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import moment from "moment";
export default {
    components: {SvgIcon,ProductCombination},
    props:{
      venueServiceId: { type: Number },
      perCapacity: { type: Number, default: 0 },
      ids: {type: Array, default: null },
    },
    watch: {
      ids: {
        immediate: true,
        handler(val) {
          if (val) {
            this.resheduleMultiDialog = true;
            if(val.length){
                this.rescheduleForms = [];
                this.productCombinations = [];
                for (let index = 0; index < val.length; index++) {
                  this.facilities[index] = [];
                  this.rescheduleForms.push({
                    start_time: null,
                    end_time: null,
                    date: null,
                    facility_id: null,
                  });
                  this.getRescheduleDetails(index);
                }
            }

          } else {
           this.resheduleMultiDialog = false;
          }
        },
      },
    },
    mounted(){},
    computed: {
      venueServiceConfiguration() {
        return this.$store.getters.getConfigurationByVenueServiceId(
            this.venueServiceId
        );
      },
    },

    data() {
      return {
        valid: false,
        resheduleMultiDialog: false,
        currentRentalCombinationIndex: 0,
        rescheduleForms: [
          {
            start_time: null,
            end_time: null,
            date: null,
            facility_id: null,
          }
        ],
        facilities: [],
        times: [],
        endTimes: [],
        bookingDetails: [],
        bookingId: null,
        productCombinations: [],
        showCombinationDialog: false,
        selectedCombinationPrice: [0],
        orderId: null,
        minBookingTimes: [60],
        enableOvernightBooking:false,
        confirmModel: {
          id: null,
          title: null,
          description: null,
        },
        notify_customers:true
      };
    },
    methods: {
      close() {
        this.resheduleMultiDialog = false;
        this.notify_customers = true;
        this.$emit("close");
      },
      minutesOfDay(m) {
        return m.minutes() + m.hours() * 60;
      },
      checkEnableOvernightBooking(){
        if(this.venueServiceConfiguration.enable_over_night_booking){
          this.enableOvernightBooking = true;
        }else{
          this.enableOvernightBooking = false;
        }
      },
      getRescheduleDetails(index, timeChanged = false) {
        this.checkEnableOvernightBooking();
        if ((this.minutesOfDay(moment(this.rescheduleForms[index].start_time, "HH:mm:ss")) >= this.minutesOfDay(moment(this.rescheduleForms[index].end_time, "HH:mm:ss")))
            && !this.enableOvernightBooking
          ) {
            return;
          }
        this.showLoader("Loading");
        let url = `${this.rescheduleForms[index].start_time? "&start_time=" + this.rescheduleForms[index].start_time: ""}`;
            url += `${this.rescheduleForms[index].end_time? "&end_time=" + (this.rescheduleForms[index].end_time == "00:00:00"?"23:59:59":this.rescheduleForms[index].end_time): ""}`;
            url += `${this.rescheduleForms[index].date ? "&date=" + this.rescheduleForms[index].date : ""}`;
            url += `${this.rescheduleForms[index].facility_id? "&facility_id=" + this.rescheduleForms[index].facility_id: ""}`;
            url += `${this.enableOvernightBooking? "&enable_overnight_booking=" + (this.enableOvernightBooking ? 1:0): ""}`;
        this.$http.get(`venues/facilities/bookings/reschedule/${this.ids[index]}?venue_service_id=${this.venueServiceId}${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.bookingDetails[index] = {
                booking_id: data.id,
                total: data.order.total,
                price: data.order.price,
                tax_amount: data.order.tax_amount,
                old_price: data.order.price,
                is_trainer: data.is_trainer,
              };
              this.bookingDetails[index].products = [];
              let sum = 0;
              data.order.items.forEach((item) => {
                sum += item.price;
                let pdata = {
                  order_item_id: item.id,
                  product_id: item.product_id,
                  product_type_id: item.product_type_id,
                  category_id: item.category_id,
                  quantity: item.quantity,
                  price: item.price,
                  rental: item.rental != null,
                  venue_service_id: this.venue_service_id,
                  name: item.name ? item.name : "Product Name",
                  product_price: item.actual_price? item.actual_price: item.product_price,
                  tax: item.tax,
                  discounted_price:item.price
                };
                this.bookingDetails[index].products.push(pdata);
              });

              this.bookingDetails[index].old_price = sum;
              this.times[index] = data.times;
              this.minBookingTimes[index] = data.min_booking_time;
              this.endTimes[index] = this.endTimeSlots(data.start_time,data.times);
              if (!timeChanged) {
                this.rescheduleForms[index] = {
                  start_time: data.start_time,
                  end_time: data.end_time,
                  date: data.date,
                  facility_id: data.facility_id,
                };
              }
              if (typeof this.endTimes[index] != "undefined" && !this.enableOvernightBooking) {
                let expectedEndTime = moment(this.rescheduleForms[index].start_time,"HH:mm:ss").add(this.minBookingTimes[index], "minutes").format("HH:mm:ss");
                if(expectedEndTime == "00:00:00"){
                  expectedEndTime = "11:59:59";
                }
                this.endTimes[index] = this.endTimes[index].filter((item) => { return (item.time >= expectedEndTime); });
              }
              if (data.product_combinations.length) {
                this.productCombinations[index] = [...data.product_combinations];
                this.changeRentalProducts(index,this.productCombinations[index][0]);
              }else{
                this.productCombinations[index] = [];
              }
              if (this.facilities[index] && this.facilities[index].length == 0) {
                this.getFacilities(index);
              }
              this.hideLoader();
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      },
      getFacilities(index) {
        this.$http.get(`venues/facilities/short?venue_service_id=${this.venueServiceId}&date=${this.rescheduleForms[index].date}&per_capacity=${this.perCapacity}`)
          .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.facilities[index] = response.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      },
      changeStartTime(index) {
        this.endTimes[index] = this.times[index];
        let expectedEndTime = moment(this.rescheduleForms[index].start_time, "HH:mm:ss").add(this.minBookingTimes[0], "minutes").format("HH:mm:ss");
        this.endTimes[index] = this.endTimes[index].filter((item) => {
          return item.time >= expectedEndTime;
        });
        this.rescheduleForms[index].end_time = null;
        if (this.endTimes[index].length > 0) {
          this.rescheduleForms[index].end_time = this.endTimes[index][0].time;
        }

        this.getRescheduleDetails(index,true);
      },
      changeEndTime(index) {
        setTimeout(() => {
          this.getRescheduleDetails(index);
        }, 10);
      },
      endTimeSlots(start_time,et) {
        if(et){
          const timeSlotsFs = [];
          const timeSlotsSc = [];
          let currentTime = moment(start_time, 'HH:mm:ss');
          if(this.enableOvernightBooking){
            et.forEach( (ob) => {
              if(moment(ob.time,"HH:mm:ss").isBefore(currentTime)){
                timeSlotsSc.push({
                  formatted: moment(ob.time,"HH:mm:ss").format('hh:mm a') + '<span style="color: red">+1</span>',
                  time: ob.time,
                });
              }
            });
          }
          et.forEach( (ob) => {
            if(moment(ob.time,"HH:mm:ss").isAfter(currentTime)){
              timeSlotsFs.push({
                formatted: moment(ob.time,"HH:mm:ss").format('hh:mm a'),
                time: ob.time,
              });
            }
          })


          return timeSlotsFs.concat(timeSlotsSc);
        }
      },
      changeRentalProducts(index,rentalCombination) {
        let otherProducts = this.bookingDetails[index].products.filter(
          (item) => !item.rental
        );
        this.currentRentalCombinationIndex = index;
        this.selectedCombinationPrice[index] = rentalCombination.total_price;
        let rentals = [];
        rentalCombination.products.forEach((product) => {
          // if product have discount applied, then on duration change calculate for initial price correctly :: QP-2849
          const orderItem = this.bookingDetails[index].products.find(prod=>prod.product_id == product.id);
          let price = product.price;
          let newQuantity = product.quantity;
          let productPrice = product.product_price_when_overlapping?product.product_price_when_overlapping:product.product_price;

          if (orderItem && orderItem.price != product.price){
            price = orderItem.discounted_price?orderItem.discounted_price:product.product_price;
            if(!orderItem.actual_price){
              price = product.price;
            }
            newQuantity =  (product.quantity - orderItem.quantity);
            if (newQuantity>=0){
              product.price = price;//(productPrice*newQuantity)+price;
            } else {
              if(orderItem.discounted_price){
                product.price = ((orderItem.discounted_price/orderItem.quantity)*product.quantity);
              }else{
                product.price = productPrice * product.quantity;
              }
            }
          }

          rentals.push({
            product_id: product.id,
            product_type_id: product.product_type_id,
            price: product.price,
            name: product.name,
            tax: product.tax_amount,
            category_id: product.category_id,
            rental: true,
            product_price: product.product_price,
            quantity: product.quantity,
          });
        });

        if (otherProducts) {
          this.bookingDetails[index].products = [...rentals, ...otherProducts];
        } else {
          this.bookingDetails[index].products = rentals;
        }
        this.bookingDetails[index].price = this.bookingDetails[index].products.reduce((a, b) => a + parseFloat(b.price), 0 );
        if (this.bookingDetails[index].price < this.bookingDetails[index].old_price) {
          this.bookingDetails[index].icon = "mdi-arrow-down-thick";
          this.bookingDetails[index].color = "green darken-4";
          this.bookingDetails[index].symbol = "-";
          this.bookingDetails[index].balance = this.bookingDetails[index].old_price - this.bookingDetails[index].price;
        } else if (this.bookingDetails[index].price > this.bookingDetails[index].old_price) {
          this.bookingDetails[index].icon = "mdi-arrow-up-thick";
          this.bookingDetails[index].color = "error";
          this.bookingDetails[index].symbol = "+";
          this.bookingDetails[index].balance = this.bookingDetails[index].price - this.bookingDetails[index].old_price;
        }
        this.showCombinationDialog = false;
        this.$forceUpdate();
      },
      chooseRentalCombination(index) {
        this.currentRentalCombinationIndex = index;
        this.showCombinationDialog = true;
      },
      callConfirmReschedule(){
        console.log("calling")
        this.confirmModel = {
          id: 1,
          title: `Do you want reschedule this booking?`,
          description: `This will update the booking. By clicking <b>Yes</b> you can confirm reschedule operation`,
          type: "update",
          data:{
            id:this.order_id,
            notify_customers:false
          }
        }
      },
      confirmReschedule() {
        this.confirmModel.id = null;
        this.showLoader();
        let isValidationFailed = false;
        let productNullFlag = false;
        const formData = [];
        /** validate data */
        let rfLength = this.rescheduleForms.length;
        this.rescheduleForms.forEach((rescheduleForm, index) => {
          if (!rescheduleForm.end_time) {
              this.hideLoader();
              this.showError("Please fill all fields");
              isValidationFailed = true;
              return;
          }

          const start_time = moment(rescheduleForm.start_time, "HH:mm:ss");
          const end_time = moment(rescheduleForm.end_time, "HH:mm:ss");
          let checkMinBookingTime = end_time.diff(start_time, "minute");
          if (checkMinBookingTime % 5 === 4) {
              checkMinBookingTime += 1;
          }
          if (checkMinBookingTime > 0 && checkMinBookingTime < this.minBookingTimes[index]) {
              this.hideLoader();
              this.showError("Minimum booking time is not matching with the selected time");
              isValidationFailed = true;
              return;
          }

          if (!start_time.isBefore(end_time) && !this.enableOvernightBooking) {
              this.hideLoader();
              this.showError("The end time must be a date after start time.");
              isValidationFailed = true;
              return;
          }
          const isPastTime = moment(rescheduleForm.start_time, "HH:mm:ss").isSameOrBefore();
          const isPastDate = moment(rescheduleForm.date, "YYYY-MM-DD").isSameOrBefore();
          if (isPastTime && isPastDate) {
              this.hideLoader();
              this.showError("You cannot reschedule to a past time.");
              isValidationFailed = true;
              return;
          }
          /**  validate overlap timing */
          let res = this.validateOverlapTiming(index,rfLength,{...rescheduleForm});
          if(res){
            isValidationFailed = true;
          }
        });
        if (isValidationFailed) {
          this.hideLoader();
          return; // Stop execution if validation failed
        }
        this.bookingDetails.forEach((bookingDetails, pIndex) => {
          if(this.rescheduleForms[pIndex] && this.rescheduleForms[pIndex].facility_id){
            const formItem = {
                booking_id: bookingDetails.booking_id,
                enable_overnight_booking: this.enableOvernightBooking ? 1 : 0,
                facility_id: this.rescheduleForms[pIndex].facility_id,
                start_time: this.rescheduleForms[pIndex].start_time,
                end_time: this.rescheduleForms[pIndex].end_time,
                venue_service_id: this.venueServiceId,
                date: this.rescheduleForms[pIndex].date,
                products: [],
                notify_customers:this.notify_customers
            };

            bookingDetails.products.forEach((product) => {
                if (product.product_id) {
                    formItem.products.push({
                        product_id: product.product_id,
                        quantity: typeof product.quantity === "number" ? parseFloat(product.quantity.toFixed(2)) : parseFloat(product.quantity)
                    });
                } else {
                    productNullFlag = true;
                }
            });

            formData.push(formItem);
          }
        });
        if (productNullFlag) {
          this.hideLoader();
          this.showError("Please select different time");
          return;
        }
        this.$http.post(`venues/facilities/bookings/reschedule-multi`, formData).then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.close();
            let data = response.data.data;
            this.close();
            if (data && data.reschedule && data.reschedule.price_status == "increase") {
              this.orderId = data.id;
            } else if (data && data.reschedule && data.reschedule.price_status == "decrease") {
              this.$emit("refund", data.id);
            } else if(data && data.id){
              this.$emit("booked", data.id);
            }else{
              this.showSuccess(response.data.message? response.data.message: "Booking reschedule successfully");
              this.$emit("booked");
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
      },
      validateOverlapTiming(index,rfLength,rsFormObj){
         // Check if the start time and end time overlap with other entries
        const startTime = moment(rsFormObj.start_time, "HH:mm:ss");
        const endTime = moment(rsFormObj.end_time, "HH:mm:ss");
        let isOverLap = false;
        for (let i = 0; i < rfLength; i++) {
          if (i !== index) {
            const otherForm = this.rescheduleForms[i];
            const otherStartTime = moment(otherForm.start_time, "HH:mm:ss");
            const otherEndTime = moment(otherForm.end_time, "HH:mm:ss");

            // Check if the start time or end time falls within the range of another form
           if (
                rsFormObj.date === otherForm.date &&
                rsFormObj.facility_id === otherForm.facility_id &&
                (
                  (startTime.isSameOrAfter(otherStartTime) && startTime.isBefore(otherEndTime)) ||
                  (endTime.isAfter(otherStartTime) && endTime.isSameOrBefore(otherEndTime))
                )
              ) {
                this.showError("Start time or End time is overlaping");
                isOverLap = true;
                return isOverLap;
            }
          }
        }
        return isOverLap;
      },
    },
  }
</script>
<style scoped>
.row.reschule-rec {
    border: 1px solid #ccc;
    padding: 10px;
}
</style>