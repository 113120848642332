<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="modal-heading">Leaderboard</span>
      </v-card-title>
      <v-divider></v-divider>
        <LeaderboardItems v-for="(stat,index) in stats"  :user="stat" :key="index" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close">Close</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LeaderboardItems from "@/views/Schedule/Facility/LeaderboardItems.vue";

export default {
  name: "LeaderBoardModal",
  components: {LeaderboardItems},
  props: {
    raceId: { type: Number, default: null },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.getRaceLeaderBoard(this.raceId);
      }
    },
  },
  data(){
    return {
      stats:[],
    }
  },
  methods: {
    close(){
      this.$emit("close");
    },
    getRaceLeaderBoard(race_id){
      this.$http.get(`venues/facilities/bookings/race/get-leaderboard/${race_id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if(data.length > 0) {
                this.stats = data;
              }else{
                this.showError("Race data not found");
                this.close();
              }
            }
          }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      })
    },
  }
}
</script>


<style scoped>

</style>