<template>
  <v-autocomplete
    :value="member"
    :items="[{ card_number: 'None' }, ...entries]"
    :loading="isSearchLoading"
    :search-input.sync="search"
    item-text="card_number"
    item-value="card_number"
    :label="showLabel?label:''"
    placeholder="Membership number.."
    @change="changeCustomerData"
    @blur="entryShif"
    auto-select-first
    return-object
    outlined
    dense
    autocomplete="cc-csc"
    background-color="#fff"
    :hide-details="hideDetails"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        v-if="item.profile_image"
        rounded
        color="teal"
        class="text-h5 font-weight-light white--text"
      >
        <view-image :image="item.profile_image"></view-image>
      </v-list-item-avatar>
      <v-list-item-avatar
        v-else-if="item.first_name"
        rounded
        color="teal"
        class="text-h5 font-weight-light white--text"
      >
        {{ item.first_name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.card_number }}</v-list-item-title>
        <v-list-item-subtitle
          >{{ item.first_name }} {{ item.last_name }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: Object },
    selected: { type: String },
    label: { type: String, default: "Card No*" },
    showLabel: { type: Boolean, default: true },
    hideDetails: { type: String, default: '' },
    venueId:{ type: Number, default: null },
  },
  data() {
    return {
      isSearchLoading: false,
      memberDoesNotExist: false,
      entries: [],
      search: null,
      member: { card_number: null },
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (
          val == "" ||
          val == null ||
          (this.value != null && this.value.card_number == val)
        )
          return;
        let data = { card_number: val };
        this.entries = [data];
        if (val !== "" && val !== null) {
          this.member.card_number = val;
        }
        if (this.value) {
          this.member = this.value;
        }
        this.$emit("input", data);
      },
    },
    search(val) {
      // this.searchHelper(val);
      if(val && val.length >= 3){
        this.searchHelper(val);
      }
    },
  },
  methods: {
    searchHelper(val) {
      if (
        val == null ||
        val == "" ||
        val.length == 0 ||
        (this.value != null && this.value.card_number == val)
      ) {
        this.memberDoesNotExist = false;
        return;
      }
      // if (this.isSearchLoading || this.memberDoesNotExist) return;
      if (this.memberDoesNotExist) return;
      this.searchMember(val);
    },
    searchMember(card_number) {
      let venue = "";
      if(this.venueId){
        venue += "&venueId="+this.venueId; 
      }
      this.isSearchLoading = true;
      this.$http
        .get(
          `venues/memberships/members/filters?field=display_number&search=${card_number}${venue}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.isSearchLoading = false;
            let data = response.data.data;
            if (data.length == 0) {
              this.memberDoesNotExist = true;
            }
            this.entries = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    entryShif() {
      if (this.entries.length == 1 && this.entries[0].name == "") {
        this.changeCustomerData(this.entries[0]);
      }
    },
    changeCustomerData(data) {
      if (!data) {
        return;
      }
      let customer = {};
      if (data.first_name) {
        customer.name = `${data.first_name} ${
          data.last_name ? data.last_name : ""
        }`;
        customer.mobile = data.mobile;
        customer.email = data.email;
        customer.country_id = data.country_id;
        customer.gender = data.gender;
        customer.dob = data.dob;
        customer.last_name = data.last_name;
        customer.first_name = data.first_name;
        customer.image_path = data.profile_image;
        customer.card_number = data.card_number;
        customer.membership_id = data.membership_id;
        customer.customer_id = data.id;
        this.$emit("updateCustomer", customer);
      }
      if (data.card_number == "None") {
        this.$emit("clear");
      }
      this.$emit("input", data);
    },
  },
};
</script>

<style></style>
