<template>
  <div>
    <v-dialog
      persistent
      v-model="orderDialog"
      :max-width="'60%'"
      @input="closeOrderDetails"
      scrollable
    >
      <v-card tile class="payment-model">
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="7">
              <v-card outlined>
                <v-card-title
                  ><span class="text_line">PAYMENT METHOD</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined tile>
                        <v-btn
                          block
                          style="background-color:#F2F2F2;"
                          class="fw-500 fs-14"
                        >
                          Total {{ paymentTotal | toCurrency }} /
                          {{ totalAmount | toCurrency }}
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="12" md="12">
                            <template v-for="(method, index) in payments">
                              <payment-method
                                v-bind="method"
                                :index="index"
                                :wallet="wallet"
                                :totalAmountFromRepeat="totalAmount"
                                :key="`p_${index}`"
                                :updateCommitType="'updateRepeatPaymentMethod'"
                                @remove="removePaymentMethod"
                              ></payment-method>
                            </template>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-blue text-underline"
                    text
                    @click="addNewPaymentMethod"
                    :prefix="currencyCode"
                  >
                    + Add New Method
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div class="bottom-fix" style="margin-top:30px;">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      dark
                      class="yellow-color"
                      absolute
                      bottom
                      @click="
                        $emit('close'), clearPaymentMethod(), cancelRedeem()
                      "
                      >Settle later</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col md="5">
              <v-card outlined class="order-summary">
                <v-card-title class="fw-700 heading-title">
                  <span class="text_line">ORDER SUMMARY</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-2">
                  <v-list
                    three-line
                    style="min-height: 100px;"
                    class="overflow-y-auto"
                  >
                    <v-list-item
                      v-for="(product, i) in products"
                      :key="i"
                      ripple
                      @click="() => {}"
                    >
                      <v-list-item-avatar title height="64" width="64">
                        <view-image :image="product.image"></view-image>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="text-body">{{
                          product.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <div
                            class="d-flex justify-space-between text-caption"
                          >
                            <div class="font-weight-bold">
                              {{ product.product_price }}
                              x {{ product.quantity }}
                            </div>
                            <div class="font-weight-bold">
                              Tax
                              {{
                                (product.discount? product.discount.actual_tax: (product.rental?(product.tax * product.quantity):product.tax))| toCurrency
                              }}
                            </div>
                            <div class="font-weight-bold">
                              {{ currencyCode }}
                              {{ product.discount? product.discount.actual_total:(product.rental? 
                                    (
                                      product.price +
                                      product.tax * product.quantity
                                    ): product.price + product.tax).toFixed(2)
                              }}
                            </div>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="orderDetails.discount"
                      ripple
                      :key="`o_${orderDetails.id}`"
                      @click="() => {}"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body product-name green--text"
                          v-if="orderDetails.discount"
                        >
                          {{ "Discount" }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div
                            class="d-flex justify-space-between text-caption"
                          >
                            <div class="font-weight-bold">
                              <span class="text-decoration-line-through">{{
                                orderDetails.price | toCurrency
                              }}</span>
                              {{
                                (orderDetails.price -
                                  orderDetails.actual_price)
                                  | toCurrency
                              }}
                            </div>
                            <div class="font-weight-bold">
                              Tax
                              <span class="text-decoration-line-through">{{
                                orderDetails.tax | toCurrency
                              }}</span>
                              {{
                                (orderDetails.tax - orderDetails.actual_tax)
                                  | toCurrency
                              }}
                            </div>
                            <div class="font-weight-bold">
                              <span class="text-decoration-line-through">
                                {{ orderDetails.total | toCurrency }}
                              </span>
                              {{
                                (orderDetails.total -
                                  orderDetails.actual_total)
                                  | toCurrency
                              }}
                            </div>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                </v-card-text>

                <div class="cart-text-bottom">
                  <v-row>
                    <v-col cols="6" class="smaller-col">
                      <div class="subheading">Sub Total</div>
                    </v-col>
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{ orderDetails.price | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="smaller-col">
                      <div class="subheading">Tax Amount</div>
                    </v-col>
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{ orderDetails.tax | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pb-2 mb-2">
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading fw-700">Grand Total</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        <span
                          class="text-decoration-line-through pt-1"
                          v-if="orderDetails.discount"
                        >
                          {{ orderDetails.total | toCurrency }}
                        </span>
                        {{
                          orderDetails.discount
                            ? orderDetails.total - orderDetails.actual_total
                            : orderDetails.total | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      !orderDetails.promotion_code &&
                        !payments.find(
                          (x) => x.payment_method == 'Complementary'
                        )
                    "
                  >
                    <v-col md="6" class="smaller-col">
                      <v-text-field
                        class="placeholder-small"
                        v-model="discountAmount"
                        label="Special discount"
                        outlined
                        :prefix="currencyCode"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                            }
                            return true;
                          },
                        ]"
                        @keyup="specialDiscountChange('amount')"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" class="smaller-col">
                      <v-text-field
                        class="placeholder-small"
                        v-model="discountPercentage"
                        suffix="%"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                              if (v > 100) {
                                return 'Percentage should be less than 100';
                              }
                            }
                            return true;
                          },
                        ]"
                        label="Percentage"
                        outlined
                        @keyup="specialDiscountChange('percentage')"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-textarea
                        v-model="paymentNote"
                        rows="1"
                        label="Payment note"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-row dense class="pa-2 mb-2">
                    <v-col cols="12" style="padding-bottom:24px;">
                      <v-select
                        v-if="payerCustomerList"
                        :items="payerCustomerList"
                        v-model="payer"
                        item-value="customerId"
                        item-text="customerName"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        return-object
                        hide-details="auto"
                        label="Payer"
                        dense
                        :rules="[(v) => !!v || 'Payer is required']"
                        required
                        @change="changePayer()"
                      ></v-select>
                    </v-col>
                     <v-col cols="12" v-if="payer && wallet && wallet.cash > 0" style="padding-bottom:24px;">
                        <v-btn class="fw-400 fs-14" style="font-size: 13px; text-transform: capitalize;padding:6px;" block>
                          <v-icon small left>mdi-wallet</v-icon>Cash:{{ wallet.cash | toCurrency }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="payer && wallet && wallet.points > 0" style="padding-bottom:24px;">
                        <v-btn class="fw-400 fs-14" style="font-size: 13px; text-transform: capitalize;padding:6px;" block>
                          <v-icon small left>mdi-gift</v-icon>Total QPoints:{{ wallet.points }} | {{ wallet.points_val | toCurrency }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        @click="payRepeatBooking"
                        block
                        class="block white--text blue-color large"
                        :disabled="!payer"
                        >PAY</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
        ></confirm-model>
        <customer-product-wallet
          v-bind="walletModel"
          @refreshWallet="refreshWallet"
          @close="
            (walletModel.products = []),
              (walletModel.showModal = false),
              (walletModel.order_id = null)
          "
        ></customer-product-wallet>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentMethod from "./PaymentMethod.vue";
import CustomerProductWallet from "./CustomerProductWallet.vue";
export default {
  components: {
    "payment-method": PaymentMethod,
    "customer-product-wallet": CustomerProductWallet,
  },
  props: {
    show: { Type: Boolean, default: null },
    products: { Type: Array, default: null },
    repeatBookingIds: { Type: Array, default: null },
    mobile: { Type: String, default: null },
    promotion_code: { Type: String, default: null },
    card_number: { Type: String, default: null },
    wallet: { type: Object, default: () => {} },
    orderId: { type: Number, default: null },
    payerCustomerList: { Type: Array, default: null },
  },
  data() {
    return {
      balance: 0,
      confirmModel: {},
      paymentNote: null,
      discountAmount: null,
      prevdiscountAmount: null,
      discountPercentage: null,
      orderDialog: false,
      orderDetails: {},
      totalAmount: 0,
      totalAmountBeforeDiscount: 0,
      walletModel: {
        showModal: false,
        products: [],
        order_id: null,
      },
      isEditing: true,
      payer: null,
      loadingProducts: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val == true) {
          this.orderDialog = true;
          this.addNewPaymentMethod();
        } else {
          this.orderDialog = false;
          this.clearPaymentMethod();
        }
      },
    },
    products: {
      immediate: true,
      handler(val) {
        if (val) {
          let total_price = 0;
          let total_tax = 0;

          let total_actual_price = 0;
          let total_actual_tax = 0;

          total_price = this.products.reduce(
            (a, b) =>
              a + parseFloat(b.discount ? b.discount.actual_price : b.price),
            0
          );
          total_tax = this.products.reduce(
            (a, b) =>
              a +
              parseFloat(
                b.discount
                  ? b.discount.actual_tax
                  : b.rental == false && !b.total_tax_amount
                  ? b.tax
                    ? b.tax
                    : 0
                  : b.total_tax_amount
                  ? b.total_tax_amount
                  : 0
              ),
            0
          );

          this.products.forEach((product) => {
            if (product.discount) {
              this.orderDetails.promotion_code = true;
              this.orderDetails.discount = true;
              total_actual_price +=
                product.discount.actual_price - product.price;
              total_actual_tax += product.discount.actual_tax - product.tax;
            }
          });

          this.totalAmount = (total_price + total_tax).toFixed(2);
          this.totalAmount = parseFloat(this.totalAmount);

          this.totalAmountBeforeDiscount = total_price + total_tax;
          this.orderDetails.price = total_price;
          this.orderDetails.tax = total_tax;
          this.orderDetails.total = total_price + total_tax;

          this.orderDetails.actual_price = total_actual_price;
          this.orderDetails.actual_tax = total_actual_tax;
          this.orderDetails.actual_total =
            total_actual_price + total_actual_tax;

          if (this.orderDetails.actual_total) {
            this.totalAmount -= this.orderDetails.actual_total;
            this.totalAmount = parseFloat(this.totalAmount.toFixed(2));
            this.totalAmountBeforeDiscount -= this.orderDetails.actual_total;
          }
          this.$forceUpdate();
        }
      },
    },
    repeatBookingIds: {
      immediate: true,
      handler(val) {
        if (val) {
          console.log(this.repeatBookingIds);
        }
      },
    },
  },
  mounted() {
    //window.addEventListener("beforeunload", this.stopRefresh);
    if (this.payerCustomerList && this.payerCustomerList[0]) {
      this.payer = this.payerCustomerList[0];
      this.changePayer();
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.stopRefresh);
  },
  computed: {
    payments() {
      return this.$store.getters.getRepeatPayments;
    },

    paymentTotal() {
      return this.$store.getters.getRepeatPaymentTotal;
    },
  },
  methods: {
    stopRefresh(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
      this.cancelRedeem();
    },
    refreshWallet() {
      this.$emit("refreshWallet");
    },
    showWalletProducts() {
      this.showError("Cannot redeem products partially");
      return;

      // this.showLoader("Loading...");
      // let data = {
      //   order_id: this.orderId,
      // };

      // this.$http
      //   .post(`venues/customers/products-wallet/get-customer-order-all`, data)
      //   .then((response) => {
      //     if (response.status == 200 && response.data.status) {
      //       this.hideLoader();
      //       let data = response.data.data;
      //       this.walletModel.products = [];
      //       data.forEach((d) => {
      //         this.walletModel.products.push({
      //           id: d.id,
      //           name: d.product.name,
      //           quantity: d.quantity,
      //           type: d.product.product_type,
      //           status: d.status_id,
      //         });
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.hideLoader();
      //     this.errorChecker(error);
      //   });
      // this.walletModel.order_id = this.orderId;
      // this.walletModel.showModal = true;
    },
    addNewPaymentMethod() {
      this.$store
        .dispatch("addNewRepeatPaymentMethod")
        .then(() => {})
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    cancelRedeem() {
      if (this.orderId) {
        this.$http
          .get(`venues/customers/products-wallet/cancel-redeem/${this.orderId}`)
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },

    clearPaymentMethod() {
      this.$store
        .dispatch("resetPaymentMethod")
        .then(() => {})
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },

    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removeRepeatPaymentMethod", data.data.index);
        });
      }
      this.confirmModel.id = null;
    },

    closeOrderDetails() {
      this.$emit("close");
    },

    specialDiscountChange(type) {
      this.orderDetails.discount = false;
      this.orderDetails.actual_price = null;
      this.orderDetails.actual_tax = null;
      this.orderDetails.actual_total = null;
      this.totalAmount = this.totalAmountBeforeDiscount;

      if (type == "percentage") {
        if (isNaN(this.discountPercentage)) {
          return;
        }

        if (this.discountPercentage < 0) {
          this.discountPercentage = 0;
        }
        if (this.discountPercentage >= 100) {
          this.discountPercentage = 100;
          this.discountAmount = 0;
        } else {
          this.discountAmount = (
            this.totalAmountBeforeDiscount -
            (this.totalAmountBeforeDiscount / 100) * this.discountPercentage
          ).toFixed(2);
        }

        let taxPercentage =
          (this.orderDetails.tax / 100) * this.discountPercentage;

        if (this.discountAmount != this.totalAmount) {
          let actual_price = this.discountAmount - taxPercentage;
          this.orderDetails.discount = true;
          this.orderDetails.actual_price =
            this.orderDetails.price - actual_price;
          this.orderDetails.actual_tax = taxPercentage;
          this.orderDetails.actual_total =
            this.orderDetails.total - this.discountAmount;
          this.totalAmount = parseFloat(this.discountAmount);
        } else {
          this.discountAmount = null;
        }
        this.$forceUpdate();
      } else if (type == "amount") {
        this.discountPercentage = null;
        let discountPercentage = null;
        if (this.discountAmount == "" || this.discountAmount == null) {
          this.discountAmount = null;
          discountPercentage = null;
          return;
        }
        if (isNaN(this.discountAmount)) {
          this.discountAmount = this.totalAmountBeforeDiscount;
          discountPercentage = 100;
        }
        if (this.discountAmount == 0) {
          discountPercentage = 100;
        } else if (this.discountAmount == this.totalAmountBeforeDiscount) {
          discountPercentage = 0;
        } else {
          discountPercentage = (
            (this.discountAmount / this.totalAmountBeforeDiscount) *
            100
          ).toFixed(2);
        }
        let taxPercentage = (this.orderDetails.tax / 100) * discountPercentage;
        if (this.discountAmount != this.totalAmount) {
          let actual_price = this.discountAmount - taxPercentage;
          this.orderDetails.discount = true;
          this.orderDetails.actual_price =
            this.orderDetails.price - actual_price;
          this.orderDetails.actual_tax = this.orderDetails.tax - taxPercentage;
          this.orderDetails.actual_total =
            this.orderDetails.total - this.discountAmount;
          this.totalAmount = parseFloat(this.discountAmount);
        }
        this.$forceUpdate();
      }
    },

    payRepeatBooking() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all fields");
        return;
      }
      this.showLoader("Payment processing....");

      let totalPayment = this.payments.reduce(
        (a, b) => a + parseFloat(b.amount),
        0
      );
      if (this.totalAmount > totalPayment || this.totalAmount < totalPayment) {
        this.showError("Payment amount not matching with order amount!");
        return 0;
      }

      let bookingIds = [];
      this.repeatBookingIds.forEach((element) => {
        bookingIds.push(element.booking_id);
      });
      let data = {
        payments: this.payments,
        booking_ids: bookingIds,
        products: this.products,
      };

      if (this.mobile) {
        data.mobile = this.mobile;
      }
      if (this.card_number) {
        data.card_number = this.card_number;
      }

      if (this.promotion_code) {
        data.promotion_code = this.promotion_code;
      }

      if (this.discountAmount) {
        data.discount = parseFloat(this.discountAmount);
      }
      if (this.paymentNote) {
        data.notes = this.paymentNote;
      }
      if (this.payer && this.payer.customerId) {
        data.customer_id = this.payer.customerId;
        data.mobile = this.payer.mobile;
      }

      this.$http
        .post("venues/facilities/bookings/repeat/payments", data)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.showSuccess("Payment success");
            this.clearPaymentMethod();
            this.$emit("payed");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changePayer() {
      this.orderDetails.customerId = this.payer.customerId;
      this.orderDetails.customerName = this.payer.customerName;
      // this.wallet.cash = this.payer.cashWallet;
      console.log(this.wallet);
      console.log("this.wallet")
      let data = {
        id: this.payer.customerId,
        name: this.payer.customerName,
        wallet: this.payer.cashWallet,
        mobile: this.payer.mobile,
        email: this.payer.email,
      };
      this.loadProductWallet();
      this.$store.dispatch("setCustomer", data);
    },
    loadProductWallet() {
      this.wallet.cash = 0;
      this.wallet.products = 0;
      this.wallet.points = 0;
      this.wallet.points_val = 0;
      this.loadingProducts = true;
      let data = null;
      data = {
        customer_id: this.payer.customerId,
      };

      this.$http
        .post(`venues/customers/products-wallet/get-customer-all`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data.products;
            this.wallet.products = data;
            this.loadingProducts = false;
            this.wallet.cash = response.data.cash;
            this.wallet.points = response.data.points;
            this.wallet.points_val = response.data.points_val;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
          this.loadingProducts = false;
        });
    },
  },
};
</script>

<style scoped>
.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}
.fw-700 {
  font-weight: 700;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.price_div {
  width: 100%;
  background: #f2f2f2;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 0.5px solid black;
}
.order-summary {
  background-color: rgba(17, 42, 69, 0.03);
}
.placeholder-small ::v-deep label {
  font-weight: 400;
  font-size: 12px;
}
.placeholder-small ::v-deep .v-text-field__prefix,
.placeholder-small ::v-deep .v-text-field__suffix {
  font-size: 14px;
}
.heading-title {
  font-size: 16px;
}
.order-summary button.v-expansion-panel-header.tab-heading {
  color: #112a45;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 12px;
  min-height: 44px;
}
.order-summary .v-list-item__title.product-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #112a45;
}
.order-summary .v-expansion-panel-content.tab-content .v-list-item {
  padding: 0px;
  min-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.order-summary .v-expansion-panel-content.tab-content .v-list-item:last-child {
  border-bottom: 0px;
}
.order-summary .v-list-item__content {
  max-height: 65px;
}

.order-summary
  .v-expansion-panel-content.tab-content
  ::v-deep
  .v-expansion-panel-content__wrap {
  padding: 0px 12px;
}
.order-summary .smaller-col {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.order-summary .cart-text-bottom {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
