var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{class:("d-flex justify-center align-center cursorstyle " + _vm.status + " " + (hover && _vm.status != 'not_available' ? 'hoverstyle' : '')),attrs:{"block":"","height":_vm.height,"elevation":hover && _vm.status != 'not_available' ? 12 : 0},on:{"mouseover":_vm.highlight,"mouseout":_vm.removeHighlight}},[(
        _vm.status == 'maintenance' || _vm.status == 'event' || _vm.status == 'dependency'
      )?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.name)}})]):(_vm.status != 'not_available')?_c('v-row',{attrs:{"justify":"center"}},[(
          _vm.isEnablePerDayCapacity && !_vm.splitCapacity
            ? _vm.perDayCapacity > (_vm.totalAttendance+_vm.reservedQuantity) && !_vm.isPast
            : (_vm.bookings + _vm.reservedQuantity) < _vm.capacity && !_vm.isPast && !_vm.splitCapacity && _vm.checkWritePermission(_vm.$modules.schedule.management.slug)
        )?_c('v-col',{attrs:{"md":"4","sm":"6"},on:{"click":function($event){return _vm.onClick('add')}}},[_c('v-btn',{attrs:{"color":"teal","dark":"","fab":"","x-small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1):_vm._e(),((_vm.bookings + _vm.reservedQuantity) > _vm.capacity && _vm.isPast)?_c('v-col',{attrs:{"md":"4","sm":"6"}}):_vm._e(),(_vm.status == 'unpaid')?_c('v-col',{staticClass:"pr-8 mx-auto",class:{ status: _vm.status },attrs:{"md":_vm.isEnablePerDayCapacity &&
          _vm.totalAttendance >= _vm.perDayCapacity &&
          !_vm.isPast
            ? 12
            : (_vm.bookings + _vm.reservedQuantity) < _vm.capacity && !_vm.isPast && !_vm.splitCapacity
            ? 8
            : 12,"sm":(_vm.bookings + _vm.reservedQuantity) < _vm.capacity ? 6 : 12},on:{"click":function($event){return _vm.onClick('participant')}}},[_c('v-btn',{attrs:{"color":"white","x-small":"","dark":"","outlined":"","block":""}},[_vm._v(_vm._s((_vm.bookings + _vm.reservedQuantity))+" "),(!_vm.isEnablePerDayCapacity)?_c('span',[_vm._v("/"+_vm._s(_vm.capacity))]):_vm._e()])],1):_c('v-col',{staticClass:"pr-8",class:{ status: _vm.status },attrs:{"md":_vm.isEnablePerDayCapacity &&
          _vm.totalAttendance >= _vm.perDayCapacity &&
          !_vm.isPast
            ? 12
            : (_vm.bookings + _vm.reservedQuantity) < _vm.capacity && !_vm.isPast
            ? 8
            : 12,"sm":(_vm.bookings + _vm.reservedQuantity) < _vm.capacity ? 6 : 12},on:{"click":function($event){return _vm.onClick('participant')}}},[_c('v-btn',{attrs:{"color":"teal","x-small":"","dark":"","outlined":"","block":""}},[_vm._v(_vm._s((_vm.bookings + _vm.reservedQuantity))),(!_vm.isEnablePerDayCapacity)?_c('span',[_vm._v("/"+_vm._s(_vm.capacity))]):_vm._e()])],1)],1):(_vm.status == 'not_available')?_c('v-row',[_c('v-col',{staticClass:"text-center text-white",attrs:{"md":"12"}},[_c('span',[_vm._v(_vm._s(_vm.name))])])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }