<template>
  <v-dialog v-model="capture_image_dialoge" width="50%" @input="close()">
    <v-card>
      <v-card-title class="headline">
        <v-row no-gutters
          >Capture Image
          <v-spacer></v-spacer>
          <v-col md="3" v-if="devices.length > 1">
            <v-select
              v-model="camera"
              :items="devices"
              dense
              solo
              item-text="label"
              item-value="deviceId"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-show="image">
          <v-col>
            <v-img :src="image" />
          </v-col>
        </v-row>
        <v-row v-show="image == null">
          <v-col md="12">
            <vue-web-cam
              ref="webcam"
              :device-id="deviceId"
              width="100%"
              @started="onStarted"
              @stopped="onStopped"
              @error="onError"
              @cameras="onCameras"
              @camera-change="onCameraChange"
            />
            <h3 class="text-center" v-if="!started">
              Camera Stopped. Click start to open camera again
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            v-if="image == null && started"
            fab
            dark
            color="teal"
            class="mr-2"
            @click="onCapture"
          >
            <v-icon>mdi-camera-iris</v-icon>
          </v-btn>
          <v-btn
            v-else-if="started"
            fab
            dark
            color="teal"
            class="mr-2"
            @click="reCapture"
          >
            <v-icon>mdi-camera-retake</v-icon>
          </v-btn>
          <v-btn
            v-if="started && image == null"
            color="error"
            fab
            @click="onStop"
            ><v-icon dark>mdi-camera-off</v-icon></v-btn
          >
          <v-btn v-if="!started" fab color="success" @click="onStart"
            ><v-icon dark>mdi-camera</v-icon></v-btn
          >
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2 white--text blue-color" text @click="close"
          >Close</v-btn
        >
        <v-btn
          v-if="img"
          class="ma-2 white--text teal-color"
          text
          @click="confirm"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { WebCam } from "vue-web-cam";
export default {
  components: {
    "vue-web-cam": WebCam,
  },
  props: {
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      image: null,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      capture_image_dialoge: false,
      started: false,
    };
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      console.log(tail);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
    open(val) {
      if (val == true) {
        this.capture_image_dialoge = true;
        this.image = null;
      }
    },
  },
  methods: {
    onCapture() {
      this.image = this.$refs.webcam.capture();
      fetch(this.image)
        .then((res) => res.blob())
        .then((img) => (this.img = img));
    },
    onStarted(stream) {
      this.started = stream.active;
    },
    onStopped(stream) {
      this.started = stream.active;
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    reCapture() {
      this.image = null;
      this.$forceUpdate();
      setTimeout(this.onStart(), 1000);
    },
    onError(error) {
      this.showError(error);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    close() {
      this.capture_image_dialoge = false;
      this.$emit("close");
      this.onStop();
    },
    confirm() {
      if (this.img == null) {
        this.showError("No image capture");
        return;
      }
      this.capture_image_dialoge = false;
      this.$emit("confirm", this.img);
      this.onStop();
    },
  },
};
</script>
