<template>
  <v-dialog scrollable :value="showParticipants" max-width="500" @input="close">
    <v-card>
      <v-toolbar color="teal" dark height="80">
        <v-toolbar-title>
          <v-list-item-content>
            <v-list-item-title class="text-h5">
                Tickets ({{tickets.length}})
                <div class="right-cb" style="float:left;padding-left: 25px;">
                  <v-checkbox
                    style="margin-top: 0px"
                    v-model="cbAll"
                    color="#66c8c8"
                    hide-details
                    @change="selectAllTickets(cbAll)"
                  ></v-checkbox></div>
            </v-list-item-title>
          </v-list-item-content>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-list subheader v-if="tickets.length > 0">
          <div
            v-for="(ticket, index) in tickets"
            :key="index"
            class="parent-list-items border-show"
          >
            <v-list-item style="padding: 0px">
              <v-list-item-content>
                <v-list-item-title class="ml-2">
                    <div class="ticket-row">
                        <div class="right"><v-checkbox
                                style="margin-top: 0px"
                                v-model="ticket.isSettle"
                                color="#66c8c8"
                                :value="ticket.isSettle"
                                @change="checkedTicket(ticket.id,ticket.isSettle)"
                                hide-details
                              ></v-checkbox></div>
                        <div class="left">
                            <span>
                              {{ ticket.customer?ticket.customer.first_name:'' }} 
                              {{ ticket.customer.last_name?' '+ticket.customer.last_name:'' }}
                            </span>
                            <span class="cdisplayBlock">{{ ticket.ticket_code }}</span>
                        </div>
                    </div>
                    </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-btn
                  class="m1-2 white--text teal-color"
                  normal
                  @click="printPdf('pos',ticket.id)"
                ><span>Print POS</span>
                </v-btn>
            </v-list-item-action>
            <v-list-item-action>
                <v-btn
                    class="ma-1 white--text teal-color"
                    normal
                    @click="printPdf('boca',ticket.id)"
                ><span>Print BOCA</span>
                </v-btn>
            </v-list-item-action> -->
             </v-list-item>
          </div>
        </v-list>
        <div
          v-else
          style="height: 200px"
          class="d-flex justify-center align-center"
        >
          No Participants Tickets
        </div>
        <!-- <v-divider></v-divider> -->
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close()" class="ma-2 white--text blue-color" text
          >Close
        </v-btn>
        <v-spacer></v-spacer>
        <div  v-if="selectedTickets.length">
          <v-btn
            class="m1-2 white--text teal-color"
            normal
            @click="printPdf('pos')"
          ><span>Print POS</span>
          </v-btn>      
          <v-btn
              class="ma-1 white--text teal-color"
              normal
              @click="printPdf('boca')"
          ><span>Print BOCA</span>
          </v-btn>
        </div>
       
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    showParticipants: { type: Boolean, default: false },
    id: { type: Number,default: null },
    refresh: { type: Boolean },
  },
  watch: {
    showParticipants(val) {
      if (val == true) {
        this.getBookingTickets();
      }
    },
    refresh() {
      this.getBookingTickets();
    },
  },
  data() {
    return {
      tickets: [],
      cbAll: null,
      selectedTickets:[],
    };
  },
  methods: {
    printPdf(type) {
      if (this.selectedTickets.length) {
        this.showLoader("Generating..");
        this.$http
          .get(`venues/orders/booking-tickets-pdf/${this.id}/${this.selectedTickets.join(",")}?type=${type}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.printFile(response);
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    getBookingTickets() {
      if (!this.id) {
        return;
      }
      this.showLoader("Loading");
      this.$http.get(`venues/orders/booking-tickets/${this.id}`).then((response) => {
        if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.tickets = data;
            this.hideLoader();
          }
        });
    },
    checkedTicket(ticketId, isSelect) {
      const indexOfTicket = this.selectedTickets.indexOf(ticketId);
      if (isSelect) {
        if (indexOfTicket === -1) {
          this.selectedTickets.push(ticketId);
        }
      } else {
          if (indexOfTicket !== -1) {
            this.selectedTickets.splice(indexOfTicket, 1);
          }
      }
    },
    selectAllTickets(isAllSelect) {
      if (isAllSelect) {
          this.selectedTickets = [];
          this.tickets.forEach(element => {
            element.isSettle = true;
            this.selectedTickets.push(element.id);
          });
      } else {
        this.selectedTickets = [];
        this.tickets.forEach(element => {
            element.isSettle = false;
        });
      }
    },
    close() {
      this.cbAll = false;
      this.selectedTickets = [];
      this.$emit("close");
    },
  },
};
</script>

<style>
.parent-list-items.border-show {
  border-bottom: 1px inset;
}
.ticket-row {
    display: flex;
}
.ticket-row span.cdisplayBlock {
  display: block;
  padding-top: 5px;
  font-size: 12px;
}
.ticket-row span.numbering {
    width: 30px;
    display: inline-block;
}
.ticket-row .right {
    padding: 10px;
}


</style>
