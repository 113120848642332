<template>
  <v-dialog v-model="dialog" width="55%" @input="close">
    <v-card>
      <v-card-title class="headline">Reschedule Booking <v-spacer></v-spacer>
        <span class="ml-2" v-if="!bookingDetails.is_trainer">Price: {{ bookingDetails.price | toCurrency }}</span>
      </v-card-title>
      <v-card-text class="form_bg">
        <v-card
          color="#edf9ff"
          style="border: 1px #ccc solid"
          class="pa-4"
          outlined
        >
          <v-row>
            <v-col md="5">
              <p class="titles text-left mb-0">Booking Details</p>
            </v-col>
            <v-col md="7">
              <p
                class="titles text-right mb-0"
                v-if="bookingDetails.price != bookingDetails.old_price && !bookingDetails.is_trainer"
              >
                New Price : {{ bookingDetails.price | toCurrency }} | Original
                Price : {{ bookingDetails.old_price | toCurrency }}
                <span v-if="bookingDetails.balance"
                  >| Excess Price : {{ bookingDetails.symbol }}
                  {{ bookingDetails.balance | toCurrency }}</span
                >
              </p>
            </v-col>
          </v-row>
          <div
            class="titles d-flex justify-space-between"
            v-if="!bookingDetails.is_trainer"
          >
            <v-btn
              x-small
              outlined
              v-if="productCombinations.length > 1"
              @click="chooseRentalCombination"
              ><v-icon x-small>mdi-cart-arrow-right</v-icon> Rental
              combination</v-btn
            >
          </div>
          <div v-if="!bookingDetails.is_trainer">
            <v-chip
              label
              color="cyan"
              dark
              class="ml-2 mb-2"
              v-for="(product, index) in bookingDetails.products"
              :key="index"
            >
              <v-avatar left>
                <view-image :image="product.image_path"></view-image>
              </v-avatar>
              {{ product.name }} x
              {{
                product.quantity && typeof product.quantity == "number"
                  ? product.quantity.toFixed(2)
                  : product.quantity
              }}
              -

              {{ product.price.toFixed(2) | toCurrency }}
              <span
                v-if="product.discount != null"
                class="text-decoration-line-through pl-1"
              >
                {{
                  product.discount.actual_price.toFixed(2) | toCurrency
                }}</span
              >
            </v-chip>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-0">
            <v-col sm="6" md="6">
              <date-field
                v-model="rescheduleForm.date"
                label="Booking date"
                @change="getRescheduleDetails"
                :backFill="
                  checkBackfillPermission($modules.facility.schedule.slug)
                "
              >
              </date-field>
            </v-col>
            <v-col sm="6" md="6">
              <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                label="Facility"
                background-color="#fff"
                item-text="name"
                item-value="id"
                :items="facilities"
                v-model="rescheduleForm.facility_id"
                @change="getRescheduleDetails"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col sm="6" md="6">
              <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                label="Start time"
                background-color="#fff"
                item-text="formatted"
                item-value="time"
                :items="times"
                v-model="rescheduleForm.start_time"
                @change="changeStartTime"
              >
              </v-select>
            </v-col>
            <v-col sm="6" md="6">
              <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                label="End time"
                background-color="#fff"
                item-text="formatted"
                item-value="time"
                :items="endTimes"
                v-model="rescheduleForm.end_time"
                @change="changeEndTime"
              >
              <template v-slot:item="{ item }">
                <span v-html="item.formatted"></span>
              </template>
              <template v-slot:selection="{ item }">
                <span v-html="item.formatted"></span>
              </template>
              </v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" class="ma-2 white--text blue-color" text
          >Close
        </v-btn>
        <v-btn
          @click="confirmReschedule()"
          class="ma-2 white--text teal-color"
          text
          >Confirm Booking
        </v-btn>
      </v-card-actions>
    </v-card>
    <product-combination
      :productCombinations="productCombinations"
      :showCombinations="showCombinationDialog"
      @close="showCombinationDialog = false"
      @changeCombination="changeRentalProducts"
      :selectedCombinationPrice="selectedCombinationPrice"
    >
    </product-combination>
    <credit-order-payment
      :id="orderId"
      @close="orderId = null"
      @payed="(orderId = null), $emit('booked', orderId)"
      @cancel="orderId = null"
      @reload="$emit('reload')"
    ></credit-order-payment>
  </v-dialog>
</template>

<script>
import ProductCombination from "@/components/Schedule/Facility/ProductCombination";
import CreditOrderPayment from "@/components/Order/CreditOrderPayment";
import moment from "moment";
export default {
  props: {
    id: { type: Number, default: 0 },
    venueServiceId: { type: Number },
    perCapacity: { type: Number, default: 0 },
  },
  components: {
    ProductCombination,
    CreditOrderPayment,
  },
  data() {
    return {
      enableOvernightBooking:false,
      dialog: false,
      times: [],
      endTimes: [],
      bookingDetails: {},
      rescheduleForm: { end_time: null },
      bookingId: null,
      facilities: [],
      productCombinations: [],
      showCombinationDialog: false,
      selectedCombinationPrice: 0,
      orderId: null,
      minBookingTime: 60,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.checkEnableOvernightBooking();
          this.rescheduleForm = {
            start_time: null,
            end_time: null,
            date: null,
            facility_id: null,
          };
          this.productCombinations = [];
          this.facilities = [];
          this.getRescheduleDetails();
          this.dialog = true;
        } else {
          this.dialog = false;
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices")
    }
  },
  computed: {
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
      );
    },
  },
  methods: {
    checkEnableOvernightBooking(){
      if(this.venueServiceConfiguration.enable_over_night_booking){
        this.enableOvernightBooking = true;
      }
    },
    changeStartTime() {
      this.endTimes = this.times;
      let expectedEndTime = moment(this.rescheduleForm.start_time, "HH:mm:ss").add(this.minBookingTime, "minutes").format("HH:mm:ss");
      this.endTimes = this.endTimes.filter((item) => {
        return item.time >= expectedEndTime;
      });
      this.rescheduleForm.end_time = null;
      if (this.endTimes.length > 0) {
        this.rescheduleForm.end_time = this.endTimes[0].time;
      }

      this.getRescheduleDetails(true);
    },
    changeEndTime() {
      // if (this.perCapacity == 1) {
      //   let expectedStartTime = moment(this.rescheduleForm.end_time, "HH:mm:ss")
      //     .subtract(this.minBookingTime, "minutes")
      //     .format("HH:mm:ss");
      //   this.rescheduleForm.start_time = expectedStartTime;
      // }
      setTimeout(() => {
        this.getRescheduleDetails();
      }, 10);
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    minutesOfDay(m) {
      return m.minutes() + m.hours() * 60;
    },
    getRescheduleDetails(timeChanged = false) {
      if ((
        this.minutesOfDay(moment(this.rescheduleForm.start_time, "HH:mm:ss")) >=
        this.minutesOfDay(moment(this.rescheduleForm.end_time, "HH:mm:ss"))) && !this.enableOvernightBooking
      ) {
        return;
      }
      this.showLoader("Loading");
      let url = `${this.rescheduleForm.start_time? "&start_time=" + this.rescheduleForm.start_time: ""}`;
      url += `${this.rescheduleForm.end_time? "&end_time=" + (this.rescheduleForm.end_time == "00:00:00"?"23:59:59":this.rescheduleForm.end_time): ""}`;
      url += `${this.rescheduleForm.date ? "&date=" + this.rescheduleForm.date : ""}`;
      url += `${this.rescheduleForm.facility_id? "&facility_id=" + this.rescheduleForm.facility_id: ""}`;
      url += `${this.enableOvernightBooking? "&enable_overnight_booking=" + (this.enableOvernightBooking ? 1:0): ""}`;

      this.$http.get(`venues/facilities/bookings/reschedule/${this.id}?venue_service_id=${this.venueServiceId}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.bookingDetails = {
              booking_id: data.id,
              total: data.order.total,
              price: data.order.price,
              tax_amount: data.order.tax_amount,
              old_price: data.order.price,
              is_trainer: data.is_trainer,
            };
            this.bookingDetails.products = [];
            let sum = 0;
            data.order.items.forEach((item) => {
              sum += item.price;
              let pdata = {
                order_item_id: item.id,
                product_id: item.product_id,
                product_type_id: item.product_type_id,
                category_id: item.category_id,
                quantity: item.quantity,
                price: item.price,
                rental: item.rental != null,
                venue_service_id: this.venue_service_id,
                name: item.name ? item.name : "Product Name",
                product_price: item.actual_price? item.actual_price: item.product_price,
                tax: item.tax,
                discounted_price:item.price
              };
              this.bookingDetails.products.push(pdata);
            });

            this.bookingDetails.old_price = sum;
            this.times = data.times;  
            this.minBookingTime = data.min_booking_time;
            this.endTimes = this.endTimeSlots(data.start_time,data.times);
            if (!timeChanged) {
              this.rescheduleForm = {
                start_time: data.start_time,
                end_time: data.end_time,
                date: data.date,
                facility_id: data.facility_id,
              };
            }
            if (typeof this.endTimes != "undefined" && !this.enableOvernightBooking) {
              let expectedEndTime = moment(this.rescheduleForm.start_time,"HH:mm:ss").add(this.minBookingTime, "minutes").format("HH:mm:ss");
              this.endTimes = this.endTimes.filter((item) => { return (item.time >= expectedEndTime); });
            }
            if (data.product_combinations.length) {
              this.productCombinations = data.product_combinations;
              this.changeRentalProducts(this.productCombinations[0]);
            }else{
              this.productCombinations = [];
            }

            if (!this.facilities.length) {
              this.getFacilities();
            }
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFacilities() {
      this.$http.get(`venues/facilities/short?venue_service_id=${this.venueServiceId}&date=${this.rescheduleForm.date}&per_capacity=${this.perCapacity}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.facilities = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeRentalProducts(rentalCombination) {
      let otherProducts = this.bookingDetails.products.filter((item) => !item.rental);
      this.selectedCombinationPrice = rentalCombination.total_price;
      let rentals = [];
      rentalCombination.products.forEach((product) => {
        // if product have discount applied, then on duration change calculate for initial price correctly :: QP-2849
        const orderItem = this.bookingDetails.products.find(prod=>prod.product_id == product.id);
        let price = product.price;
        let newQuantity = product.quantity;
        let productPrice = product.product_price_when_overlapping?product.product_price_when_overlapping:product.product_price;
        if (orderItem && orderItem.price != product.price){
          price = orderItem.discounted_price?orderItem.discounted_price:product.product_price;
          if(!orderItem.actual_price){
            price = product.price;
          }
          newQuantity =  (product.quantity - orderItem.quantity);
          if (newQuantity>=0){
            product.price = price;//(productPrice*newQuantity)+price;
          } else {
            if(orderItem.discounted_price){
              product.price = ((orderItem.discounted_price/orderItem.quantity)*product.quantity);
            }else{
              product.price = productPrice * product.quantity;
            }
          }
        }

        rentals.push({
          product_id: product.id,
          product_type_id: product.product_type_id,
          price: product.price,
          name: product.name,
          tax: product.tax_amount,
          category_id: product.category_id,
          rental: true,
          product_price: product.product_price,
          quantity: product.quantity,
        });
      });

      if (otherProducts) {
        this.bookingDetails.products = [...rentals, ...otherProducts];
      } else {
        this.bookingDetails.products = rentals;
      }
      this.bookingDetails.price = this.bookingDetails.products.reduce((a, b) => a + parseFloat(b.price), 0 );
      if (this.bookingDetails.price < this.bookingDetails.old_price) {
        this.bookingDetails.icon = "mdi-arrow-down-thick";
        this.bookingDetails.color = "green darken-4";
        this.bookingDetails.symbol = "-";
        this.bookingDetails.balance = this.bookingDetails.old_price - this.bookingDetails.price;
      } else if (this.bookingDetails.price > this.bookingDetails.old_price) {
        this.bookingDetails.icon = "mdi-arrow-up-thick";
        this.bookingDetails.color = "error";
        this.bookingDetails.symbol = "+";
        this.bookingDetails.balance = this.bookingDetails.price - this.bookingDetails.old_price;
      }
      this.showCombinationDialog = false;
      this.$forceUpdate();
    },
    chooseRentalCombination() {
      this.showCombinationDialog = true;
    },
    confirmReschedule() {
      this.showLoader();
      if (!this.rescheduleForm.end_time) {
        this.hideLoader();
        this.showError("Please fill all fields");
        return;
      }

      var start_time = moment(this.rescheduleForm.start_time, "HH:mm:ss");
      var end_time = moment(this.rescheduleForm.end_time, "HH:mm:ss");
      let checkMinBookingTIme = end_time.diff(start_time, "minute");
      if (checkMinBookingTIme % 5 == 4) {
        checkMinBookingTIme += 1;
      }
      if (checkMinBookingTIme > 0 && checkMinBookingTIme < this.minBookingTime) {
        this.hideLoader();
        this.showError("Minimum booking time is not matching with the selected time");
        return;
      }

      if (!moment(start_time, "HH:mm:ss").isBefore(moment(end_time, "HH:mm:ss")) && !this.enableOvernightBooking) {
        this.hideLoader();
        this.showError("The end time must be a date after start time.");
        return;
      }

      let isPastTime = moment(this.rescheduleForm.start_time,"HH:mm:ss").isSameOrBefore();
      let isPastDate = moment(this.rescheduleForm.date,"YYYY-MM-DD").isSameOrBefore();

      if (isPastTime && isPastDate) {
        this.hideLoader();
        this.showError("You cannot reschedule to a past time.");
        return;
      }

      let formData = { products: [] };
      var productNullFlag = false;
      this.bookingDetails.products.forEach((product) => {
        if (product.product_id) {
          // formData.append(`products[${index}][product_id]`, product.product_id);
          // formData.append(`products[${index}][quantity]`, product.quantity);
          formData.products.push({
            product_id: product.product_id,
            quantity: product.quantity && typeof product.quantity == "number"? parseFloat(product.quantity.toFixed(2)): parseFloat(product.quantity),
          });
        } else {
          productNullFlag = true;
        }
      });
      if (productNullFlag) {
        this.hideLoader();
        this.showError("Please select different time");
        return;
      }
      formData.facility_id = this.rescheduleForm.facility_id;
      formData.start_time = this.rescheduleForm.start_time;
      formData.end_time = this.rescheduleForm.end_time;
      formData.venue_service_id = this.venueServiceId;
      formData.date = this.rescheduleForm.date;

      if(this.enableOvernightBooking){
        formData.enable_overnight_booking= 1;
      }else{
        formData.enable_overnight_booking= 0;
      }

      this.$http.post(`venues/facilities/bookings/reschedule/${this.id}`, formData).then((response) => {
        if (response.status == 200 && response.data.status == true) {
          this.close();
          let data = response.data.data;
          this.hideLoader();
          this.close();
          if (data.reschedule.price_status == "increase") {
            this.orderId = data.id;
          } else if (data.reschedule.price_status == "decrease") {
            this.$emit("refund", data.id);
          } else {
            this.$emit("booked", data.id);
          }
          this.showSuccess(response.data.message? response.data.message: "Booking updated successfully");
        }
      })
      .catch((error) => {
        this.hideLoader();
        this.errorChecker(error);
      });
    },
    endTimeSlots(start_time,et) {
      if(et){
        const timeSlotsFs = [];
        const timeSlotsSc = [];
        let currentTime = moment(start_time, 'HH:mm:ss');
        et.forEach( (ob) => {
          if(moment(ob.time,"HH:mm:ss").isBefore(currentTime)){
            timeSlotsSc.push({
              formatted: moment(ob.time,"HH:mm:ss").format('hh:mm a') + '<span style="color: red">+1</span>',
              time: ob.time,
            });
          }
        });
        et.forEach( (ob) => {
          if(moment(ob.time,"HH:mm:ss").isAfter(currentTime)){
            timeSlotsFs.push({
              formatted: moment(ob.time,"HH:mm:ss").format('hh:mm a'),
              time: ob.time,
            });
          }
        })

        return timeSlotsFs.concat(timeSlotsSc);
      }
    },

  },
};
</script>

<style scoped>
.details_title {
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(0, 89, 118) !important;
}

.details_subtitle {
  font-size: 13px;
  color: #8b8b8b;
  text-align: left;
  font-weight: 100;
}

.details_data {
  font-size: 16px;
  padding: 3px 0px;
  text-align: left;
  font-weight: 400;
}
</style>
