<template>
  <v-dialog
    v-model="maintenanceDialog"
    max-width="600px"
    persistent
    scrollable
    @input="$refs.form.resetValidation()"
  >
    <v-card >
      <v-card-text class="border-bottom">
        <div class="row pt-1 border-bottom">
          <div class="col-md-12">
            <div class="d-flex justify-space-between align-center mt-2">
              <SvgIcon class="text-2xl font-semibold" text="Ground Maintenance" style="color: black" >
              </SvgIcon>
              <v-btn  fab x-small class="shadow-0" @click="maintainGroundClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-form ref="form">
          <v-row class="mt-4" dense>
            <v-col md="12">
              <label for="">Name*</label>
              <v-text-field
                outlined
                background-color="#fff"
                v-model="maintainData.name"
                required
                :rules="[(v) => !!v || 'Name is required']"
                class="q-text-field shadow-0"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="12">
              <label for="">Description</label>

              <v-textarea
                outlined
                background-color="#fff"
                v-model="maintainData.description"
                dense
                hide-details
                class="q-text-field shadow-0"
              ></v-textarea>
            </v-col>
            <v-col md="6">
              <label for="">Start Date*</label>

              <date-field
                v-model="maintainData.start_date"
                :rules="[(v) => !!v || 'Start date is required']"
                :backFill="
                  checkBackfillPermission($modules.facility.management.slug)
                "
                class-name="q-text-field shadow-0"
                dense
                hide-details
              >
              </date-field>
            </v-col>
            <v-col md="6">
              <label for="">End Date*</label>
              <date-field
                v-model="maintainData.end_date"
                :rules="[(v) => !!v || 'End date is required']"
                :backFill="
                  checkBackfillPermission($modules.facility.management.slug)
                "
                class-name="q-text-field shadow-0"
                dense
                hide-details
              >
              </date-field>
            </v-col>
            <v-col>
              <label for="">From*</label>

              <v-select
                :items="startTimes"
                label="From*"
                item-text="text"
                item-value="value"
                v-model="maintainData.start_time"
                @change="startTimeChange"
                required
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'From time is required']"
                class="q-autocomplete shadow-0"
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <label for="">To*</label>
              <v-select
                :items="startTimes"
                item-text="text"
                item-value="value"
                v-model="maintainData.end_time"
                required
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'To time is required']"
                class="q-autocomplete shadow-0"
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2 "
          text
          @click="maintainGroundClose"
          >Close</v-btn
        >
        <v-btn
          class="ma-2 white--text blue-color"
          @click="addMaintainGround"
          text
          >Add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {SvgIcon},
  props: {
    facilityId: { type: Number, default: null },
    venueServiceId: { type: Number, default: null },
    startTime: { type: String, default: null },
    endTime: { type: String, default: null },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    timeIncrement: { type: Number, default: null },
  },
  watch: {
    facilityId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.maintainData.facility_id = this.facilityId;
          this.maintainData.venue_service_id = this.venueServiceId;
          this.maintainData.start_date = this.startDate ? this.startDate : null;
          this.maintainData.end_date = this.endDate ? this.endDate : null;
          this.maintainData.start_time = this.startTime ? this.startTime : null;
          this.maintainData.end_time = this.endTime ? this.endTime : null;
          this.maintenanceDialog = true;
          this.getStartTimes();
          setTimeout(() => {
            this.$refs.form.resetValidation();
            this.$forceUpdate();
          });
        }
      },
    },
  },
  data() {
    return {
      maintainData: { start_date: null, end_date: null },
      maintenanceDialog: false,
      startTimes: [],
      endTimes: [],
    };
  },
  methods: {
    maintainGroundClose() {
      this.maintainData = { start_date: null, end_date: null };
      this.maintenanceDialog = false;
      this.$emit("close");
    },
    addMaintainGround() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      this.$http
        .post("venues/facilities/maintenances", this.maintainData)
        .then((response) => {
          if (response.status == 200) {
            this.$emit("save");
            this.maintainGroundClose();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getStartTimes() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.startTimes = [];
      this.startTimes.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.startTimes.push(data);
          hour = 24;
        } else {
          this.startTimes.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    startTimeChange() {
      this.maintainData.end_time = moment(
        this.maintainData.start_time,
        "HH:mm:ss"
      )
        .add(this.timeIncrement, "minutes")
        .format("HH:mm:ss");
      this.$forceUpdate();
    },
  },
};
</script>

<style>
</style>