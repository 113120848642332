<template>
  <div>
    <v-tooltip bottom v-if="addOn == 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="red"
          fab
          x-small
          absolute
          top
          right
          dark
          @click="deleteCustomer()"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      Delete
    </v-tooltip>
    <v-row no-gutters>
      <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-4 w-full">
        <div class="title-text">Customer Details</div>
      </div>
      <div class="d-flex justify-space-between align-center w-full">
        <div  v-if="!addOn">
          <div class="pa-2 titles text-center" v-if="perCapacity == 1">
            Price
            {{ bookingForm.total_price | toCurrency }}
            <span
                v-if="
              bookingForm.discount != null &&
                bookingForm.price != bookingForm.discount.actual_price
            "
                class="text-decoration-line-through"
            >
            {{ bookingForm.discount.actual_total | toCurrency }}</span
            >
          </div>
        </div>
        <div
            v-if="promotions.length > 0 && perCapacity == 1 && !addOn"
            style="margin-bottom: 10px"
        >
          <label for="" v-if="bookingForm.card_number === null">Promotions</label>
          <v-autocomplete
              v-if="bookingForm.card_number === null"
              :items="[{ name: 'None', promotion_code: null }, ...promotions]"
              item-text="name"
              item-value="promotion_code"
              v-model="bookingForm.promotion_code"
              outlined
              @change="verifyBenefit('promotion')"
              label=""
              :readonly="disablePromotion"
              dense
              hide-details="auto"
              class="q-autocomplete shadow-0"
          >
          </v-autocomplete>
        </div>
      </div>
      <!-- Uncomment to enable emirates ID reader -->
    </v-row>
    <v-divider></v-divider>
    <v-row class="px-4">
      <v-col md="3" v-if="!addOn">
        <v-radio-group
          v-model="bookingForm.customer_type"
          class="custom-radio-group mt-5"
          row
          @change="customerTypeChange"
          mandatory
          hide-details="auto"
          :readonly="id > 0"
        >
          <v-radio label="Normal" class="custom-radio" color="cyan" value="normal"></v-radio>
          <!-- <v-radio label="Corporate" color="cyan" value="corporate"></v-radio> -->
          <v-radio label="Member" class="custom-radio" color="cyan" value="member"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col md="2" v-if="bookingForm.customer_type == 'member' && !addOn">
        <v-member-search
          v-model="bookingForm.member"
          @clear="clearBenefit"
          :selected="bookingForm.card_number"
          @updateCustomer="setMemberData"
          class="mt-4"
        >
        </v-member-search>
      </v-col>
      <!-- <v-col md="2" v-if="bookingForm.customer_type == 'corporate' && !addOn">
        <v-autocomplete
          class="mt-4"
          label="Company Name"
          :items="companies"
          v-model="bookingForm.company_id"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col md="2" v-if="bookingForm.customer_type == 'corporate' && !addOn">
        <v-autocomplete
          :disabled="bookingForm.company_id == null"
          :items="getCompanySales()"
          label="Sale Order ID"
          item-text="sale_seq_no"
          item-value="id"
          class="mt-4 ml-2"
          v-model="bookingForm.company_sale_id"
          outlined
          background-color="#fff"
          dense
        >
        </v-autocomplete>
      </v-col> -->
      <v-spacer></v-spacer>
      <v-col md="2">
        <span class="d-flex align-center"><v-checkbox :ripple="false"  v-model="bookingForm.opt_marketing"/> Opt In Marketing</span>
      </v-col>
      <v-col md="3" class="text-right d-flex flex-row-reverse" v-if="!order_id">
        <card-data-button
          className="blue-text mt-5 ml-4"
          label="HID Omnikey"
          @data="
            (data) => {
              setCardData(data);
            }
          "
        ></card-data-button>
        <card-reader-button
          className="blue-text mt-5"
          label="Samsotech Reader"
          @data="
            (data) => {
              setCardData(data);
            }
          "
        ></card-reader-button>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col sm="4" md="4">
        <div>
          <label for="">Mobile No*</label>
          <v-mobile-search
            outlined
            :selected="bookingForm.mobile"
            @updateCustomer="setCustomerData"
            readonly
            v-model="bookingForm.search"
            hide-details="auto"
            class-name1="q-text-field shadow-0"
            background-color=""
            :dense="true"
            label=""
            :show-label="false"
          ></v-mobile-search>
        </div>
      </v-col>

      <v-col sm="4" md="4">
        <div>
          <label for="">Name*</label>
          <v-name-search
            outlined
            :selected="bookingForm.name"
            :mobile="bookingForm.mobile"
            :email="bookingForm.email"
            @updateCustomer="setCustomerData"
            readonly
            v-model="bookingForm.nameSearch"
            hide-details="auto"
            class-name="q-text-field shadow-0"
            background-color=""
            :dense="true"
            label=""
          >
          </v-name-search>
        </div>
      </v-col>

      <v-col sm="4" md="4">
        <div>
          <label for="">Email{{field.email.is_required ? '*' : ''}}</label>
          <v-text-field
            outlined
            :readonly="bookingForm.customer_id != null"
            background-color="#fff"
            v-model="bookingForm.email"
            label=""
            :placeholder="`Email${field.email.is_required ? '*' : ''}`"
            :rules="emailRule"
            class="q-text-field shadow-0"
            dense
            validate-on-blur
          ></v-text-field>
        </div>
      </v-col>
      <v-col sm="4" md="4" v-if="field.gender.is_visible">
        <label for="">Gender{{field.gender.is_required ? '*' : ''}}</label>
        <v-select
          :items="['Male', 'Female']"
          :placeholder="`Gender${field.gender.is_required ? '*' : ''}`"
          label=""
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          v-model="bookingForm.gender"
          :rules="genderRule"
          background-color="#fff"
          class="q-text-field shadow-0"
          dense
          validate-on-blur
        ></v-select>
      </v-col>
      <v-col sm="4" md="4" v-if="field.dob.is_visible && customerAgeRange">
        <label for="">Age Group{{ field.dob.is_required ? '*' : '' }}</label>
        <v-select
            v-if="customerAgeRange"
            v-model="bookingForm.age_group"
            :items="ageRanges"
            item-text="name"
            item-value="id"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            background-color="#fff"
            class="q-text-field shadow-0"
            dense
            validate-on-blur
        ></v-select>
      </v-col>
      <v-col sm="4" md="4" v-if="field.dob.is_visible && !customerAgeRange">
        <label for="">Date of Birth{{ field.dob.is_required ? '*' : '' }}</label>
        <date-of-birth
          :placeholder="`Date of Birth${field.dob.is_required ? '*' : ''}`"
          label=""
          :rules="dobRule()"
          v-model="bookingForm.dob"
          class-name="q-text-field shadow-0 add-on"
          hide-details="auto"
          :dense="true"
        >
        </date-of-birth>
      </v-col>
      <v-col sm="4" md="4" v-if="field.nationality.is_visible">
        <label for="">Nationality{{ field.nationality.is_required ? '*' : '' }}</label>
        <v-autocomplete
          :items="countries"
          :hint="`Nationality${field.nationality.is_required ? '*' : ''}`"
          label=""
          :rules="nationalityRule"
          item-value="id"
          item-text="name"
          outlined
          v-model="bookingForm.country_id"
          background-color="#fff"
          class="q-text-field shadow-0"
          dense
          validate-on-blur
        ></v-autocomplete>
      </v-col>
      <v-col sm="4" md="4" v-if="field.idProof.is_visible">
        <label for="">ID Type{{ field.idProof.is_required ? '*' : '' }}</label>
        <v-select
          :hint="`ID Type${field.idProof.is_required ? '*' : ''}`"
          label=""
          :rules="idTypeRule"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          item-value="id"
          item-text="name"
          :items="idProofTypes"
          v-model="bookingForm.id_proof_type_id"
          @change="changeIdProofTypeId"
          background-color="#fff"
          class="q-autocomplete shadow-0"
          dense
          validate-on-blur
        ></v-select>
      </v-col>
      <v-col sm="4" md="4" v-if="field.idProof.is_visible">
        <v-row no-gutters>
          <v-col md="7">
            <label for="">ID Number{{ field.idProof.is_required ? '*' : '' }}</label>
            <v-text-field
              :key="refreshIdProofNumber"
              :hint="`ID Number${field.idProof.is_required ? '*' : ''}`"
              label=""
              :rules="idTypeRule"
              outlined
              v-model="bookingForm.id_proof_number"
              background-color="#fff"
              class="q-text-field shadow-0 text_field1"
              dense
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col md="5">
            <label>{{ bookingForm.id_proof_path ? 'Download' : 'ID Proof' }} {{
                field.idProof.is_required ? '*' : ''
              }}</label>
            <v-file-input
              v-model="bookingForm.id_proof"
              :rules="idProofRule"
              prepend-icon=""
              background-color="#fff"
              outlined
              dense
              hide-details="auto"
              class="q-text-field shadow-0"
            >
              <template v-slot:prepend-inner>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="cyan"
                      v-if="bookingForm.id_proof_path"
                      @click="openFile(bookingForm.id_proof_path)"
                      v-on="on"
                    >
                      mdi-download-box
                    </v-icon>
                    <v-icon v-else v-on="on"> mdi-card-account-details </v-icon>
                  </template>
                  <span v-if="bookingForm.id_proof_path"
                    >Download uploaded file</span
                  >
                  <span v-else>Upload ID Proof</span>
                </v-tooltip>
              </template>
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index == 0"
                  color="cyan accent-4"
                  dark
                  label
                  small
                >
                  <span style="width: 38px" class="text-truncate">{{
                    text
                  }}</span>
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <!-- <div style="margin-top: -110px"></div> -->
      </v-col>
      <v-col sm="4" md="4" v-if="field.tag.is_visible">
        <label for="">Tags{{ field.tag.is_required ? '*' : '' }}</label>
        <v-select
          :items="tags"
          :placeholder="`Tags${field.tag.is_required ? '*' : ''}`"
          label=""
          outlined
          item-value="id"
          item-text="name"
          :menu-props="{ bottom: true, offsetY: true }"
          v-model="bookingForm.customer_tag"
          :rules="tagRule"
          background-color="#fff"
          return-object
          multiple
          class="q-autocomplete shadow-0"
          dense
          validate-on-blur
        ></v-select>
      </v-col>
      <v-col sm="4" md="4" v-if="field.image.is_visible">
        <v-row >
          <v-col md="8">
            <label for="">
              {{
                bookingForm.profile_image ? 'Change image' : 'Upload Image'
              }} {{ field.image.is_required ? '*' : '' }}
            </label>
            <v-file-input
              v-model="bookingForm.profile_image"
              prepend-icon=""
              :rules="imageRule"
              background-color="#fff"
              outlined
              show-size
              dense
              hide-details="auto"
              class="q-text-field shadow-0"
            >
              <template v-slot:prepend-inner>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="cyan"
                      v-if="bookingForm.image_path"
                      @click="openFile(bookingForm.image_path)"
                      v-on="on"
                    >
                      mdi-download-box
                    </v-icon>
                    <v-icon v-else v-on="on">mdi-image</v-icon>
                  </template>
                  <span v-if="bookingForm.image_path"> Download image</span>
                  <span v-else>Upload Image</span>
                </v-tooltip>
              </template>
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index == 0"
                  color="cyan accent-4"
                  dark
                  label
                  small
                >
                  <span style="width: 120px" class="text-truncate">{{
                    text
                  }}</span>
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col md="4" align-self="end">
            <v-btn
              large
              block
              style="background-color: #fff"
              outlined
              height="40"
              color="blue-grey"
              class="white--text q-text-field shadow-0 bordered"
              @click="webcamDialog = true"
            >
              <v-icon dark>mdi-camera</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <capture-image
      :open="webcamDialog"
      @close="webcamDialog = false"
      @confirm="confirmImageCapture"
    />
  </div>
</template>
<script>
import moment, { now } from "moment";
import VMemberSearch from "@/components/Customer/VMemberSearch";
import bookingFields from "@/mixins/bookingFieldValidation";
import CaptureImage from "@/components/Image/CaptureImage";
export default {
  props: {
    perCapacity: { type: Number, default: 0 },
    singleBookingForm: { type: Object },
    promotions: { type: Array },
    countries: { type: Array },
    tags: { type: Array },
    companies: { type: Array },
    idProofTypes: { type: Array },
    id: { type: Number, default: 0 },
    venue_service_id: { type: Number },
    order_id: { type: Number },
    disablePromotion: { type: Boolean, default: false },
    addOn: { type: Number, default: 0 },
  },
  mixins: [bookingFields],
  components: {
    VMemberSearch,
    CaptureImage,
  },
  data() {
    return {
      webcamDialog: false,
      refreshIdProofNumber: now(),
    };
  },
  computed: {
    customerAgeRange() {
      return this.$store.getters.getCustomerAgeRangeConfiguration.data;
    },
    ageRanges() {
      return this.$store.getters.getCustomerAgeRange.data;
    },
    bookingForm() {
      if (this.singleBookingForm !== "") {
        return this.singleBookingForm;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.setFieldConfigurations();

    if (this.$store.getters.getCustomerAgeRangeConfiguration.status == false) {
      this.$store.dispatch("LoadCustomerAgeRangeConfiguration");
    }
    if (this.$store.getters.getCustomerAgeRange.status == false) {
      this.$store.dispatch("LoadCustomerAgeRange");
    }

  },
  methods: {
    setMemberData(data) {
      this.$emit("setMemberData", data);
    },
    setCustomerData(data) {
      this.$emit("setCustomerData", data);
    },
    setCardData(data) {
      this.$emit("setCustomerData", data);
    },
    customerTypeChange() {
      this.$emit("customerTypeChange");
    },
    verifyBenefit(type) {
      this.$emit("verifyBenefit", type);
    },
    clearBenefit() {
      this.$emit("clearBenefit");
    },
    getCompanySales() {
      return this.bookingForm.company_id != null && this.companies.length
        ? this.companies.find((item) => item.id == this.bookingForm.company_id)
            .company_sale
        : [];
    },
    confirmImageCapture(image) {
      image.name = this.bookingForm.name
        ? this.bookingForm.name + "_" + moment().format("YYYYMMDDHHSS")
        : "user_image_" + moment().format("YYYYMMDDHHSS");
      this.bookingForm.profile_image = image;
      this.webcamDialog = false;
    },
    changeIdProofTypeId() {
      if (
        this.bookingForm.customer_documents &&
        this.bookingForm.customer_documents.length
      ) {
        let objType = this.bookingForm.customer_documents.find((x) => {
          return x.id_proof_type_id === this.bookingForm.id_proof_type_id;
        });
        if (typeof objType !== "undefined" && objType.id_proof_type_id) {
          this.bookingForm.id_proof_number = objType.id_proof_number;
          this.bookingForm.id_proof_path = objType.id_proof_path;
          this.refreshIdProofNumber = now();
        } else {
          this.bookingForm.id_proof_number = null;
          this.bookingForm.id_proof_path = null;
          this.refreshIdProofNumber = now();
        }
      } else {
        // console.log("document length 0");
      }
    },
    deleteCustomer() {
      console.log("emitting");
      this.$emit("removeCustomerAdd");
    },
  },
};
</script>

<!--  <customer-booking-form
                  :promotions="promotions"
                  :singleBookingForm="bookingForm"
                  :id="id"
                  :order_id="order_id"
                  :countries="countries"
                  :idProofTypes="idProofTypes"
                  @setCustomerData="(data) => setCustomerData(data)"
                  @setMemberData="(data) => setMemberData(data)"
                  @customerTypeChange="(data) => customerTypeChange(null, data)"
                  @clearBenefit="(data) => clearBenefit()"
                /> -->
