<template>
  <v-dialog scrollable :value="show" max-width="500">
    <v-card>
      <v-toolbar color="teal" dark height="80">
        <v-toolbar-title>Alert Note</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-note-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: pre-wrap">
              <span>{{ note }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <!-- <h3>{{ note }}</h3> -->
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn @click="clearNote(id)" class="ma-2 black--text yellow-color" text
          >Clear Alert
        </v-btn> -->
        <v-btn @click="closeModal()" class="ma-2 white--text blue-color" text
          >Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    note: { type: String, default: null },
    id: { type: Number, default: null },
  },
  methods: {
    closeModal() {
      this.$emit("closeAlert");
    },
    clearNote() {
      this.$emit("clearAlertNote", this.id);
    },
  },
};
</script>
