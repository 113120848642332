<template>
  <div class="bg-white border-top rounded-lg pb-8 mt-4 relative">
    <v-btn color="error" x-small fab absolute top right @click="clearRepeat"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <v-row class="pa-4">
      <v-col sm="3" md="3">
        <label for="">Booking date</label>
        <date-field
          outlined
          background-color="#fff"
          :value="repeatForm.date"
          :dayName="true"
          :backFill="checkBackfillPermission($modules.facility.schedule.slug)"
          @change="changeFieldValue($event, 'date')"
          class-name="q-text-field shadow-0"
          :dense="true"
          :hide-details="true"
          label=""
        >
        </date-field>
      </v-col>
      <v-col sm="3" md="3">
      <label for="">Start Time</label>
        <v-select
          label=""
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          background-color="#fff"
          item-text="formatted"
          item-value="time"
          @change="changeFieldValue($event, 'start_time')"
          :items="repeatForm.start_times"
          :value="repeatForm.start_time"
          dense
          hide-details="auto"
          class="q-autocomplete shadow-0"
        >
        </v-select>
      </v-col>
      <v-col sm="3" md="3">
        <label for="" class="text-dark-gray font-medium text-xs">End Time</label>
        <v-select
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          label=""
          background-color="#fff"
          item-text="formatted"
          item-value="time"
          :items="repeatForm.end_times"
          @change="changeFieldValue($event, 'end_time')"
          :value="repeatForm.end_time"
          :rules="[(v) => !!v || 'End time is required']"
          dense
          hide-details="auto"
          class="q-autocomplete shadow-0"
        >
        <template v-slot:item="{ item }">
          <span v-html="item.formatted"></span>
        </template>
        <template v-slot:selection="{ item }">
          <span v-html="item.formatted"></span>
        </template>
        </v-select>
      </v-col>
      <v-col sm="3" md="3">
        <label>Facility*</label>
        <v-select
          v-model="repeatForm.facility_id"
          item-value="id"
          item-text="name"
          :items="facilities"
          label=""
          outlined
          background-color="#fff"
          @change="changeFieldValue($event, 'facility_id')"
          dense
          hide-details="auto"
          class="q-autocomplete shadow-0"
        ></v-select>
      </v-col>
      <v-col md="3">
        <label>Type</label>
        <v-select
          :items="['Daily', 'Weekly', 'Monthly', 'Advanced']"
          label=""
          :value="repeatForm.repeatType"
          @change="changeFieldValue($event, 'repeatType')"
          outlined
          background-color="#fff"
          :disabled="repeatForm.repeatId > 0"
          dense
          hide-details="auto"
          class="q-autocomplete shadow-0"
        ></v-select>
      </v-col>
      <v-col md="3" v-if="repeatForm.repeatType == 'Weekly'">
        <label>Weekdays</label>
        <v-select
          :items="weekdays"
          multiple
          item-value="value"
          item-text="name"
          label=""
          @change="changeFieldValue($event, 'repeatWeekdays')"
          :value="repeatForm.repeatWeekdays"
          outlined
          background-color="#fff"
          :disabled="repeatForm.repeatId > 0"
          dense
          hide-details="auto"
          class="q-autocomplete shadow-0"
        >
          <template
            v-if="weekdays.length == repeatForm.repeatWeekdays.length"
            v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Days</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, {{ item.name }}</span>
            <span v-if="index == 2">, ...</span>
          </template>
        </v-select>
      </v-col>
      <v-col md="3" v-if="repeatForm.repeatType != 'Advanced'">
        <label>Repeat Every</label>
        <v-text-field
          outlined
          background-color="#fff"
          :disabled="
            repeatForm.repeatType == null || repeatForm.repeatType == 'Never'
          "
          @change="changeFieldValue($event, 'repeatNumber')"
          :value="repeatForm.repeatNumber"
          label=""
          type="number"
          min="1"
          :suffix="
            (repeatForm.repeatType == 'Monthly'
              ? 'Month'
              : repeatForm.repeatType == 'Weekly'
              ? 'Week'
              : 'Day') + '(s)'
          "
          dense
          hide-details="auto"
          class="q-text-field shadow-0"
        ></v-text-field>
      </v-col>
      <v-col md="3" v-if="repeatForm.repeatType != 'Advanced'">
        <label>Repeat End</label>
        <v-select
          :disabled="repeatForm.repeatType == null"
          :value="repeatForm.repeatEndType"
          label=""
          :items="['Count', 'Date']"
          @change="changeFieldValue($event, 'repeatEndType')"
          outlined
          background-color="#fff"
          dense
          hide-details="auto"
          class="q-autocomplete shadow-0"
        ></v-select>
      </v-col>
      <v-col
        md="3"
        v-if="
          repeatForm.repeatType != 'Advanced' &&
          (repeatForm.repeatEndType == null ||
            repeatForm.repeatEndType == 'Count')
        "
      >
        <label>End After</label>
        <v-text-field
          outlined
          background-color="#fff"
          :disabled="repeatForm.repeatType == null"
          :value="repeatForm.repeatOccurrence"
          label=""
          type="number"
          suffix="Occurrence"
          min="0"
          @change="changeFieldValue($event, 'repeatOccurrence')"
          dense
          hide-details="auto"
          class="q-text-field shadow-0"
        ></v-text-field>
      </v-col>
      <v-col md="3" v-else-if="repeatForm.repeatType != 'Advanced'">
        <label>Date</label>
        <date-field
          :value="repeatForm.repeatEndDate"
          @change="changeFieldValue($event, 'repeatEndDate')"
          :dense="true"
          label=""
          class-name="q-text-field shadow-0"
          :hide-details="true"
          :backFill="checkBackfillPermission($modules.facility.management.slug)"
        >
        </date-field>
      </v-col>
      <v-col md="3" v-if="repeatForm.repeatType == 'Advanced'">
        <label>Dates</label>
        <multi-date-field
          :value="repeatForm.repeated_dates"
          :dense="true"
          label=""
          class-name="q-text-field shadow-0"
          :hide-details="'auto'"
          @input="changeFieldValue($event, 'repeated_dates')"
        ></multi-date-field>
      </v-col>
    </v-row>
    <div
      class="d-flex flex-wrap"
      no-gutters
      v-if="repeatForm.repeated_dates.length > 0"
    >
      <v-row>
        <v-col md="2" v-for="date in repeatForm.repeated_dates" :key="date">
          <v-btn
            text
            class="text-capitalize"
            :color="
              checkExcluded(date)
                ? 'error'
                : checkIncluded(date)
                ? 'success'
                : ''
            "
            @click="changeDateSelection(date)"
          >
            <v-icon
              :color="
                checkExcluded(date)
                  ? 'error'
                  : checkIncluded(date)
                  ? 'success'
                  : ''
              "
              >{{
                checkExcluded(date)
                  ? "mdi-minus-box"
                  : checkIncluded(date)
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}</v-icon
            >
            {{ date | calendarYearFormat }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return { selected: [] };
  },
  props: {
    date: { type: String, default: moment().format("YYYY-MM-DD") },
    minBookingTime: { type: Number },
    facilityId: { type: Number },
    venueServiceId: { type: Number },
    index: { type: Number },
    enableOvernightBooking:{type:Boolean, default: false},
    facilities: { type: Array, default: () => []}
  },
  watch: {
    date: {
      immediate: true,
      handler(val) {
        this.changeFieldValue(val, "date");
      },
    },
  },
  mounted() {
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    repeatForm() {
      let rf = this.$store.getters.getRepeats[this.index];
      if(this.enableOvernightBooking && rf.start_times && typeof rf.start_times != "undefined"){
        if(rf.end_times && rf.start_time){
          rf.end_times = this.endTimeSlots(rf.start_time,rf.end_times);
        }
      }
      return rf;
    },
  },
  methods: {
    async repeatFxn() {
      await this.$store
        .dispatch("loadRepeatDates", this.index)
        .then((response) => {
          if (response) {
            if (response.repeated_dates.length) this.validateOccurrence(this.index);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeFieldValue(value, field) {
      let data = { field: field, index: this.index };
      data[field] = value;
      this.$store.commit("setRepeatFieldData", data);
      if (field == "facility_id") {
        this.getFacilityUtils(this.index);
      } else if (field == "date") {
        this.getFacilityUtils();
      } else if (field == "start_time") {
        this.startTimeChange();
      } else if (field == "end_time") {
        this.repeatFxn();
      } else if (field == "repeated_dates") {
        this.validateOccurrence(this.index);
      } else if (field == "selected_dates") {
        this.getProductsForSelectedDate();
      } else {
        this.repeatFxn();
      }
    },
    changeDateSelection(date) {
      if (!this.checkExcluded(date)) {
        let dates = JSON.parse(JSON.stringify(this.repeatForm.selected_dates));
        if (this.checkIncluded(date)) {
          if (dates.length == 1) {
            this.showError("Error! At least one date required");
            return;
          }
          let index = dates.findIndex((item) => item == date);
          dates.splice(index, 1);
        } else {
          dates.push(date);
        }
        this.changeFieldValue(dates, "selected_dates");
      }
    },
    clearRepeat() {
      this.$emit("remove", this.index);
    },
    startTimeChange() {
      let endTime = moment(this.repeatForm.start_time, "HH:mm:ss")
        .add(this.minBookingTime, "minutes")
        .format("HH:mm:ss");
      this.changeFieldValue(endTime, "end_time");
    },
    timeChange() {
      if (this.repeatForm.start_time && this.repeatForm.end_time) {
        let sIndex = this.repeatForm.times.findIndex(
          (item) => item.time == this.repeatForm.start_time
        );
        let eIndex = this.repeatForm.times.findIndex(
          (item) => item.time == this.repeatForm.end_time
        );
        for (let i = sIndex; i < eIndex; i++) {
          if (this.repeatForm.times[i].no_end) {
            this.changeFieldValue(this.repeatForm.times[i].time, "start_time");
            return this.showError("Error! Start time selection not possible");
          }
          if (this.repeatForm.times[i].no_start) {
            this.changeFieldValue(this.repeatForm.times[i].time, "end_time");
            return this.showError("Error! End time selection not possible");
          }
        }
        this.repeatFxn();
      }
    },
    getFacilityUtils(ind = null) {
      if (!this.date) return;
      this.showLoader("Loading");
      let params = {
        index: this.index,
        facility_id: ind == null ? this.facilityId:this.repeatForm.facility_id,
        date: this.date,
        venue_service_id: this.venueServiceId,
        enable_over_night: this.enableOvernightBooking

      };
      this.$store
        .dispatch("loadRepeatUtils", params)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.repeatFxn();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    validateOccurrence(ind = null) {
      let params = {
        index: this.index,
        facility_id: ind == null ? this.facilityId:this.repeatForm.facility_id,
        date: this.date,
        venue_service_id: this.venueServiceId,
        enableOvernightBooking:this.enableOvernightBooking
      };
      console.log("validate occurance: ");
      console.log(params);
      this.showLoader("Checking..");
      this.$store
        .dispatch("loadRepeatValidation", params)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.$forceUpdate();
            this.hideLoader();
            this.emitChanges();
          }
        })
        .catch(() => {
          this.hideLoader();
          this.showError('Choose a date please');
          // this.errorChecker(error);
        });
    },
    getProductsForSelectedDate() {
      let params = {
        index: this.index,
        facility_id: this.facilityId,
        date: this.date,
        venue_service_id: this.venueServiceId,
      };
      this.showLoader("Checking..");
      this.$store
        .dispatch("loadProductsForSelectedDate", params)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.$forceUpdate();
            this.hideLoader();
            this.emitChanges();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    checkExcluded(date) {
      return this.repeatForm.excluded_dates.includes(date);
    },
    checkIncluded(date) {
      return this.repeatForm.selected_dates.includes(date);
    },
    emitChanges() {
      this.$emit("repeat");
    },
    endTimeSlots(start_time,et) {
      const timeSlotsFs = [];
      const timeSlotsSc = [];
      let currentTime = moment(start_time, 'HH:mm:ss');
      et.forEach( (ob) => {
        if(moment(ob.time,"HH:mm:ss").isBefore(currentTime)){
          timeSlotsSc.push({
            formatted: moment(ob.time,"HH:mm:ss").format('hh:mm a') + '<span style="color: red">+1</span>',
            time: ob.time,
            no_end:ob.no_end,
            no_start:ob.no_start
          });
        }
      });
       et.forEach( (ob) => {
        if(moment(ob.time,"HH:mm:ss").isAfter(currentTime)){
          timeSlotsFs.push({
            formatted: moment(ob.time,"HH:mm:ss").format('hh:mm a'),
            time: ob.time,
            no_end:ob.no_end,
            no_start:ob.no_start
          });
        }
      })

      return timeSlotsFs.concat(timeSlotsSc);
    },
  },
};
</script>

<style>
.selected {
  color: #4caf50 !important;
  font-weight: 600;
  cursor: pointer;
}

.excluded {
  color: #a32525 !important;
  font-weight: 600;
  cursor: pointer;
}
</style>