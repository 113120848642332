<template>
  <v-dialog
      persistent
      v-model="assignKartModal"
      scrollable
      @input="close"
      width="600px"
  >
    <v-form ref="category_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                    class="text-2xl font-semibold"
                    text="Kart Assignment"
                    style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
              <label class="text-dark-gray font-medium text-xs" for=""
              >Vehicles List</label
              >
              <v-select
                  v-model="assign_vehicle_id"
                  :items="vehicles"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Vehicle is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  outlined
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn
              text
              class="white--text blue-color"
              @click="saveParticipantVehicle"
          >Save
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "AssignKartModal",
  components: {SvgIcon},
  props: {
    assignKartModal: {type: Boolean, default: false},
    participant: {type: Object, default: null},
    vehicle: {type: Number, default: null},
  },
  data() {
    return {
      assign_vehicle_id: null,
      vehicles: [],
    };
  },
  watch: {
    assignKartModal(val) {
      if (val) {
        this.getAvailableVehicles();
        this.assign_vehicle_id = this.vehicle;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getAvailableVehicles() {
      this.showLoader('Fetching karts..');
      this.$http
          .get(`venues/facilities/bookings/race/get-vehicles?race_id=${this.participant.race_id}&race_participant_id=${this.participant.race_participant_id}&facility_id=${this.participant.facility_id}`)
          .then((response) => {
            if (response.status == 200) {
              this.vehicles = response.data.vehicles;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    saveParticipantVehicle() {
      this.showLoader('Assigning kart..');
      let data = {
        participant: btoa(JSON.stringify(this.participant)),
        vehicle_id: this.assign_vehicle_id,
      }
      this.$http
          .post(`venues/facilities/bookings/race/assign-vehicle`,data)
          .then((response) => {
            if (response.status == 200) {
              this.vehicles = response.data.vehicles;
              this.close();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    }
  }
}
</script>

<style scoped>

</style>